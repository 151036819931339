<template>
    <div v-if="visible" class="position-relative">
        <ButtonBase
            :class="{ enabled }"
            @click="onClick()"
            @mouseover="$emit('mouseover', $event)"
        >
            <svg
                fill="none"
                height="55"
                viewBox="0 0 56 55"
                width="56"
                xmlns="http://www.w3.org/2000/svg"
            >
                <ellipse
                    cx="28"
                    cy="27.5"
                    fill="#979797"
                    rx="28"
                    ry="27.5"
                />
                <path
                    d="M39.25 12H16.75C14.6816 12 13 13.6816 13 15.75V32.625C13 34.6934 14.6816 36.375 16.75 36.375H22.375V41.2969C22.375 41.8711 23.0312 42.2051 23.4941 41.8652L30.8125 36.375H39.25C41.3184 36.375 43 34.6934 43 32.625V15.75C43 13.6816 41.3184 12 39.25 12Z"
                    fill="white"
                />
            </svg>
        </ButtonBase>
        <div v-if="badge" class="my-badge">
            {{ badge }}
        </div>
    </div>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import {
  CHAT_END,
  CHAT_ENDED,
  CHAT_MESSAGE,
  CHAT_START,
  CHAT_STARTED,
  KEY_C,
  MEETING_JOINED,
  CHAT_STATUS
} from '@/utils/consts'
// https://github.com/vestusola/vue-chat-emoji
export default {
  name: 'ButtonChat',
  components: { ButtonBase },
  data () {
    return {
      badge: 0,
      enabled: false,
      visible: false
    }
  },
  mounted () {
    // To only show after connected
    this.onGlobalEvent(MEETING_JOINED, () => {
      this.visible = true
    })

    this.onGlobalEvent(CHAT_STARTED, () => {
      this.enabled = true
      this.badge = 0
    })

    this.onGlobalEvent(CHAT_ENDED, () => {
      this.enabled = false
    })

    this.onGlobalEvent(CHAT_START, () => {
      this.dialog('Chat').show()
    })

    this.onGlobalEvent(CHAT_END, () => {
      this.dialog('Chat').hide()
    })

    this.onGlobalEvent(KEY_C, (v) => {
      this.onClick()
    })

    this.onGlobalEvent(CHAT_MESSAGE, () => {
      if (!this.enabled) {
        this.badge += 1
      }
    })
  },
  methods: {
    onClick () {
      this.enabled = !this.enabled
      this.globalEventEmit(CHAT_STATUS, this.enabled)
      if (this.enabled) {
        this.dialog('Chat').show()
      } else {
        this.dialog('Chat').hide()
      }
    }
  }
}
</script>
