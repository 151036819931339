import Vue from 'vue'
import VueRouter from 'vue-router'
import Lobby from '@/views/Lobby'
import Conference from '@/views/Conference'
import EndLobby from '@/views/EndLobby'
import Mobile from '@/views/Mobile'

const routes = [
  {
    path: '/',
    component: Lobby
  },
  {
    name: 'EndLobby',
    path: '/end',
    component: EndLobby
  },
  {
    path: '/mobile',
    component: Mobile,
    name: 'Mobile'
  },
  {
    path: '/:room',
    component: Conference,
    name: 'Conference'
  }
]
const router = new VueRouter({ routes })
Vue.use(VueRouter)

export default router
