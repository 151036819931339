<template>
    <ButtonBase
        :class="{ enabled, disabled: tool && tool !== 'drawing' }"
        @click="togglePen()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" fill="#979797" r="28"/>
            <path d="M13 41.1864L18.5103 43L20.4652 40.9921L16.7682 37.195L13 41.1864ZM19.8718 27.5922C19.5815 27.8553 19.3711 28.1987 19.2656 28.5814C19.1601 28.964 19.164 29.3698 19.2768 29.7502L19.9965 32.1761L17.1879 35.0613L22.4946 40.5118L25.2994 37.6311L27.6564 38.3719C28.4135 38.6098 29.2363 38.3714 29.7602 37.7614L31.7207 35.4029L22.1615 25.5849L19.8718 27.5922ZM42.1131 18.4901L38.6278 14.9105C37.4979 13.7499 35.6841 13.6916 34.4852 14.7774L23.5082 24.4039L32.8705 34.0203L42.2427 22.7455C43.3004 21.5141 43.2436 19.6512 42.1131 18.4901Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { DRAWING_STARTED, DRAWING_STOPPED } from '@/utils/consts'

export default {
  name: 'ButtonPen',
  components: {
    ButtonBase
  },
  props: ['tool'],
  data () {
    return {
      enabled: false
    }
  },
  mounted () {
    this.onGlobalEvent(DRAWING_STOPPED, () => {
      this.enabled = false
    })

    this.onGlobalEvent(DRAWING_STARTED, () => {
      this.enabled = true
    })
  },
  methods: {
    togglePen () {
      this.$emit('click')
    }
  }
}
</script>
