<template>
    <audio
        autoplay
    />
</template>

<script>
export default {
  name: 'VaiAudio',
  props: {
    stream: {}
  },
  mounted () {
    this.$nextTick(() => {
      this.stream && this.stream.attach(this.$el)
    })
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.stream && this.stream.detach(this.$el)
    })
  }
}
</script>

<style scoped>

</style>
