import Vue from 'vue'
import jQuery from 'jquery'
import config from '@/options/config'
import { checkToken, getDecodedToken } from '@/utils/jwt'
import { showError } from '@/utils/notifications'
import { _ } from '@/lang'
import { SimpleError } from '@/utils/exception'
import haversine from 'haversine-distance'

export function Log (v) {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  if (typeof v === 'string') {
    console.log('[Vaivu] ' + v)
    return
  }

  console.log('[Vaivu]', v)
}

export function LogError (v) {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  if (typeof v === 'string') {
    console.error('[Vaivu] ' + v)
    return
  }

  console.error('[Vaivu]', v)
}

export function setObj (o, values, extra = {}, whitelist = null) {
  const lValues = { ...extra, ...values }
  for (const i in lValues) {
    if (whitelist === null || whitelist.includes(i)) {
      // eslint-disable-next-line no-prototype-builtins
      if (lValues.hasOwnProperty(i)) {
        Vue.set(o, i, lValues[i])
      }
    }
  }
}

export function clearObj (o) {
  for (const i in o) {
    // eslint-disable-next-line no-prototype-builtins
    if (o.hasOwnProperty(i)) {
      Vue.set(o, i, null)
      delete o[i]
    }
  }
}

export function me () {
  if (!window.g_conference) {
    return ''
  }

  return window.g_conference.myUserId()
}

/**
 * Return true whether this user is a moderator
 *
 * @return {boolean}
 */
export function isModerator () {
  if (!window.g_conference) {
    return false
  }

  return window.g_conference.isModerator()
}

/**
 * Return true whether this user is the host
 *
 * @return {boolean}
 */
export function isHost () {
  try {
    return getDecodedToken('isHost', !!window.g_default_is_host)
  } catch (e) {
    return false
  }
}

export function checkModerator () {
  if (!isModerator()) {
    throw new SimpleError(_('Only moderator can do this action'))
  }
}

export function checkHost () {
  if (!isHost()) {
    throw new SimpleError(_('Only the host can do this action'))
  }
}

/**
 * Time formatting
 *
 * @param seconds
 * @return {string}
 */
export function formatDiff (seconds) {
  seconds = Math.floor(seconds)
  var hours = Math.floor(seconds / 3600)
  var minutes = Math.floor((seconds - (hours * 3600)) / 60)
  seconds = seconds - (hours * 3600) - (minutes * 60)

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}

export function fitTiles (cols, rows) {
  let h = window.innerHeight
  let w = (window.innerWidth - 10) / cols
  while (true) {
    h = w / (16 / 9)
    if ((h * rows) > (window.innerHeight - 120)) {
      w -= 10
      continue
    }

    break
  }

  return {
    w,
    h
  }
}

export function stopVideoTracks () {
  window.g_jitsi_tracks = window.g_jitsi_tracks || []
  window.g_jitsi_tracks.forEach(stream => {
    const track = stream.getVideoTracks()[0]
    if (track) {
      track.stop()
    }
  })
}

export function stopAudioTracks () {
  window.g_jitsi_tracks = window.g_jitsi_tracks || []
  window.g_jitsi_tracks.forEach(stream => {
    const track = stream.getAudioTracks()[0]
    if (track) {
      track.stop()
    }
  })
}

export function stopAllTracks () {
  window.g_jitsi_tracks = window.g_jitsi_tracks || []
  window.g_jitsi_tracks = window.g_jitsi_tracks.filter(t => t && t.active)
  window.g_jitsi_tracks.forEach(t => t.stop())
}

export function stopUnusedTracks () {
  window.g_jitsi_tracks = window.g_jitsi_tracks || []
  window.g_jitsi_tracks = window.g_jitsi_tracks.filter(t => {
    const vue = window.vueConference
    if (vue) {
      if (vue.local?.video?.stream && vue.local.video.stream === t) {
        return true
      }

      if (vue.local?.audio?.stream && vue.local.audio.stream === t) {
        return true
      }

      if (vue.screenShareTrack?.stream && vue.screenShareTrack.stream === t) {
        return true
      }
    }

    const settings = window.vueSettings
    if (settings) {
      if (settings.previewVideoTrack?.stream && settings.previewVideoTrack?.stream === t) {
        return true
      }

      if (settings.previewAudioTrack?.stream && settings.previewAudioTrack?.stream === t) {
        return true
      }
    }

    if (t.active) {
      t.stop()
    }

    return false
  })
}

window.stopUnusedTracks = stopUnusedTracks

export function cleanUp () {
  Log('CleanUp')

  if (window.g_conference) {
    // Only leave if room exists
    if (window.g_conference.room) {
      window.g_conference.leave().catch(() => {
      })
    }
    window.g_conference = null
  }

  stopAllTracks()

  if (window.g_connection) {
    window.g_connection.disconnect().catch(() => {
    })

    if (window.g_connection && window.g_connection.xmpp) {
      window.g_connection.xmpp.disconnect().catch(() => {
      })
    }

    window.g_connection = null
  }
}

/**
 * Broadcast commands to others
 *
 * @param {string} name
 * @param {*} param
 */
export function confEventEmit (name, param = {}) {
  if (!window.g_conference) {
    Log('Not in conference yet... retry in 2s')
    setTimeout(() => {
      confEventEmit(name, param)
    }, 2000)
  } else {
    return window.g_conference.sendCommandOnce(name, param)
  }
}

export function onConfEvent (eventName, callback) {
  if (!window.g_conference) {
    // Store to register later
    window.g_pending.push({
      eventName,
      callback
    })
    return
  }

  while (window.g_pending.length) {
    const shift = window.g_pending.shift()
    window.g_conference.addCommandListener(shift.eventName, shift.callback)
  }

  return window.g_conference.addCommandListener(eventName, callback)
}

export function globalEventEmit (...args) {
  window.g_root.$emit.apply(window.g_root, args)
}

export function onGlobalEvent (eventName, callback) {
  window.g_root.$on(eventName, callback)
}

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
export function copyTextToClipboard (str, parent) { // eslint-disable-line no-unused-vars
  const element = document.createElement('textarea')
  element.value = str
  element.setAttribute('readonly', '')
  element.style.position = 'absolute'
  element.style.left = '-9999px';
  (parent || document.body).appendChild(element)
  element.select()
  document.execCommand('copy');
  (parent || document.body).removeChild(element)
}

export function requestJWT (params = {}, callback = () => {
}, callbackError = () => {
}) {
  if (!config.jwt) {
    const decoded = {}
    callback(decoded)
    return
  }

  jQuery
    .ajax({
      url: `${config.arena.host}/api/v1/webconf/room/join`,
      method: 'POST',
      headers: {
        'X-YCT-Token': config.arena.key
      },
      contentType: 'application/json',
      dataType: 'json',
      processData: false,
      async: true,
      data: JSON.stringify({
        meeting_id: params.room,
        meeting_name: params.room,
        join_name: params.name,
        join_pwd: params.password,
        returnJwt: true
      })
    })
    .then(r => {
      if (r.success) {
        checkToken(r.data)
          .then(decoded => {
            callback(decoded)
          })
          .catch(e => {
            showError(_('Authentication'), e.message)
            window.sessionStorage.removeItem('jwt')
          })
        return
      }

      // checking if backend return with input errors
      if (r.inputs) {
        callbackError(r.inputs)
        return
      }

      showError(_('Meeting'), r.message)
    })
    .catch(() => {
      showError(_('Something went wrong'))
    })
}

/**
 * This is a complete object, you can use just one of these attributes
 *
 * data example: {
 *     cc: 'email1@teste.com,email2@teste.com,email3@teste.com',
 *     bcc: 'name3@rapidtables.com',
 *     subject: 'This is a test',
 *     body: 'Content email body'
 * }
 *
 * @param {object} data
 * @param {string} recipient
 */
export function mailTo (data, recipient = '') {
  const url = jQuery.param(data)
  const win = window.open(`mailto:${recipient}?${url}`)
}

function _showOver (elem, text, params = {}) {
  const rect = elem.getBoundingClientRect()
  const id = params.id || text.replace(/\W+/g, '')
  window.g_globals = window.g_globals || {}

  const setTimer = () => {
    return setTimeout(() => {
      hint.remove()
      hint = null
      delete window.g_globals[id]
    }, params.timeout || 1000)
  }

  let hint
  if (window.g_globals[id]) {
    hint = window.g_globals[id].hint
    clearTimeout(window.g_globals[id].timer)
    window.g_globals[id].timer = setTimer()

    jQuery(document.body).find('.my-over').not(hint).remove()
    return
  }

  jQuery(document.body).find('.my-over').remove()
  hint = jQuery(`<div class="my-over ${params.class ?? 'hint'}"></div>`).text(text).appendTo(document.body)
  const timer = setTimer()
  window.g_globals[id] = {
    hint,
    timer
  }

  if (params.style) {
    hint.css(params.style)
  }

  if (params.position === 'left') {
    const mid = (rect.top + (rect.height / 2))
    const top = `calc(${mid}px - 1rem)`
    const right = window.innerWidth - (rect.left - 10) + 'px'
    hint.css({
      top,
      right
    })
  } else if (params.position === 'bottom') {
    const top = (rect.bottom + 10) + 'px'
    const left = (rect.left + (rect.width / 2) - (hint.width() / 2)) + 'px'
    hint.css({
      top,
      left
    })
  } else if (params.position === 'right') {
    const mid = (rect.top + (rect.height / 2))
    const top = `calc(${mid}px - 1rem)`
    const left = (rect.right + 10) + 'px'
    hint.css({
      top,
      left
    })
  } else {
    const mid = (rect.top + (rect.height / 2))
    const top = `calc(${mid}px - 1rem)`
    const left = (rect.left - (rect.width / 2)) + 'px'
    hint.css({
      top,
      left
    })
  }
}

export function showHint (elem, text, params = {}) {
  return _showOver(elem, text, params)
}

export function showPopover (elem, text, params = {}) {
  params.class = 'pop'
  params.timeout = 5000
  return _showOver(elem, text, params)
}

export async function getNearRegion () {
  const regions = {
    region_us: {
      lat: 34.052235,
      lng: -118.243683
    },
    region_au: {
      lat: -33.868820,
      lng: 151.209290
    },
    region_br: {
      lat: -30.034647,
      lng: -51.217659
    }
  }

  let response
  let nearestDistance = 0
  let nearestRegion = 'region_us'
  try {
    response = await jQuery.getJSON('https://extreme-ip-lookup.com/json')
  } catch (e) {
    return nearestRegion
  }
  for (const region in regions) {
    const distance = haversine(regions[region], {
      lat: response.lat,
      lng: response.lon
    })

    if (!nearestDistance) {
      nearestDistance = distance
      continue
    }

    if (distance < nearestDistance) {
      nearestDistance = distance
      nearestRegion = region
    }
  }
  return nearestRegion
}
