<template>
    <ButtonBase
        :class="getClass()"
        @click="toggleScreenShare"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg
            fill="none"
            height="56"
            viewBox="0 0 56 56"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="28"
                cy="28"
                fill="#979797"
                r="28"
            />
            <path
                d="M40.5 15H15.5C14.1198 15 13 16.1338 13 17.5312V34.4062C13 35.8037 14.1198 36.9375 15.5 36.9375H25.5L24.6667 39.4688H20.9167C20.224 39.4688 19.6667 40.033 19.6667 40.7344C19.6667 41.4357 20.224 42 20.9167 42H35.0833C35.776 42 36.3333 41.4357 36.3333 40.7344C36.3333 40.033 35.776 39.4688 35.0833 39.4688H31.3333L30.5 36.9375H40.5C41.8802 36.9375 43 35.8037 43 34.4062V17.5312C43 16.1338 41.8802 15 40.5 15ZM39.6667 33.5625H16.3333V18.375H39.6667V33.5625Z"
                fill="white"
            />
        </svg>

    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { _ } from '@/lang'
import { JitsiMeetJS } from '@/utils/jitsiLib'
import { KEY_D, SHARE_STARTED, SHARE_STOPPED, TRACKS_UPDATED } from '@/utils/consts'
import { globalEventEmit, Log, me, confEventEmit } from '@/utils/common'
import { showInfo } from '@/utils/notifications'

export default {
  name: 'ButtonScreenShare',
  components: { ButtonBase },
  data () {
    return {
      status: ''
    }
  },
  computed: {
    sharing () {
      return this.status === 'sharing'
    }
  },
  mounted () {
    this.onGlobalEvent(KEY_D, (v) => {
      this.toggleScreenShare()
    })
  },
  methods: {
    getClass () {
      return {
        enabled: this.sharing
      }
    },
    toggleScreenShare () {
      if (this.status === 'sharing') {
        // Will fire JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED
        this.$parent.screenShareTrack.stopStream()
        return
      }

      JitsiMeetJS
        .createLocalTracks({ devices: ['desktop'] })
        .then(tracks => {
          const newTrack = tracks[0]
          this.$parent.screenShareTrack = newTrack
          window.g_conference.replaceTrack(window.vueConference.local.video, newTrack)
          this.status = 'sharing'
          this.globalEventEmit(SHARE_STARTED)
          this.globalEventEmit(TRACKS_UPDATED)
          confEventEmit(SHARE_STARTED, {
            attributes: {
              id: me()
            }
          })

          newTrack.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
            this.globalEventEmit(SHARE_STOPPED)
            this.globalEventEmit(TRACKS_UPDATED)
            confEventEmit(SHARE_STOPPED)
            this.status = ''
            Log('Screenshare track stoped')
            if (window.g_conference) {
              window.g_conference.replaceTrack(newTrack, window.vueConference.local.video).then(() => {
                // Dispose must be last thing
                newTrack.dispose()
              })
            } else {
              newTrack.dispose()
            }
            setTimeout(() => {
              this.$parent.screenShareTrack = null
            }, 1)
          })
        })
        .catch((error) => {
          if (error.message === 'User canceled screen sharing prompt') {
            showInfo(_('To screen share, enable the screen share permissions'))
          } else {
            throw new Error('ScreenSharing failed')
          }
        })
    }
  }
}
</script>
