<template>
    <ButtonBase
        v-if="visible"
        :class="{enabled: !muted && !locked, disabled: locked}"
        @click="toggleMute()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg
            fill="none"
            height="55"
            viewBox="0 0 56 55"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="28"
                cy="27.5"
                fill="#979797"
                rx="28"
                ry="27.5"
            />
            <path
                d="M30.5104 17H15.4896C14.1146 17 13 18.1146 13 19.4896V34.5104C13 35.8854 14.1146 37 15.4896 37H30.5104C31.8854 37 33 35.8854 33 34.5104V19.4896C33 18.1146 31.8854 17 30.5104 17ZM40.375 18.9635L34.6667 22.901V31.099L40.375 35.0312C41.4792 35.7917 43 35.0156 43 33.6875V20.3073C43 18.9844 41.4844 18.2031 40.375 18.9635Z"
                fill="white"
            />
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { KEY_V, LOCAL_MUTED, MEETING_JOINED } from '@/utils/consts'

export default {
  name: 'ButtonCam',
  components: {
    ButtonBase
  },
  props: ['locked'],
  data () {
    return {
      muted: false,
      visible: false
    }
  },
  mounted () {
    this.onGlobalEvent(LOCAL_MUTED, (v) => {
      this.muted = window?.vueConference.local?.video && window.vueConference.local.video.isMuted()
    })

    this.onGlobalEvent(KEY_V, (v) => {
      this.toggleMute()
    })

    // To only show after connected
    this.onGlobalEvent(MEETING_JOINED, () => {
      this.visible = true
    })
  },
  methods: {
    async toggleMute () {
      if (this.locked || !window.vueConference.local.video) {
        return
      }

      if (window.vueConference.local.video.isMuted()) {
        await window.vueConference.local.video.unmute()
      } else {
        await window.vueConference.local.video.mute()
      }

      this.muted = window.vueConference.local.video.isMuted()
    }
  }
}
</script>
