const domain = process.env.VUE_APP_JITSI_DOMAIN
const host = process.env.VUE_APP_ARENA_HOST

export default {
  jwt: process.env.VUE_APP_JWT_ENABLED === 'true' || process.env.VUE_APP_JWT_ENABLED === '1',
  arena: {
    host,
    pub: process.env.VUE_APP_PROSODY_KEY,
    key: process.env.VUE_APP_ARENA_KEY
  },
  miro: {
    host: host + '/webhook/v1/miro/token',
    clientId: process.env.VUE_APP_MIRO_KEY
  },
  hosts: {
    domain,
    muc: `conference.${domain}`,
    focus: `focus.${domain}`
  },
  bosh: `https://${domain}/http-bind`,
  clientNode: 'http://jitsi.org/jitsimeet', // The name of client node advertised in XEP-0115 'c' stanza
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  enableTalkWhileMuted: true,
  enableLayerSuspension: false,
  useNewBandwidthAllocationStrategy: true,
  disableSimulcast: false,
  disableH264: true,
  videoQuality: {
    preferredCodec: 'vp9',
    maxBitratesVideo: {}
  },
  channelLastN: -1, // The default value of the channel attribute last-n.
  openBridgeChannel: 'datachannel',
  deploymentInfo: { // Default region
    userRegion: 'region_us'
  },
  p2p: {
    enabled: false
  }
}
