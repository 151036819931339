<template>
    <div id="app">
        <div
            v-if="!closing && !disconnecting"
            :class="getRemoteContainerClass()"
            :style="getRemoteContainerStyle()"
            class="remote-container"
        >
            <div class="remote scroll-styled" @mousemove="mouseOverlay()">
                <div>
                    <div
                        v-for="grid in getGrid()"
                        :key="grid.page+'-'+grid.max"
                        class="remote-row"
                    >
                        <VideoContainer
                            :show="show_thumbnails"
                            :isOverlay="isOverlay"
                            v-for="data in videoContainersSorted(grid.page, grid.max)"
                            :key="data.participant + data.video + data.audio"
                            :data="data"
                            :type="data.type"
                            :view="view"
                            @click="videoClick(data)"
                        />
                    </div>
                </div>
                <div>
                    <VideoContainer
                        :show="show_thumbnails"
                        :isOverlay="isOverlay"
                        v-for="data in videoContainersSorted(16)"
                        :key="data.participant + data.video + data.audio"
                        :data="data"
                        :view="view"
                        type="remote"
                        @click="videoClick(data)"
                    />
                </div>
            </div>
        </div>

        <template v-if="!closing && !disconnecting">
            <div
                v-if="tool === 'drawing'"
                id='drawing'
                ref='drawing'
                :style="{height: pin_h, width: pin_w, top: pin_t}"
            ></div>

            <div
                v-if="tool === 'whiteboard'"
                id="whiteboard"
                v-html="whiteboard_frame"
            ></div>
        </template>

        <div class="toolbar-top">
            <ButtonDisconnect
                v-if="!disconnecting"
                :class="{disabled: connecting}"
                @click="disconnect(); mouseOverlayReset()"
            />

            <ButtonSettings
                @click="onSettingsClick(); mouseOverlayReset()"
                @mouseover="showHint($event.target, _('Settings'))"
            />

            <ButtonCam
                ref='ButtonCam'
                :locked="local_video_locked"
                @mouseover="showHint($event.target, _('Video'))"
            />

            <ButtonMic
                ref="ButtonMic"
                :locked="local_audio_locked"
                @mouseover="showHint($event.target, _('Audio'))"
            />

            <ButtonTileView
                :following="following"
                :tool="tool"
                :view="view"
                class="d-none d-sm-inline-block"
                @click="toggleView()"
                @mouseover="showHint($event.target, _('View'))"
            />

            <ButtonFullScreen
                :tool="tool"
                class="d-none d-sm-inline-block"
                @mouseover="showHint($event.target, _('Fullscreen'))"
            />
        </div>

        <div class="info-top">
            <InfoTop
                :closing="closing"
                :count="participantCount"
                :timer="meetingTimer"
                :timeMouseOverlay="timeMouseOverlay"
            />
        </div>

        <div class="toolbar-side">
            <ButtonRecord
                v-if="moderator && can_use_recording"
                @mouseover="showHint($event.target, _('Record'), 'left')"
            />

            <ButtonInvite/>

            <ButtonRaiseHand
                ref='ButtonRaiseHand'
                :badge="raised_hands"
                @mouseover="showHint($event.target, raised_hands_tooltip || _('Raise Hand'), 'left')"
            />

            <ButtonChat
                @mouseover="showHint($event.target, _('Chat'), 'left')"
            />

            <ButtonPen
                :tool="tool"
                @click="onDrawingPenClick()"
                @mouseover="showHint($event.target, _('Pen'), 'left')"
            />

            <ButtonBoard
                v-if="miro_loaded && !free_plan"
                @mouseover="showHint($event.target, _('Whiteboard'), 'left')"
            />

            <ButtonScreenShare
                ref="ButtonScreenShare"
                @mouseover="showHint($event.target, _('Screen Share'), 'left')"
            />

            <ButtonMuteAll
                v-if="moderator"
                @mouseover="showHint($event.target, _('Mute All'), 'left')"
            />
        </div>

        <div v-if="connecting" class="connecting">
            <RotateSquare2 :background="'#FFFFFF'"/>
        </div>
    </div>
</template>

<script>
import { connect, createAndJoinRoom, createTracksAndAddToRoom } from '@/utils/jitsiUtils'
import { browser, JitsiMeetJS } from '@/utils/jitsiLib'
import {
  confEventEmit,
  fitTiles,
  formatDiff,
  isHost,
  isModerator,
  Log,
  LogError,
  me,
  onConfEvent,
  showHint,
  showPopover,
  stopUnusedTracks
} from '@/utils/common'
import ButtonDisconnect from '@/components/ButtonDisconnect'
import ButtonSettings from '@/components/ButtonSettings'
import ButtonCam from '@/components/ButtonCam'
import ButtonMic from '@/components/ButtonMic'
import ButtonRaiseHand from '@/components/ButtonRaiseHand'
import ButtonChat from '@/components/ButtonChat'
import ButtonScreenShare from '@/components/ButtonScreenShare'
import { SvgOverChat, SvgOverKick, SvgOverMic, SvgOverSpeaker } from '@/svgs'
import ButtonTileView from '@/components/ButtonTileView'
import ButtonFullScreen from '@/components/ButtonFullScreen'
import ButtonRecord from '@/components/ButtonRecord'
import VideoContainer from '@/components/VideoContainer'
import { showError, showInfo, showWarn } from '@/utils/notifications'
import { _ } from '@/lang'
import { getDecodedToken } from '@/utils/jwt'
import {
  CHAT_MESSAGE,
  CLEAR_RAISED_HAND,
  DEFAULT_DISPLAY_NAME,
  DISABLE_VIDEO,
  DRAWING_PEN,
  DRAWING_START,
  DRAWING_STARTED,
  DRAWING_STOP,
  DRAWING_STOPPED,
  FOLLOW_ME_COMMAND,
  HOST_CLOSED,
  HOST_DESTROYED,
  KEY_F,
  KEY_H,
  KEYBOARD_SHORTCUT_DOWN,
  KEYBOARD_SHORTCUT_UP,
  LOCAL_MUTED,
  MEETING_DISPLAY_NAME,
  MEETING_ENDED,
  MEETING_ERROR,
  MEETING_INUSE,
  MEETING_JOINED,
  MEETING_MODERATOR,
  MEETING_MODERATOR_REQUEST,
  MEETING_WARN,
  MODERATOR_GRANTED,
  RECORDING_CHANGED,
  REMOVE_BUTTON_STYLE,
  SHARE_STARTED,
  SHARE_STOPPED,
  STOP_RECORDING,
  TRACKS_UPDATED,
  UNAVAILABLE_AUDIO,
  UNAVAILABLE_DEVICES,
  UNAVAILABLE_VIDEO,
  UNMUTE_POLICY,
  UPDATE_BLUR,
  WHITEBOARD_COMMAND,
  WHITEBOARD_COMMAND_CLOSE
} from '@/utils/consts'
import InfoTop from '@/components/InfoTop'
import ButtonInvite from '@/components/ButtonInvite'
import ButtonMuteAll from '@/components/ButtonMuteAll'
import { initDrawing, processDrawingCommand } from '@/utils/drawingPen'
import ButtonPen from '@/components/ButtonPen'
import jQuery from 'jquery'
import ButtonBoard from '@/components/ButtonBoard'
import { installWinEvents, removeWinEvents } from '@/utils/conferenceEvents'
import { RotateSquare2 } from 'vue-loading-spinner'
import { updateMeetingWhiteboard } from '@/utils/whiteboard'

/** @type {JitsiConnection} */
window.g_connection = null

/** @type {ChatRoom} */
window.g_conference = null
window.g_pending = []

window.g_speak_time = {}

window.vueConference = null

export default {
  name: 'Conference',
  components: {
    ButtonBoard,
    ButtonPen,
    ButtonMuteAll,
    ButtonInvite,
    InfoTop,
    VideoContainer,
    ButtonRecord,
    ButtonFullScreen,
    ButtonTileView,
    ButtonScreenShare,
    ButtonChat,
    ButtonRaiseHand,
    ButtonMic,
    ButtonCam,
    ButtonSettings,
    RotateSquare2,
    ButtonDisconnect
  },
  data () {
    return {
      free_plan: true,
      show_thumbnails: true,
      miro_loaded: false,
      host: false,
      disconnecting: false,
      closing: false,
      connecting: false,
      recording: false,
      moderator: false,
      warn_shown: {},
      startedAt: 0,
      local_video_locked: false,
      local_audio_locked: false,
      local_video_muted: false,
      local_audio_muted: false,
      raised_hands: 0,
      raised_hands_tooltip: '',
      pin_h: '',
      pin_w: '',
      pin_t: '',
      view: 'stripe',
      tool: '',
      tool_owner: '',
      following: false,
      can_use_recording: false,
      room_settings: {
        audio_mute_timeout_count: 0,
        video_mute_timeout_count: 0,
        mute_timeout_tries: 10
      },
      whiteboard_frame: '',
      participantCount: 0,
      meetingTimer: '00:00',
      svg: {
        SvgOverMic,
        SvgOverChat,
        SvgOverSpeaker,
        SvgOverKick
      },
      local: {},
      remote: {},
      isOverlay: true,
      timeMouseOverlay: null,
      userSharing: ''
    }
  },
  watch: {
    view (v) {
      this.updateVideoQuality()
    },
    moderator () {
      this.localSaveMeetingRole()
    }
  },
  beforeDestroy () {
    clearInterval(this.interval_handler)
    removeWinEvents()
    window.removeEventListener('keydown', this.execKeyDownShortcut)
    window.removeEventListener('keyup', this.execKeyUpShortcut)
  },
  mounted () {
    this.can_use_recording = !browser.isSafari()

    if (this.$route.params.room) {
      this.connect(this.$route.params.room)
    }

    onConfEvent(SHARE_STARTED, ({ attributes }) => {
      const participantId = attributes.id
      if (participantId === me()) {
        return
      }
      this.view = 'stripe'
      this.userSharing = participantId
      this.pin(participantId)
    })

    onConfEvent(SHARE_STOPPED, () => {
      this.userSharing = ''
    })

    this.interval_handler = setInterval(() => {
      this.updateInfo()
      this.checkPin()
      this.resizeTool()
      stopUnusedTracks()

      // Count activity per user
      for (const i in this.remote) {
        if (this.remote.hasOwnProperty(i)) {
          if (this.remote[i].dominant && !this.remote[i].audioMuted) {
            if (window.g_speak_time[i]?.counter) {
              window.g_speak_time[i].counter += 1
              window.g_speak_time[i].name = this.remote[i].display_name
            } else {
              window.g_speak_time[i] = {
                counter: 1,
                name: this.remote[i].display_name
              }
            }
          }
        }
      }

      if (this.local.dominant && !this.local_audio_muted) {
        const myId = me()
        if (window.g_speak_time[myId]?.counter) {
          window.g_speak_time[myId].counter += 1
          window.g_speak_time[myId].name = this.local.display_name
        } else {
          window.g_speak_time[myId] = {
            counter: 1,
            name: this.local.display_name
          }
        }
      }

      this.updateVideoQuality()
    }, 1000)

    installWinEvents(this)

    window.vueConference = this
    this.onGlobalEvent(UPDATE_BLUR, this.updateBlur)

    this.onGlobalEvent(KEY_F, () => {
      this.show_thumbnails = !this.show_thumbnails
    })

    this.onGlobalEvent(KEY_H, () => {
      this.dialog('KeyboardShortcut').show()
      this.mouseOverlayReset()
    })

    this.onGlobalEvent(UNAVAILABLE_DEVICES, () => {
      this.local_video_locked = true
      this.local_audio_locked = true
    })

    this.onGlobalEvent(UNAVAILABLE_VIDEO, () => {
      this.local_video_muted = true
      this.local_video_locked = true
    })

    this.onGlobalEvent(UNAVAILABLE_AUDIO, () => {
      this.local_audio_muted = true
      this.local_audio_locked = true
    })

    this.onGlobalEvent(RECORDING_CHANGED, v => {
      this.recording = v
    })

    // shortcuts
    window.addEventListener('keydown', this.execKeyDownShortcut)
    window.addEventListener('keyup', this.execKeyUpShortcut)

    this.onGlobalEvent(LOCAL_MUTED, () => {
      this.local_video_muted = window?.vueConference.local?.video && window.vueConference.local.video.isMuted()
      this.local_audio_muted = window?.vueConference.local?.audio && window.vueConference.local.audio.isMuted()
    })

    this.onGlobalEvent(MODERATOR_GRANTED, () => {
      this.moderator = true
      showInfo(_('Now you are moderator'))
    })

    // Closed by time's up
    this.onGlobalEvent(MEETING_ENDED, () => {
      this.mouseOverlayReset()
      if (this.recording) {
        this.onGlobalEvent(RECORDING_CHANGED, v => {
          if (!v) {
            this.disconnect(true)
          }
        })
        this.globalEventEmit(STOP_RECORDING)
      } else {
        this.disconnect(true)
      }
    })

    // Closed by manual intervention
    this.onGlobalEvent(HOST_CLOSED, () => {
      if (this.closing) {
        return
      }

      this.closing = true

      setTimeout(() => {
        if (this.recording) {
          this.onGlobalEvent(RECORDING_CHANGED, v => {
            if (!v) {
              this.disconnect(true)
            }
          })
          this.globalEventEmit(STOP_RECORDING)
        } else {
          this.disconnect(true)
        }
      }, 5000)
      this.mouseOverlayReset()
      // This meeting was closed by host.
      showWarn(_('this_meeting_was_closed_by_host'))
    })

    this.onGlobalEvent(MEETING_WARN, (o) => {
      if (!this.warn_shown[o.value]) {
        this.warn_shown[o.value] = true
        showWarn(o.message)
      }
    })

    this.onGlobalEvent(MEETING_ERROR, (o) => {
      showError(o.value || o.message)
    })

    this.onGlobalEvent(MEETING_INUSE, (o) => {
      if (!this.warn_shown[o.value]) {
        this.warn_shown[o.value] = true
        if ((o.value + '').match(new RegExp(me() + '$'))) {
          this.closing = true
          showWarn(_('session_already_open'))
          setTimeout(() => {
            this.disconnect(true)
          }, 5000)
        }
      }
    })

    onConfEvent(WHITEBOARD_COMMAND, this.onWhiteboardCommand)
    onConfEvent(WHITEBOARD_COMMAND_CLOSE, this.onWhiteboardClose)
    this.onGlobalEvent(WHITEBOARD_COMMAND, this.onWhiteboardCommand)
    this.onGlobalEvent(WHITEBOARD_COMMAND_CLOSE, this.onWhiteboardClose)

    this.onGlobalEvent(HOST_DESTROYED, () => {
      this.disconnect(true)
    })

    this.onGlobalEvent(SHARE_STARTED, () => {
      this.pin('local', 'forced')
    })

    this.miro_loaded = !!window.miroBoardsPicker

    try {
      this.free_plan = !!getDecodedToken('isFreePlan')
    } catch (e) {
      this.free_plan = true
    }
  },
  methods: {
    updateVideoQuality () {
      if (!window.g_conference) return
      const constraints = {}
      if (this.view !== 'tile') {
        for (const participantId in this.remote) {
          if (this.remote[participantId].pinned) {
            constraints[participantId] = { maxHeight: Math.min(1080, window.innerHeight) }
            break
          }
        }
      }

      window.g_conference.setReceiverConstraints({
        lastN: -1,
        selectedEndpoints: [],
        onStageEndpoints: [],
        defaultConstraints: { maxHeight: 180 },
        constraints
      })
    },
    mouseOverlay () {
      this.mouseOverlayReset()
      this.timeMouseOverlay = setTimeout(() => {
        document.querySelector('html').style.cursor = 'none'
        this.isOverlay = false
      }, 3000)
    },
    mouseOverlayReset () {
      this.isOverlay = true
      clearTimeout(this.timeMouseOverlay)
      document.querySelector('html').style.cursor = 'default'
    },
    isFollowing () {
      for (const participantId in this.remote) {
        if (this.remote[participantId].follow_me) {
          return true
        }
      }

      return false
    },
    localTrackWasChanged (trackId) {
      this.local.video = trackId
    },
    /**
     * Check if must be video muted by room settings
     * - do not affect moderators
     */
    roomSettingsVideoMute () {
      if (isModerator()) return
      if (window.g_conference.startVideoMuted === true) {
        this.setVideoMute(true)
        Log('Room settings - video muted')
      }
    },
    /**
     * Check if must be audio muted by room settings
     *  - do not affect moderators
     */
    roomSettingsAudioMute () {
      if (isModerator()) return
      if (window.g_conference.startAudioMuted === true) {
        this.setAudioMute(true)
        Log('Room settings - audio muted')
      }
    },
    /**
     * @param {boolean} mute
     */
    async setVideoMute (mute) {
      if (typeof (window.vueConference.local.video) === 'object') {
        this.local_video_muted = mute
        if (mute) {
          await window.vueConference.local.video.mute()
        } else {
          await window.vueConference.local.video.unmute()
        }
      } else {
        if (this.room_settings.video_mute_timeout_count <= this.room_settings.mute_timeout_tries) {
          this.room_settings.video_mute_timeout_count++
          setTimeout((_mute) => {
            this.setVideoMute(_mute)
          }, 1000, mute)
        }
      }
    },
    /**
     * @param {boolean} mute
     */
    async setAudioMute (mute) {
      if (typeof (window.vueConference.local.audio) === 'object') {
        this.local_audio_muted = mute
        if (mute) {
          await window.vueConference.local.audio.mute()
        } else {
          await window.vueConference.local.audio.unmute()
        }
      } else {
        if (this.room_settings.audio_mute_timeout_count <= this.room_settings.mute_timeout_tries) {
          this.room_settings.audio_mute_timeout_count++
          setTimeout((_mute) => {
            this.setAudioMute(_mute)
          }, 1000, mute)
        }
      }
    },
    /**
     * Apply room settings established by moderator
     */
    applyRoomSettings () {
      this.roomSettingsVideoMute()
      this.roomSettingsAudioMute()
    },
    checkPin () {
      for (const participantId in this.remote) {
        if (!this.remote.hasOwnProperty(participantId)) {
          continue
        }

        if (this.remote[participantId].follow_me && !this.remote[participantId].pinned) {
          this.pin(participantId, true)
          return
        }
      }

      let pinned = false
      for (const participantId in this.remote) {
        if (this.remote[participantId].pinned) {
          pinned = true
          break
        }
      }

      if (this.local.pinned) {
        pinned = true
      }

      if (!pinned) {
        this.pin('local')
      }
    },
    resizeTool () {
      const $video = jQuery('.pinned video')
      if ($video.length) {
        this.pin_h = ($video.height() + 2) + 'px'
        this.pin_w = ($video.width() + 2) + 'px'
        this.pin_t = $video.offset().top + 'px'
      } else {
        this.pin_h = '100%'
        this.pin_w = '100%'
        this.pin_t = '0'
      }
    },
    pin (id, force = false) {
      const localShare = this.$refs.ButtonScreenShare && this.$refs.ButtonScreenShare.sharing
      const remoteShare = this.userSharing && this.userSharing !== me()
      if (!force) {
        if (localShare) {
          Log('Pin prevented due my screen share')
          return
        }

        if (remoteShare && this.userSharing !== id) {
          Log('Pin prevented due another screen share')
          return
        }

        if (this.tool) {
          Log('Pin prevented due tool in use')
          return
        }

        if (this.participantCount > 1 && id === 'local') {
          Log('Avoid local pin')
          return
        }

        for (const participantId in this.remote) {
          if (this.remote[participantId].follow_me) {
            if (id !== participantId) {
              Log('Following someone else')
              return
            }
          }
        }
      }

      const changePin = () => {
        for (const participantId in this.remote) {
          if (localShare) {
            this.$set(this.remote[participantId], 'pinned', false)
            continue
          }

          if (remoteShare) {
            this.$set(this.remote[participantId], 'pinned', this.userSharing === participantId)
            continue
          }

          this.$set(this.remote[participantId], 'pinned', id === participantId)
        }

        if (localShare) {
          this.local.pinned = true
        }

        if (!remoteShare) {
          this.local.pinned = (id === 'local')
        }

        // Lock changes for 3s
        this.locked_pin = true
        setTimeout(() => {
          this.locked_pin = false
          if (this.locked_fn) {
            this.locked_fn()
            this.locked_fn = null
          }
        }, 3000)
      }

      if (this.locked_pin) {
        this.locked_fn = () => {
          changePin()
        }
        return
      }

      changePin()
    },
    showHint (elem, text, position) {
      showHint(jQuery(elem).closest('.button').get(0), text, {
        position: position || 'bottom',
        style: {
          whiteSpace: 'pre-wrap'
        }
      })
    },
    videoClick (data) {
      const participantId = data.participant
      this.pin(participantId, 'force')
    },
    execKeyDownShortcut (key) {
      this.globalEventEmit(KEYBOARD_SHORTCUT_DOWN, key)
    },
    execKeyUpShortcut (key) {
      this.globalEventEmit(KEYBOARD_SHORTCUT_UP, key)
    },
    updateBlur () {
      if (!window.vueConference.local.video) return
      const enabled = this.global('blur', false)
      if (enabled) {
        Log('Blur: On')
        window.g_blur
          .createBlurEffect()
          .then(blurEffectInstance => {
            window.vueConference.local.video
              .setEffect(blurEffectInstance)
              .then(() => {

              })
              .catch(error => {
                LogError('setEffect failed with error:', error)
              })
          })
        return
      }

      Log('Blur: Off')
      window.vueConference.local
        .video
        .setEffect()
        .then(() => {
        })
        .catch(error => {
          LogError('setEffect failed with error:', error)
        })
    },
    onSettingsClick () {
      this.dialog('Settings').show()
    },
    mute (participantId) {
      window.g_conference.muteParticipant(participantId)
    },
    kick (participantId) {
      this.dialog('ConfirmKick').show(r => {
        if (!r) return
        window.g_conference
          .kickParticipant(participantId)
          .then(r => {
            if (!r) showError(_('Kick'), _('Something went wrong'))
          })
          .catch(() => {
            showError(_('Kick'), _('You can\'t kick moderators'))
          })
      })
    },
    * getGrid () {
      const count = this.videoContainersSorted().length

      if (count <= 2) {
        yield {
          page: 1,
          max: 2
        }
      } else if (count <= 4) {
        yield {
          page: 1,
          max: 2
        }
        yield {
          page: 2,
          max: 2
        }
      } else if (count <= 6) {
        yield {
          page: 1,
          max: 3
        }
        yield {
          page: 2,
          max: 3
        }
      } else if (count <= 9) {
        yield {
          page: 1,
          max: 3
        }
        yield {
          page: 2,
          max: 3
        }
        yield {
          page: 3,
          max: 3
        }
      } else {
        yield {
          page: 1,
          max: 4
        }
        yield {
          page: 2,
          max: 4
        }
        yield {
          page: 3,
          max: 4
        }
        yield {
          page: 4,
          max: 4
        }
      }
    },
    videoContainersSorted (page = null, count = null) {
      const items = []

      items.push({
        participant: 'local',
        type: 'local',
        ...this.local
      })

      for (const participant in this.remote) {
        if (!this.remote.hasOwnProperty(participant)) continue
        items.push({
          participant,
          ...this.remote[participant]
        })
      }

      if (items.length > 16) {
        items.sort((a, b) => {
          return (a.last_activity - b.last_activity)
        })
      }

      if (page !== null && count === null) {
        return items.slice(page)
      }

      if (page !== null && count !== null) {
        const start = (page - 1) * count
        return items.slice(start, start + count)
      }

      return items
    },
    updateInfo () {
      this.participantCount = Object.values(this.remote).length + 1

      if (this.startedAt) {
        this.meetingTimer = formatDiff((performance.now() - this.startedAt) / 1000)
      }

      let hands = 0
      const raisedHandsUsers = []
      for (const i in this.remote) {
        if (this.remote[i].raised_hand) {
          hands++
          raisedHandsUsers.push(this.remote[i].display_name)
        }
      }
      this.raised_hands = hands
      this.raised_hands_tooltip = this.moderator ? raisedHandsUsers.join('\n') : ''
    },
    toggleCam () {
      if (this.vueConference.local.video.isMuted()) {
        this.vueConference.local.video.unmute()
      } else {
        this.vueConference.local.video.mute()
      }
    },
    getRemoteContainerClass () {
      const classes = []
      if (this.view === 'stripe') {
        classes.push('stripe')
      } else {
        classes.push('tile')
      }

      if (!Object.values(this.remote).length || !this.show_thumbnails) {
        classes.push('nobackground')
      }

      return classes
    },
    toggleView () {
      if (this.following) {
        return
      }

      if (this.tool) {
        return
      }

      if (this.view === 'stripe') {
        this.view = 'tile'
      } else {
        this.view = 'stripe'
      }
    },
    getMainStyle () {
      const {
        w,
        h
      } = fitTiles(1, 1)
      return {
        width: `${w}px`,
        height: `${h}px`
      }
    },
    getRemoteContainerStyle () {
      if (this.view === 'stripe') {
        return { '--video-item-width': '240px' }
      }

      const count = this.videoContainersSorted().length
      if (count <= 1) {
        const {
          w,
          h
        } = fitTiles(1, 1)
        return {
          '--video-item-width': w + 'px',
          '--video-item-height': h + 'px'
        }
      }

      if (count <= 2) {
        const {
          w,
          h
        } = fitTiles(2, 1)
        return {
          '--video-item-width': w + 'px',
          '--video-item-height': h + 'px'
        }
      }

      if (count <= 4) {
        const {
          w,
          h
        } = fitTiles(2, 2)
        return {
          '--video-item-width': w + 'px',
          '--video-item-height': h + 'px'
        }
      }

      if (count <= 9) {
        const {
          w,
          h
        } = fitTiles(3, 3)
        return {
          '--video-item-width': w + 'px',
          '--video-item-height': h + 'px'
        }
      }

      const {
        w,
        h
      } = fitTiles(4, 4)
      return {
        '--video-item-width': w + 'px',
        '--video-item-height': h + 'px'
      }
    },
    onLocalTrack (track) {
      const trackId = track.getId()
      const trackType = track.getType()

      track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {
        this.globalEventEmit(LOCAL_MUTED)
      })

      track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
        this.globalEventEmit(LOCAL_MUTED)
        this.local_video_muted = window?.vueConference.local?.video && window.vueConference.local.video.isMuted()
        Log('Local track stoped')
      })

      track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
        deviceId => Log(`Local track audio output device was changed to ${deviceId}`)
      )

      Log(`Local track ${trackType} ${trackId}`)

      // Store an index to be reactive
      this.$set(this.local, trackType, track)
      this.$set(this.local, 'display_name', this.global('display_name', 'Vai Vu User'))
      this.pin('local')

      this.$nextTick(() => {
        if (track.isVideoTrack()) {
          this.updateBlur()
        }
      })
    },
    /**
     * Event called on new participant tracks
     *
     * @param {JitsiTrack} track
     */
    onRemoteTrack (track) {
      // Do not add local
      if (track.isLocal()) {
        return
      }

      const local = 'remote'
      const trackId = track.getId()
      const trackType = track.getType()
      const participantId = track.getParticipantId()

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        (track) => {
          Log(`Remote track ${trackId} muted ` + participantId)
          this.$set(this.remote[participantId], trackType + 'Muted', track.muted)

          // Let recording know of tracks change
          this.globalEventEmit(TRACKS_UPDATED)

          if (isModerator() && track.muted === false) {
            this.globalEventEmit(REMOVE_BUTTON_STYLE, {})
          }
        }
      )
      track.addEventListener(
        JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        () => Log(local + ' track stoped')
      )
      track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
        deviceId => Log(local + ` track audio output device was changed to ${deviceId}`)
      )

      Log(`Track ${local} ${trackType} ${trackId} from ${participantId}`)

      // Store an index to be reactive
      if (!this.remote[participantId]) this.$set(this.remote, participantId, {})
      this.$set(this.remote[participantId], 'type', 'remote')
      this.$set(this.remote[participantId], trackType, track)
      this.$set(this.remote[participantId], 'last_activity', performance.now())

      // Let recording know of tracks change
      this.globalEventEmit(TRACKS_UPDATED)

      this.pin(participantId)
    },
    /**
     * Used for sorting
     *
     * @param participantId
     */
    onDominantChanged (participantId) {
      Log(participantId + ' dominant')

      for (const i in this.remote) {
        if (this.remote.hasOwnProperty(i)) {
          if (participantId === i) {
            continue
          }

          this.$set(this.remote[i], 'dominant', false)
        }
      }
      this.local.dominant = participantId === me()

      if (this.remote.hasOwnProperty(participantId)) {
        this.$set(this.remote[participantId], 'last_activity', performance.now())
        this.$set(this.remote[participantId], 'dominant', true)
        this.pin(participantId)
      }
    },
    onUserLeft (participantId) {
      const myId = me()
      if (participantId === myId) {
        showWarn(_('Meeting'), _('You\'re kicked'))
        this.disconnect(true)
        return
      }

      // If user leaving was pinned, pin local
      if (this.remote[participantId] && this.remote[participantId].pinned) {
        this.pin('local')
      }

      this.$delete(this.remote, participantId)
      Log(participantId + ' left')

      // Let recording know of tracks change
      this.globalEventEmit(TRACKS_UPDATED)
    },
    disconnect (force = false, delay = 1000) {
      if (this.disconnecting) {
        return
      }

      if (!force && this.recording) {
        this.dialog('ConfirmLeave').show(r => {
          if (r) {
            this.onGlobalEvent(RECORDING_CHANGED, v => {
              if (!v) {
                this.disconnect(true)
              }
            })
            this.globalEventEmit(STOP_RECORDING)
          }
        })

        return
      }

      this.disconnecting = true

      this.dialogHideAll()

      const name = 'EndLobby'
      const meetingId = getDecodedToken('meeting_id', this.$route.params.room)

      window.sessionStorage.removeItem('jwt')
      window.sessionStorage.removeItem('jwt_decoded')

      if (!window.g_conference || !window.g_conference.room) {
        setTimeout(() => {
          this.$router.replace({
            name,
            query: { end: meetingId }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        }, delay)
        return
      }

      window.g_conference
        .leave()
        .then(() => {
          setTimeout(() => {
            this.$router.replace({
              name,
              query: { end: meetingId }
            }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err
              }
            })
          }, delay)
        })
        .catch(() => {
          setTimeout(() => {
            // Move to lobby end w/o router
            location.href = this.$router.resolve({
              name,
              query: { end: meetingId }
            }).href
          }, delay)
        })
    },
    /**
     * Fire when another participant send its presence information "setDisplayName"
     */
    onParticipantName (participantId, displayName) {
      if (me() === participantId) return

      if (this.remote.hasOwnProperty(participantId)) {
        if (this.remote[participantId].display_name && this.remote[participantId].display_name === _('Someone')) {
          // Looks like just joined
          showInfo(_('%{display_name} joined this conference', { display_name: displayName }))
        }

        this.$set(this.remote[participantId], 'display_name', displayName)

        if (this.$refs.ButtonScreenShare.status === 'sharing') {
          confEventEmit(SHARE_STARTED, {
            attributes: {
              id: me()
            }
          })
        }
      }
    },
    onParticipantUpdated (participant, propertyName, oldValue, newValue) {
      const participantId = participant.getId()

      if (propertyName === 'raisedHand') {
        const raised = newValue === 'true'
        this.$set(this.remote[participantId], 'raised_hand', raised)
        const displayName = this.remote[participantId].display_name ?? _('Someone')
        if (raised && this.moderator) {
          this.$refs.ButtonRaiseHand.showPopover(_('%{display_name} wants to speak', { display_name: displayName }))
        }
        return
      }

      if (propertyName === 'features_jigasi' && (newValue === 'true' || newValue === true)) {
        const displayName = participantId ?? _('Phone')
        this.$set(this.remote[participantId], 'jigasi', true)
        this.$set(this.remote[participantId], 'videoMuted', true)
        this.$set(this.remote[participantId], 'display_name', displayName)
        showInfo(_('%{display_name} joined', { display_name: displayName }))
        return
      }

      Log(`User ${participantId} changed ${propertyName} to ${newValue}`)
    },
    onClearRaisedHand () {
      for (const i in this.remote) {
        this.$set(this.remote[i], 'raised_hand', false)
      }
    },
    connect (roomName) {
      roomName = roomName || this.$route.params.room
      this.connecting = true
      window.g_speak_time = {}

      JitsiMeetJS.mediaDevices.setAudioOutputDevice(this.global('audiooutput', '')).then().catch(() => {
      })

      connect(roomName)
        .then(connection => {
          window.g_connection = connection
          this.connecting = false
          return createAndJoinRoom(connection, roomName)
        })
        .then(conference => {
          window.g_conference = conference
          this.host = isHost()
          this.moderator = isModerator()
          this.restoreMeetingRole()
          this.globalEventEmit(MEETING_JOINED)

          conference.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, this.onParticipantName)
          conference.on(JitsiMeetJS.events.conference.KICKED, () => {
            this.mouseOverlayReset()
            showWarn(_('Meeting'), _('You\'re kicked'))
            this.disconnect(true, 2000)
          })
          conference.on(JitsiMeetJS.events.conference.PARTICIPANT_KICKED, (kicker, kicked) => {
            kicker = kicker ? this.remote[kicker.getId()].display_name : null
            kicked = kicked ? this.remote[kicked.getId()].display_name : null
            if (kicker && kicked) {
              showInfo(_('Meeting'), _('%{kicker} just kicked %{kicked} from the meeting', {
                kicker,
                kicked
              }))
            }
          })

          conference.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, (participantId, role) => {
            if (this.remote[participantId]) {
              this.$set(this.remote[participantId], 'moderator', role === 'moderator')
            }

            if (me() === participantId) {
              if (role === 'moderator') {
                setTimeout(() => {
                  if (this.closing) {
                    return
                  }

                  this.globalEventEmit(MODERATOR_GRANTED)
                }, 5000)
              }
            }
          })
          conference.on(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, this.onParticipantUpdated)
          conference.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined)
          conference.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft)
          conference.on(JitsiMeetJS.events.conference.NOISY_MIC, () => {
            showError(_('Your microphone appears to be noisy'), _('It sounds like your microphone is making noise, please consider muting or changing the device.'))
          })

          conference.on(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, () => {
            showError(_('Muted'), _('Trying to speak? You are muted.'))
          })

          conference.on(JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED, ({
            audio,
            video
          }) => {
            this.globalSet('start_audio_muted', audio)
            this.globalSet('start_video_muted', video)
          })

          conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, track => this.onRemoteTrack(track))
          conference.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, id => this.onDominantChanged(id))
          conference.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (participantId, body) => {
            if (me() === participantId) return
            this.globalEventEmit(CHAT_MESSAGE, {
              participant_id: participantId,
              display_name: this.remote[participantId].display_name ?? DEFAULT_DISPLAY_NAME,
              body,
              pvt: false
            })
          })
          conference.on(JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED, (participantId, body) => {
            if (me() === participantId) return
            this.globalEventEmit(CHAT_MESSAGE, {
              participant_id: participantId,
              display_name: this.remote[participantId].display_name ?? DEFAULT_DISPLAY_NAME,
              body,
              pvt: true
            })
          })
          conference.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (participant, data) => {
            if (data.type === 'e2e-ping-request') return
            if (data.type === 'e2e-ping-response') return
            if (data.type === 'stats') {
              // connectionQuality
              const participantId = participant.getId()
              if (participantId && this.remote[participantId]) {
                this.$set(this.remote[participantId], 'stats', data.values)
              }

              return
            }

            Log(data)
          })

          conference.on(JitsiMeetJS.events.conference._MEDIA_SESSION_STARTED, () => {
            Log('Media started')
            // Call room settings as soon as video and audio are available
            this.applyRoomSettings()
          })

          onConfEvent(DRAWING_STOP, this.onDrawingStop)
          onConfEvent(DRAWING_START, this.onDrawingStart)
          onConfEvent(DRAWING_PEN, this.onDrawing)

          onConfEvent(UNMUTE_POLICY, this.onUnmutePolicy)
          onConfEvent(CLEAR_RAISED_HAND, this.onClearRaisedHand)

          // When a moderator set to follow him, mute|unmute events will this one
          onConfEvent(FOLLOW_ME_COMMAND, this.onFollowMe)

          onConfEvent(DISABLE_VIDEO, async ({ attributes }) => {
            const { participant } = attributes

            if (participant !== me() || window.vueConference.local.video.isMuted()) return

            await window.vueConference.local.video.mute()
            this.globalEventEmit(LOCAL_MUTED)
          })

          // Called by guest, requesting to be a moderator
          window.g_conference.addCommandListener(MEETING_MODERATOR_REQUEST, this.onMeetingModeratorRequest)

          onConfEvent(MEETING_DISPLAY_NAME, ({ attributes }, participantId) => {
            if (participantId === me()) return
            this.onParticipantName(participantId, attributes.display_name)

            if (!this.remote[participantId]) {
              this.$set(this.remote, participantId, {})
            }

            const remote = this.remote[participantId]
            this.$set(remote, 'display_name', attributes.display_name)
            this.$set(remote, 'moderator', attributes.moderator === 'true')
            this.$set(remote, 'type', 'remote')
          })

          if (!this.$route.query.no_tracks) {
            createTracksAndAddToRoom(conference, track => this.onLocalTrack(track))
          }

          // When me, as moderator, have some itens set we need to propagate on room join
          setTimeout(this.sendCommandsToOthers, 2000)

          this.startedAt = performance.now()
          this.connecting = false

          if ('mediaSession' in navigator && window.MediaMetadata) {
            navigator.mediaSession.metadata = new window.MediaMetadata({
              title: 'Meeting',
              artist: 'Vaivu'
            })

            navigator.mediaSession.setActionHandler('play', () => {
              navigator.mediaSession.playbackState = 'playing'
              window.vueConference.local.audio.unmute()
              this.globalEventEmit(LOCAL_MUTED)
            })
            navigator.mediaSession.setActionHandler('pause', () => {
              navigator.mediaSession.playbackState = 'paused'
              window.vueConference.local.audio.mute()
              this.globalEventEmit(LOCAL_MUTED)
            })
            navigator.mediaSession.setActionHandler('stop', () => {
              this.disconnect()
            })
          }
        })
        .catch(error => {
          this.connecting = false

          if (error.name === 'ConferenceError') {
            showError('Connection', error.message)
            setTimeout(() => {
              this.disconnect(true)
            }, 5000)
            return
          }

          if (error.name === 'PasswordError') {
            showError('Connection', error.message)
            LogError(error)
            this.$router.replace({ path: '/' })
            return
          }

          LogError(error)
        })
    },
    /**
     * When Unmute policy is activated
     * - Users can't unmute
     * - Moderator will be muted, however can unmute
     * - Host-moderator nothing happens
     * @param value
     * @param id
     */
    onUnmutePolicy ({ value }, id) {
      if (id === me()) {
        return
      }

      if (value) {
        this.localMute()
        showPopover(this.$refs.ButtonMic.$el, _('The host has muted everyone'), { position: 'bottom' })
      }
    },
    localMute () {
      if (!window.vueConference.local.audio) return
      window.vueConference.local.audio.mute().then(() => {
        this.globalEventEmit(LOCAL_MUTED)
      })
    },
    /**
     * ButtonPen click handler
     */
    onDrawingPenClick () {
      if (this.tool && this.tool !== 'drawing') {
        Log('Another tool in use')
        return
      }

      const me = window.g_conference.myUserId()
      if (this.tool === 'drawing') {
        confEventEmit(DRAWING_STOP)
        this.globalEventEmit(DRAWING_STOP, {}, me)
        return
      }

      confEventEmit(DRAWING_START)
      this.globalEventEmit(DRAWING_START, {}, me)
    },
    onDrawingStop (data, id) {
      // Check owner
      if (this.tool_owner && this.tool_owner !== id) {
        Log(`User ${this.tool_owner} stopped drawing`)
        return
      }

      if (this.tool) {
        this.tool = ''
        this.tool_owner = ''
        this.$nextTick(() => {
          if (window.draw) {
            window.draw.remove()
            window.draw = null
          }

          this.globalEventEmit(DRAWING_STOPPED)
        })
      }
    },
    onDrawingStart (data, id) {
      // Check owner
      if (this.tool_owner && this.tool_owner !== id) {
        Log(`User ${this.tool_owner} is drawing`)
        return
      }

      if (!this.tool) {
        this.tool = 'drawing'
        this.tool_owner = id
        if (id === me()) {
          this.pin('local', 'force')
        } else {
          this.pin(id, 'force')
        }
        this.view = 'stripe'
        this.$nextTick(() => {
          initDrawing(this)
          this.globalEventEmit(DRAWING_STARTED)
        })
      }
    },
    onDrawing (data, id) {
      return processDrawingCommand(this, data, id)
    },
    /**
     * @param {string} participantId
     * @param {JitsiParticipant} participant
     */
    onUserJoined (participantId, participant) {
      Log(`User ${participantId} joined`)
      if (!this.remote[participantId]) {
        this.$set(this.remote, participantId, {})
      }

      const displayName = _('Someone')
      const remote = this.remote[participantId]
      this.$set(remote, 'display_name', displayName)
      this.$set(remote, 'moderator', participant.isModerator())
      this.$set(remote, 'type', 'remote')

      // Send all commands to the user that just joined
      this.sendCommandsToOthers()
    },
    /**
     * Handler for FOLLOW_ME_COMMAND
     */
    onFollowMe ({ attributes }, participantId) {
      if (me() === participantId) return
      Log('Follow me ' + participantId)
      if (this.remote[participantId]) {
        this.$set(this.remote[participantId], 'follow_me', (attributes.off !== 'true'))
      }

      this.following = this.isFollowing()
    },
    onMeetingModeratorRequest ({ attributes }, participantId) {
      this.grantModerator(participantId)
    },
    grantModerator (participantId) {
      if (!isModerator()) return
      window.g_conference.grantOwner(participantId)
      this.markAsModerator(participantId)
    },
    markAsModerator (participantId) {
      if (Object.prototype.hasOwnProperty.call(this.remote, participantId)) {
        this.$set(this.remote[participantId], 'moderator', true)
        showInfo(_('User has been set as moderator'))
      }
    },
    /**
     * Send my name to others
     */
    setDisplayName () {
      const displayName = this.global('display_name', 'Vai Vu User')
      this.$set(this.local, 'display_name', displayName)
      setTimeout(() => {
        if (!window.g_conference) return
        window.g_conference.setDisplayName(displayName)
        confEventEmit(MEETING_DISPLAY_NAME, {
          value: '',
          attributes: {
            display_name: displayName,
            moderator: isModerator()
          }
        })
      }, 5000)
    },
    sendCommandsToOthers () {
      this.setDisplayName()

      if (!isModerator()) {
        return
      }

      if (this.global('follows_me', false)) {
        confEventEmit(FOLLOW_ME_COMMAND, { attributes: {} })
      }

      window.g_conference.setStartMutedPolicy({
        audio: this.global('start_audio_muted'),
        video: this.global('start_video_muted')
      })
    },
    onWhiteboardCommand ({ attributes }) {
      if (this.tool && this.tool !== 'whiteboard') return
      this.tool = 'whiteboard'
      this.view = 'stripe'
      this.$nextTick(() => {
        this.whiteboard_frame = attributes.embedHtml
        updateMeetingWhiteboard(attributes)
      })
    },
    onWhiteboardClose () {
      if (this.free_plan) return
      if (this.tool && this.tool !== 'whiteboard') return
      this.tool = ''
      this.$nextTick(() => {
        this.whiteboard_frame = ''
      })
    },
    /**
     * Save user role for this meeeting
     * - cannot lose moderator privilegies
     */
    localSaveMeetingRole () {
      if ((window.sessionStorage.jwt_decoded === null) || (window.sessionStorage.jwt_decoded === '') || (typeof (window.sessionStorage.jwt_decoded) === 'undefined')) {
        Log('Failed to save user role | Token undefined')
        return
      }
      const uid = getDecodedToken('meeting_unique_id', !!window.g_default_is_host)
      if (!this.moderator) {
        if (this.global(MEETING_MODERATOR + uid) === '') this.globalSet(MEETING_MODERATOR + uid, false)
      } else {
        this.globalSet(MEETING_MODERATOR + uid, true)
      }
    },
    /**
     * Check previus role of current meeting and restore it
     */
    restoreMeetingRole () {
      if ((window.sessionStorage.jwt_decoded === null) || (window.sessionStorage.jwt_decoded === '') || (typeof (window.sessionStorage.jwt_decoded) === 'undefined')) {
        Log('Failed to save user role | Token undefined')
        return
      }
      const uid = getDecodedToken('meeting_unique_id', !!window.g_default_is_host)
      const isHost = getDecodedToken('isHost', !!window.g_default_is_host)
      const wasModerator = !!this.global(MEETING_MODERATOR + uid)
      if (wasModerator || isHost) this.requestMeetingRole()
    },
    /**
     * Make a request to other participants to be made a moderator
     */
    requestMeetingRole () {
      confEventEmit(MEETING_MODERATOR_REQUEST, { participant_id: me() })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixin";

.info-top {
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  z-index: 3;
}

@media (max-width: 501px) {
  .info-top {
    left: auto;
    right: 50px;
    margin-left: 0;
    margin-right: 0;
  }
}

.toolbar-side {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  > div {
    pointer-events: initial;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}

.toolbar-top {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 60px;
  width: 85%;
  z-index: 2;

  .button {
    margin-right: 20px;

    &:first-of-type {
      margin-left: 20px;
    }
  }

  @include media-sm() {
    .button {
      margin-right: 10px;

      &:first-of-type {
        margin-left: 10px;
      }
    }
  }
}

.nobackground {
  background: none !important;
}

.remote-container {
  --video-item-width: 240px;
  --video-item-height: calc(var(--video-item-width) / (16 / 9));

  &.stripe {
    position: absolute;
    left: 10px;
    bottom: 10px;
    max-width: calc(100% - 20px);
    background-color: $video-container-bg;
    border-radius: 10px;
    padding: 10px;

    .remote {
      display: block;
      white-space: nowrap;
      overflow-x: scroll;

      .video-container {
        margin-right: 4px;
        border: 2px solid transparent;
        overflow: hidden;
      }

      > div {
        display: inline-block;
      }

      .remote-row {
        display: inline-block;
      }
    }
  }

  &.tile {
    position: fixed;
    top: 60px;
    bottom: 60px;
    width: 100%;
    display: flex;
    align-items: center;

    .remote {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 100%;
          display: inline-block;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          height: 100%;
        }
      }

      .remote-row {
        display: flex;
        justify-content: center;
      }

      .video-container {
        margin: 5px;
        border: 5px solid #fff;
        overflow: hidden;

        &.dominant {
          border: 5px solid $primary;
        }
      }
    }
  }
}

.connecting {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .9);
}

</style>
