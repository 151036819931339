<template>
    <div class="icon-over-main">
        <SvgOverMutedVideo/>
    </div>
</template>

<script>
import { SvgOverMutedVideo } from '@/svgs'

export default {
  name: 'IconOverMutedVideo',
  components: {
    SvgOverMutedVideo
  }
}
</script>

<style scoped>
.icon-over-main {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 24px;
    height: 24px;
}
</style>
