import { getDecodedToken } from '@/utils/jwt'
import jQuery from 'jquery'
import config from '@/options/config'
import { Log, LogError } from '@/utils/common'

export function updateMeetingWhiteboard (whiteboardData) {
  const meetingUniqueID = getDecodedToken('meeting_unique_id')
  jQuery
    .ajax({
      url: `${config.arena.host}/webhook/v1/miro/meeting/${meetingUniqueID}`,
      method: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({ whiteboard_data: whiteboardData }),
      processData: false,
      async: true
    })
    .then(response => {
      if (response.success) {
        Log(response.message)
        return
      }

      LogError(response.message)
    })
    .catch(error => {
      LogError(error)
    })
}
