<template>
    <ButtonBase
        :class="{ enabled }"
        @click="toggleBoard()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" fill="#979797" r="28"/>
            <path d="M22.7501 32.5C22.638 32.5 22.526 32.5164 22.4191 32.5511C21.8116 32.7484 21.1727 32.875 20.5001 32.875C19.8274 32.875 19.1885 32.7484 18.5805 32.5511C18.4737 32.5164 18.3621 32.5 18.2501 32.5C15.341 32.5 12.9846 34.8662 13.0001 37.779C13.0066 39.0099 14.0187 39.9999 15.2501 39.9999H25.75C26.9814 39.9999 27.9935 39.0099 28 37.779C28.0155 34.8662 25.6591 32.5 22.7501 32.5ZM20.5001 31C22.9854 31 25 28.9853 25 26.5C25 24.0147 22.9854 22 20.5001 22C18.0148 22 16.0001 24.0147 16.0001 26.5C16.0001 28.9853 18.0148 31 20.5001 31ZM40.75 16H22.7501C21.5093 16 20.5001 17.043 20.5001 18.3245V20.5C21.5979 20.5 22.6141 20.8178 23.5 21.3344V19H40V32.5H37V29.5H31V32.5H27.4263C28.3216 33.2823 28.9788 34.3154 29.2868 35.5H40.75C41.9908 35.5 43 34.457 43 33.1754V18.3245C43 17.043 41.9908 16 40.75 16Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { MEETING_JOINED, WHITEBOARD_COMMAND, WHITEBOARD_COMMAND_CLOSE } from '@/utils/consts'
import { confEventEmit, globalEventEmit, onConfEvent, onGlobalEvent } from '@/utils/common'
import config from '@/options/config'
import { SimpleError } from '@/utils/exception'
import { showError } from '@/utils/notifications'
import { _ } from '@/lang'

export default {
  name: 'ButtonBoard',
  components: {
    ButtonBase
  },
  data () {
    return {
      enabled: false
    }
  },
  mounted () {
    onConfEvent(WHITEBOARD_COMMAND, () => {
      this.enabled = true
    })

    onConfEvent(WHITEBOARD_COMMAND_CLOSE, () => {
      this.enabled = false
    })

    onGlobalEvent(WHITEBOARD_COMMAND, () => {
      this.enabled = true
    })

    onGlobalEvent(WHITEBOARD_COMMAND_CLOSE, () => {
      this.enabled = false
    })
  },
  methods: {
    toggleBoard () {
      if (!window.miroBoardsPicker) {
        return
      }

      if (this.enabled) {
        // Close
        confEventEmit(WHITEBOARD_COMMAND_CLOSE, {})
        globalEventEmit(WHITEBOARD_COMMAND_CLOSE, {})
        return
      }

      window.miroBoardsPicker.open({
        clientId: config.miro.clientId,
        action: 'access-link',
        allowCreateAnonymousBoards: true,
        getToken: async () => {
          const response = await fetch(config.miro.host)
          if (response.status === 200) {
            return response.text()
          }

          throw new SimpleError(_('Failed to fetch Whiteboard access token'))
        },
        success (miroResponse) {
          const data = {
            value: 'miro',
            attributes: miroResponse
          }
          confEventEmit(WHITEBOARD_COMMAND, data)
          globalEventEmit(WHITEBOARD_COMMAND, data)
        },
        error (e) {
          showError(_('Whiteboard'), typeof e === 'string' ? e : _('Something went wrong'))
          globalEventEmit(WHITEBOARD_COMMAND_CLOSE, {})
        },
        cancel () {
          globalEventEmit(WHITEBOARD_COMMAND_CLOSE, {})
        }
      })
    }
  }
}
</script>
