/**
 * Ref
 * https://stackoverflow.com/questions/32265207/drawing-svg-with-mouse.
 */
import { DRAWING_PEN, DRAWING_START } from '@/utils/consts'
import { confEventEmit, Log, me } from '@/utils/common'
import $ from 'jquery'

const SVG = window.SVG

export function processDrawingCommand (vue, data, id) {
  const attr = data.attributes
  const myId = me()
  if (id === myId) {
    return true
  }

  if (window.draw) {
    const $drawing = $(vue.$refs.drawing)
    const group = window.SVG.get(id) || window.draw.group().attr({ id })
    const scale = $drawing.width() / attr.width
    group.scale(scale, scale, 0, 0)
    group.svg(data.value).last()
      .stroke({ color: 'purple' })
      .animate({
        ease: '-',
        delay: '10s'
      })
      .stroke({ opacity: 0 })
      .after(function () {
        this.remove()
      })
  }

  return true
}

/**
 * Initialise SVG Drawings
 */
export function initDrawing (vue) {
  if (window.draw) return

  window.draw = SVG('drawing')// #drawing
  let drawObject
  let finishing = false

  const getDrawObject = () => {
    const option = {
      stroke: 'red',
      'stroke-width': 6,
      fill: 'none'
    }

    return window.draw.polyline().attr(option)
  }

  const finish = event => {
    if (finishing) return
    finishing = true

    if (drawObject) {
      const currentDrawing = drawObject.draw('stop', event)
      const $drawing = $(vue.$refs.drawing)
      confEventEmit(DRAWING_PEN, {
        value: currentDrawing.svg(),
        attributes: {
          width: $drawing.width(),
          height: $drawing.height(),
          top: currentDrawing.y()
        }
      })

      currentDrawing
        .animate({
          ease: '-',
          delay: '10s'
        })
        .stroke({ opacity: 0 })
        .after(() => {
          currentDrawing.remove()
        })

      drawObject = null
    }

    finishing = false
  }

  window.draw.on('mousedown', event => {
    event.stopPropagation()
    event.preventDefault()
    drawObject = getDrawObject()
    drawObject.draw(event)
    window.mousedownTime = window.performance.now()
  })

  window.draw.on('mousemove', event => {
    if (finishing) return

    if (drawObject) {
      drawObject.draw('point', event)

      if ((window.performance.now() - window.mousedownTime) > 10000) {
        finish(event)
      }
    }
  })

  window.draw.on('mouseout', event => {
    if (event.toElement === document.getElementById('drawing')) {
      finish(event)
    }
  })

  window.draw.on('mouseup', event => {
    finish(event)
  })

  // This is custom extension of line, polyline, polygon which doesn't draw the circle on the line.
  SVG.Element.prototype.draw.extend('line polyline polygon', {
    init (e) {
      // When we draw a polygon, we immediately need 2 points.
      // One start-point and one point at the mouse-position
      this.set = new SVG.Set()

      const p = this.startPoint
      const arr = [
        [p.x, p.y],
        [p.x, p.y]
      ]

      this.el.plot(arr)
    },

    // The calc-function sets the position of the last point to the mouse-position (with offset ofc)
    calc (e) {
      const arr = this.el.array().valueOf()
      arr.pop()

      if (e) {
        const p = this.transformPoint(e.clientX, e.clientY)
        arr.push(this.snapToGrid([p.x, p.y]))
      }

      this.el.plot(arr)
    },
    /**
     *
     * @param evnt
     */
    point (evnt) {
      if (this.el.type.indexOf('poly') > -1) {
        // Add the new Point to the point-array
        const p = this.transformPoint(evnt.clientX, evnt.clientY)
        const arr = this.el.array().valueOf()

        arr.push(this.snapToGrid([p.x, p.y]))

        this.el.plot(arr)

        // Fire the `drawpoint`-event, which holds the coords of the new Point
        this.el.fire('drawpoint', {
          event: evnt,
          p: {
            x: p.x,
            y: p.y
          },
          m: this.m
        })

        return
      }

      // We are done, if the element is no polyline or polygon
      this.stop(evnt)
    },
    clean () {
      // Remove all circles
      this.set.each(function () {
        this.remove()
      })

      this.set.clear()
      delete this.set
    }
  })
}
