<template>
    <div v-cloak class="body">
        <div v-show="!loaded" class="loadingDiv">
            <p style="font-size: 18px">Loading...</p>
            <br>
            <rotate-square2 :background="'#FFFFFF'"></rotate-square2>
        </div>
        <transition name="fade">
            <div v-show="loaded" class="end-container">
                <div class="end">
                    <h3 class="end-title text-center mt-5">
                        Thanks
                    </h3>
                    <div class="text-center mt-5">
                        <p class="info-end">
                            That was a great meeting.<br>
                            We enjoyed seeing you!
                        </p>
                    </div>
                    <div class="row mt-5">
                        <svg fill="none" height="181" viewBox="0 0 270 181" width="270" xmlns="http://www.w3.org/2000/svg">
                            <path d="M239.624 141.348H225.118C225.118 141.765 210.167 142.103 210.167 142.103C209.933 142.28 209.719 142.482 209.53 142.707C208.962 143.385 208.652 144.241 208.654 145.125V145.729C208.654 146.73 209.052 147.691 209.76 148.399C210.468 149.107 211.429 149.505 212.431 149.506H239.624C240.626 149.505 241.586 149.107 242.294 148.399C243.002 147.69 243.4 146.73 243.401 145.729V145.125C243.4 144.123 243.001 143.163 242.293 142.455C241.585 141.747 240.626 141.349 239.624 141.348Z" fill="#3F3D56"/>
                            <ellipse cx="215.052" cy="172.66" fill="#717171" rx="54.0521" ry="7.81476"/>
                            <path d="M228.141 149.356H224.213V174.736H228.141V149.356Z" fill="#3F3D56"/>
                            <path d="M239.321 174.598C239.321 175.023 233.368 174.763 226.026 174.763C218.683 174.763 212.731 175.023 212.731 174.598C212.731 174.174 218.683 170.808 226.026 170.808C233.368 170.808 239.321 174.174 239.321 174.598Z" fill="#3F3D56"/>
                            <path d="M206.383 171.26L202.679 171.26L200.916 156.972L206.384 156.972L206.383 171.26Z" fill="#FFB8B8"/>
                            <path d="M200.033 170.202H207.177V174.7H195.535C195.535 173.507 196.009 172.363 196.852 171.519C197.696 170.676 198.84 170.202 200.033 170.202Z" fill="#2F2E41"/>
                            <path d="M200.601 169.193L197.066 168.083L199.662 153.923L204.879 155.56L200.601 169.193Z" fill="#FFB8B8"/>
                            <path d="M194.859 166.281L201.675 168.42L200.328 172.712L189.22 169.226C189.397 168.663 189.683 168.139 190.062 167.686C190.441 167.233 190.906 166.86 191.429 166.586C191.953 166.313 192.525 166.145 193.114 166.093C193.702 166.04 194.295 166.105 194.859 166.281Z" fill="#2F2E41"/>
                            <path
                                d="M229.704 138.871C229.618 138.151 229.464 137.441 229.244 136.75L228.552 135.879L226.752 133.619L226.673 133.622C226.54 133.628 225.987 133.659 225.132 133.725H225.123C224.594 133.767 223.948 133.822 223.213 133.891C223.02 133.912 222.818 133.931 222.609 133.952V137.106H226.386C226.57 137.106 226.747 137.173 226.885 137.295C227.022 137.416 227.112 137.583 227.135 137.765C227.138 137.798 227.141 137.828 227.141 137.862C227.141 138.062 227.062 138.254 226.92 138.396C226.778 138.537 226.586 138.617 226.386 138.617H204.45C204.17 138.802 203.902 139.004 203.646 139.221C202.873 139.819 202.316 140.653 202.06 141.596C200.779 147.228 198.443 157.731 197.754 160.816C197.704 161.049 197.715 161.29 197.787 161.517C197.858 161.743 197.988 161.948 198.162 162.109C198.29 162.229 198.441 162.323 198.606 162.384L201.332 163.406C201.444 163.447 201.56 163.474 201.679 163.487C201.694 163.487 201.706 163.49 201.721 163.49C201.749 163.493 201.778 163.494 201.806 163.493C202.081 163.492 202.35 163.408 202.577 163.252C202.804 163.095 202.979 162.874 203.078 162.617L205.229 156.973L208.544 148.271L209.855 144.829C211.375 145.186 224.126 148.008 228.395 143.46C228.817 143.012 229.149 142.488 229.374 141.916C229.734 140.944 229.847 139.898 229.704 138.871Z" fill="#2F2E41"
                            />
                            <path
                                d="M233.402 139.693C233.335 139.424 233.26 139.146 233.172 138.865L230.68 135.734L230.601 135.737C230.444 135.747 229.698 135.786 228.552 135.879C227.253 135.988 225.437 136.163 223.398 136.438C223.337 136.444 223.274 136.454 223.213 136.463C223.014 136.49 222.811 136.517 222.609 136.547V137.106H226.386C226.569 137.106 226.747 137.173 226.885 137.295C227.022 137.416 227.112 137.583 227.135 137.765C227.138 137.798 227.141 137.828 227.141 137.862C227.141 138.062 227.062 138.254 226.92 138.396C226.778 138.537 226.586 138.617 226.386 138.617H213.037C212.454 138.807 211.892 139.007 211.354 139.221C208.532 140.339 206.42 141.805 205.988 143.711C205.842 144.352 205.682 145.056 205.513 145.805C204.885 148.591 204.12 152.002 203.428 155.102V155.105C203.286 155.746 203.144 156.368 203.008 156.973C202.431 159.571 201.942 161.774 201.682 162.931C201.676 162.964 201.67 162.995 201.664 163.028V163.034C201.634 163.268 201.663 163.506 201.748 163.726L201.751 163.729V163.732C201.834 163.943 201.97 164.129 202.144 164.273C202.261 164.369 202.393 164.446 202.534 164.499L205.259 165.521C205.411 165.577 205.572 165.607 205.734 165.608C205.961 165.609 206.184 165.552 206.383 165.442C206.669 165.287 206.89 165.035 207.006 164.732L212.874 149.334L213.783 146.944C215.303 147.301 228.054 150.12 232.323 145.576C233.167 144.663 233.648 143.473 233.677 142.231C233.707 141.376 233.614 140.521 233.402 139.693Z" fill="#2F2E41"
                            />
                            <path
                                d="M233.066 113.037C233.018 112.979 232.966 112.922 232.912 112.867C232.107 112.021 231.114 111.378 230.012 110.99C228.911 110.602 227.733 110.48 226.576 110.635C225.566 110.767 224.566 110.964 223.582 111.224C222.31 111.551 221.146 112.204 220.204 113.118C219.47 113.83 218.892 114.686 218.506 115.633C218.121 116.579 217.936 117.596 217.965 118.617C217.977 119.031 217.98 119.454 217.974 119.883C217.974 120.083 217.971 120.285 217.968 120.488H220.947C221.076 120.487 221.205 120.503 221.331 120.533C221.694 120.62 222.018 120.827 222.25 121.12C222.482 121.413 222.608 121.776 222.609 122.15V136.206C222.811 136.263 223.014 136.321 223.213 136.381C223.276 136.402 223.337 136.42 223.397 136.438C223.467 136.459 223.536 136.481 223.603 136.502C224.216 136.692 224.815 136.894 225.4 137.106H226.386C226.569 137.106 226.746 137.173 226.884 137.294C227.022 137.416 227.111 137.583 227.135 137.765C227.34 137.846 227.54 137.928 227.736 138.01C228.434 138.3 229.096 138.59 229.703 138.871C230.522 139.245 231.25 139.602 231.861 139.913C232.053 140.011 232.265 140.063 232.48 140.064C232.763 140.063 233.038 139.972 233.266 139.804C233.313 139.769 233.358 139.732 233.402 139.693C233.563 139.541 233.685 139.352 233.758 139.143C233.83 138.933 233.85 138.709 233.816 138.49C233.504 136.116 233.413 133.719 233.544 131.329C233.628 129.32 233.809 127.259 234.045 125.268C234.172 124.189 234.314 123.132 234.462 122.113C234.604 121.14 234.755 120.207 234.903 119.324C235.098 118.206 235.033 117.057 234.715 115.967C234.396 114.877 233.832 113.875 233.066 113.037Z" fill="#00C881"
                            />
                            <path d="M220.681 113.284C220.526 113.218 220.366 113.162 220.203 113.118C219.398 112.901 218.542 112.981 217.791 113.344C217.04 113.706 216.444 114.326 216.112 115.091L214.036 119.883L213.777 120.488H220.947C221.076 120.487 221.205 120.503 221.33 120.533L221.572 119.974L222.491 117.853C222.856 117.007 222.87 116.051 222.531 115.194C222.191 114.338 221.526 113.651 220.681 113.284Z" fill="#00C881"/>
                            <path d="M227.128 108.275C231.227 108.275 234.549 104.953 234.549 100.854C234.549 96.7553 231.227 93.4327 227.128 93.4327C223.029 93.4327 219.707 96.7553 219.707 100.854C219.707 104.953 223.029 108.275 227.128 108.275Z" fill="#FFB8B8"/>
                            <path d="M234.462 122.113L230.226 122.228L229.918 125.204L229.848 125.893L223.213 128.872L222.609 129.144V134.441L223.213 134.269L225.123 133.725H225.132L233.544 131.329L233.64 131.302C234.32 131.105 234.918 130.695 235.346 130.132C235.774 129.568 236.009 128.882 236.015 128.174L236.036 124.66L236.055 122.071L234.462 122.113Z" fill="#FFB8B8"/>
                            <path d="M235.84 116.227C235.812 115.772 235.695 115.326 235.495 114.916C235.295 114.506 235.016 114.139 234.674 113.837C233.983 113.226 233.079 112.915 232.159 112.972C231.239 113.028 230.379 113.448 229.768 114.138C229.158 114.829 228.847 115.733 228.903 116.653L229.365 124.195C229.378 124.394 229.434 124.587 229.529 124.761C229.625 124.936 229.758 125.087 229.918 125.204C230.174 125.395 230.489 125.488 230.807 125.467L234.046 125.268L235.028 125.207C235.207 125.199 235.382 125.154 235.543 125.075C235.704 124.997 235.847 124.886 235.964 124.751C235.99 124.722 236.015 124.692 236.037 124.66C236.229 124.403 236.323 124.086 236.303 123.766L235.84 116.227Z" fill="#6C63FF"/>
                            <path
                                d="M223.603 136.502H223.213V136.463C223.014 136.49 222.811 136.517 222.609 136.547V137.106H225.401C224.815 136.895 224.215 136.693 223.603 136.502ZM223.603 136.502H223.213V136.463C223.014 136.49 222.811 136.517 222.609 136.547V137.106H225.401C224.815 136.895 224.215 136.693 223.603 136.502ZM226.386 136.502H223.213V122.15C223.212 121.658 223.052 121.18 222.756 120.788C222.46 120.396 222.045 120.11 221.572 119.974C221.37 119.913 221.159 119.882 220.947 119.883H194.055C193.455 119.884 192.879 120.123 192.454 120.548C192.029 120.973 191.79 121.549 191.789 122.15V136.955C191.789 137.556 192.028 138.132 192.453 138.557C192.878 138.982 193.454 139.221 194.055 139.221H226.386C226.72 139.221 227.043 139.097 227.292 138.873C227.542 138.65 227.7 138.342 227.736 138.01C227.742 137.961 227.746 137.911 227.745 137.862C227.745 137.501 227.601 137.156 227.347 136.901C227.092 136.646 226.746 136.502 226.386 136.502H226.386ZM227.141 137.862C227.141 138.062 227.061 138.254 226.92 138.396C226.778 138.537 226.586 138.617 226.386 138.617H194.055C193.615 138.617 193.192 138.442 192.881 138.13C192.569 137.818 192.394 137.396 192.394 136.955V122.15C192.394 121.709 192.569 121.286 192.881 120.975C193.192 120.663 193.615 120.488 194.055 120.488H220.947C221.076 120.487 221.205 120.503 221.331 120.533C221.694 120.62 222.018 120.827 222.25 121.12C222.482 121.413 222.608 121.776 222.609 122.15V137.106H226.386C226.569 137.106 226.747 137.173 226.884 137.295C227.022 137.416 227.111 137.583 227.135 137.765C227.138 137.798 227.141 137.828 227.141 137.862H227.141ZM223.603 136.502H223.213V136.463C223.014 136.49 222.811 136.517 222.609 136.547V137.106H225.401C224.815 136.895 224.215 136.693 223.603 136.502Z" fill="#3F3D56"
                            />
                            <path d="M207.5 131.366C208.502 131.366 209.313 130.554 209.313 129.553C209.313 128.551 208.502 127.74 207.5 127.74C206.499 127.74 205.688 128.551 205.688 129.553C205.688 130.554 206.499 131.366 207.5 131.366Z" fill="#00C881"/>
                            <path d="M225.543 97.9102C223.82 99.45 221.508 101.103 219.398 99.9747C218.012 99.2339 217.395 97.6094 217.718 96.0716C218.3 93.3063 220.691 91.8121 223.006 90.6042C226.015 89.034 229.336 87.6647 232.705 88.0729C236.075 88.4811 239.367 91.2654 239.208 94.6558C239.08 97.3823 236.853 99.9258 237.558 102.563C238.267 105.217 241.488 106.244 244.232 106.379C246.976 106.514 249.996 106.28 252.173 107.956C254.949 110.094 254.905 114.565 252.928 117.459C250.952 120.353 247.614 121.981 244.335 123.219C239.992 124.859 235.217 126.044 230.733 124.838C226.25 123.632 222.324 119.33 222.982 114.734C223.26 112.793 224.281 111.05 225.267 109.355C226.253 107.659 227.244 105.891 227.451 103.941C227.624 102.316 227.107 100.522 225.909 99.4747C225.692 99.2923 225.538 99.046 225.47 98.7709C225.402 98.4958 225.422 98.2062 225.529 97.9437L225.543 97.9102Z" fill="#2F2E41"/>
                            <ellipse cx="54.0521" cy="90.3039" fill="#717171" rx="54.0521" ry="7.81476"/>
                            <path d="M24.7468 59.0972V59.7729C24.7477 60.8927 25.193 61.9663 25.9848 62.7581C26.7766 63.5499 27.8502 63.9952 28.97 63.9962H59.3773C60.4973 63.9957 61.5712 63.5507 62.3631 62.7588C63.1551 61.9668 63.6002 60.8929 63.6006 59.7729V59.0972C63.6027 58.1089 63.2556 57.1516 62.6206 56.3943C62.4093 56.1429 62.1702 55.9162 61.9078 55.7186C61.9078 55.7186 45.1905 55.3402 45.1905 54.874H28.97C27.8504 54.8755 26.7771 55.321 25.9854 56.1126C25.1938 56.9043 24.7483 57.9776 24.7468 59.0972Z" fill="#3F3D56"/>
                            <path d="M41.8097 92.2086H46.2019V63.8284H41.8097V92.2086Z" fill="#3F3D56"/>
                            <path d="M29.3089 92.0543C29.3089 92.5289 35.9645 92.2379 44.1747 92.2379C52.3849 92.2379 59.0405 92.5289 59.0405 92.0543C59.0405 91.5798 52.3849 87.8165 44.1747 87.8165C35.9645 87.8165 29.3089 91.5798 29.3089 92.0543Z" fill="#3F3D56"/>
                            <path d="M66.3364 88.5788H70.4786L72.449 72.6021L66.3358 72.6024L66.3364 88.5788Z" fill="#A0616A"/>
                            <path d="M78.4672 92.4245L65.4492 92.4251L65.449 87.3954L73.4373 87.395C74.7712 87.395 76.0506 87.9248 76.9938 88.8681C77.9371 89.8113 78.4671 91.0905 78.4672 92.4245L78.4672 92.4245Z" fill="#2F2E41"/>
                            <path d="M72.8029 86.267L76.7548 85.0267L73.8519 69.1929L68.0191 71.0235L72.8029 86.267Z" fill="#A0616A"/>
                            <path d="M85.5282 86.3047L73.1075 90.2025L71.6015 85.4036L79.2233 83.0118C80.4961 82.6123 81.8754 82.7349 83.0577 83.3524C84.2401 83.97 85.1288 85.0319 85.5282 86.3047L85.5282 86.3047Z" fill="#2F2E41"/>
                            <path d="M70.0313 78.9135L62.4541 59.0234C60.7541 59.4226 46.4981 62.5772 41.7234 57.4935C40.0871 55.7511 39.7679 53.2267 40.7748 49.9903L43.5614 46.4886L43.648 46.4933C44.7015 46.5487 69.4723 47.9371 71.1704 55.4089C72.6021 61.7081 75.2168 73.4524 75.9839 76.9019C76.0617 77.2593 76.0085 77.6328 75.834 77.9542C75.6595 78.2757 75.3753 78.5238 75.0332 78.6532L71.986 79.7958C71.8162 79.8597 71.6363 79.8925 71.4549 79.8925C71.1464 79.8924 70.8452 79.7989 70.5911 79.6241C70.3369 79.4493 70.1417 79.2016 70.0313 78.9135Z" fill="#2F2E41"/>
                            <path d="M65.6395 81.2786L58.0622 61.3885C56.3622 61.7877 42.1063 64.9419 37.3316 59.8585C35.6952 58.1161 35.3761 55.5917 36.383 52.3554L39.1695 48.8537L39.2561 48.8583C40.3097 48.9137 65.0805 50.3021 66.7785 57.774C68.2102 64.0732 70.8249 75.8174 71.5921 79.267C71.6699 79.6244 71.6167 79.9978 71.4422 80.3193C71.2676 80.6407 70.9835 80.8888 70.6414 81.0183L67.5941 82.1609C67.4243 82.2248 67.2444 82.2575 67.063 82.2575C66.7546 82.2575 66.4534 82.1639 66.1992 81.9891C65.9451 81.8144 65.7499 81.5666 65.6395 81.2786Z" fill="#2F2E41"/>
                            <path d="M58.8242 42.6891C57.7012 42.6887 56.5976 42.3967 55.6213 41.8417C54.645 41.2866 53.8296 40.4876 53.2548 39.5228L50.8484 35.4463L56.5681 32.3201L58.9968 35.65L75.4754 33.9139C75.5556 33.7688 75.6458 33.6295 75.7454 33.497C76.0616 33.0809 76.4619 32.7361 76.9202 32.4849C77.3785 32.2338 77.8846 32.082 78.4054 32.0395C78.9286 31.9946 79.4554 32.0622 79.9503 32.2377C80.4452 32.4133 80.8968 32.6927 81.2748 33.0572C81.8807 33.6383 82.2726 34.4072 82.3866 35.2391C82.5007 36.0709 82.3303 36.9169 81.9031 37.6397C81.6359 38.0901 81.2765 38.4789 80.8484 38.7807C80.4204 39.0826 79.9335 39.2905 79.4195 39.391C78.9055 39.4915 78.3761 39.4823 77.8659 39.364C77.3557 39.2456 76.8763 39.0208 76.4591 38.7043C76.3624 38.6303 76.2696 38.5513 76.1811 38.4677L60.3981 42.4918C59.8838 42.623 59.355 42.6892 58.8242 42.6891Z" fill="#A0616A"/>
                            <path d="M36.276 53.4055C36.0479 53.2412 35.8698 53.0169 35.7614 52.7576C35.6529 52.4983 35.6184 52.214 35.6616 51.9363C36.6016 45.739 35.52 36.8613 34.447 30.505C34.2274 29.2153 34.3129 27.8921 34.6966 26.6414C35.0803 25.3907 35.7516 24.2472 36.6567 23.3026C37.5618 22.358 38.6756 21.6385 39.9088 21.2018C41.142 20.7651 42.4603 20.6232 43.7582 20.7876C44.8874 20.9368 46.006 21.1573 47.1073 21.4478C48.9367 21.9085 50.5549 22.9779 51.6959 24.4803C52.8369 25.9827 53.4327 27.8286 53.3854 29.7145C53.225 35.8481 54.4705 43.501 55.0134 46.5065C55.0504 46.7129 55.0445 46.9248 54.9961 47.1289C54.9476 47.3329 54.8576 47.5248 54.7317 47.6926C54.6082 47.8586 54.4514 47.997 54.2713 48.0988C54.0912 48.2006 53.8918 48.2636 53.6859 48.2838C47.6692 48.8979 41.1809 51.832 37.8489 53.5263C37.6347 53.6368 37.3974 53.6948 37.1564 53.6955C36.8396 53.6945 36.5314 53.5929 36.276 53.4055Z" fill="#CCCCCC"/>
                            <path d="M52.5173 37.421C52.3313 37.348 52.1617 37.2388 52.0183 37.0997C51.8748 36.9607 51.7604 36.7946 51.6815 36.611L48.3261 28.8597C48.123 28.3914 48.0141 27.8876 48.0057 27.3772C47.9973 26.8668 48.0896 26.3597 48.2773 25.8849C48.465 25.4102 48.7444 24.9771 49.0996 24.6104C49.4547 24.2437 49.8787 23.9506 50.3472 23.7478C50.8157 23.545 51.3195 23.4366 51.83 23.4286C52.3404 23.4207 52.8474 23.5134 53.322 23.7015C53.7966 23.8896 54.2294 24.1693 54.5958 24.5248C54.9622 24.8803 55.255 25.3045 55.4574 25.7731L58.8129 33.5244C58.9725 33.8945 58.9788 34.3128 58.8305 34.6875C58.6822 35.0623 58.3913 35.3629 58.0216 35.5235L53.6808 37.4025C53.4978 37.4824 53.3006 37.5252 53.1009 37.5284C52.9012 37.5316 52.7028 37.4951 52.5173 37.421H52.5173Z" fill="#CCCCCC"/>
                            <path d="M43.1409 19.9742C47.7239 19.9742 51.4391 16.259 51.4391 11.676C51.4391 7.09308 47.7239 3.37787 43.1409 3.37787C38.558 3.37787 34.8428 7.09308 34.8428 11.676C34.8428 16.259 38.558 19.9742 43.1409 19.9742Z" fill="#A0616A"/>
                            <path
                                d="M38.1262 19.0483C38.0087 19.184 37.8651 19.2948 37.704 19.374C37.5429 19.4533 37.3676 19.4994 37.1883 19.5097C37.0091 19.52 36.8296 19.4942 36.6605 19.434C36.4914 19.3737 36.3361 19.2801 36.2037 19.1588C34.3534 17.661 32.8859 15.7445 31.9223 13.5676C30.8435 10.9962 30.6854 7.85814 31.9132 5.44784C32.9511 3.41029 34.8142 2.11328 36.6743 1.05323C37.3873 0.603935 38.1687 0.273664 38.9879 0.0753677C39.4016 -0.0185395 39.8304 -0.0247623 40.2467 0.0570973C40.663 0.138957 41.0575 0.307084 41.4049 0.550657C41.7735 0.83095 42.0588 1.20636 42.2301 1.63656C42.4014 2.06677 42.4523 2.5355 42.3773 2.99244C43.2281 1.87161 44.3751 1.01077 45.689 0.506971C46.4952 0.160003 47.3959 0.0996718 48.2411 0.336031C49.4356 0.734735 50.3198 1.94095 50.8118 3.23691C51.2097 4.22909 51.3554 5.30444 51.236 6.36674C51.1839 6.87399 51.0291 7.36534 50.7812 7.81091C50.5332 8.25649 50.1972 8.64697 49.7936 8.9586C48.8193 9.6309 47.564 9.56263 46.4309 9.26508C45.2977 8.96752 44.198 8.46302 43.0451 8.32236C41.8922 8.18171 40.6145 8.48666 39.9276 9.51497C39.4438 10.2391 39.3298 11.1931 39.2311 12.1024L38.7177 16.832C38.6814 17.5086 38.5357 18.1749 38.2861 18.8049C38.2438 18.8928 38.1901 18.9746 38.1262 19.0483Z" fill="#2F2E41"
                            />
                            <path d="M68.1403 68.3329H54.669C54.4507 68.3336 54.2351 68.2845 54.0386 68.1893C53.8422 68.0941 53.6701 67.9553 53.5354 67.7835C53.3977 67.6094 53.3016 67.4061 53.2543 67.1892C53.2071 66.9723 53.2099 66.7475 53.2627 66.5319C54.5228 61.2538 54.5227 55.7532 53.2626 50.4751C53.2099 50.2594 53.207 50.0346 53.2543 49.8177C53.3016 49.6008 53.3977 49.3976 53.5354 49.2235C53.6701 49.0517 53.8422 48.9129 54.0386 48.8177C54.2351 48.7225 54.4507 48.6733 54.669 48.674H68.1403C68.4845 48.6747 68.8173 48.7975 69.0794 49.0206C69.3416 49.2437 69.516 49.5526 69.5718 49.8922C70.4504 55.5994 70.4504 61.4075 69.5718 67.1147C69.516 67.4544 69.3416 67.7633 69.0794 67.9863C68.8173 68.2094 68.4845 68.3322 68.1403 68.3329Z" fill="#A3A3A3"/>
                            <path d="M57.8014 55.9976V55.6017C57.8017 55.333 57.9086 55.0753 58.0986 54.8853C58.2886 54.6953 58.5462 54.5884 58.815 54.5881H65.8842C66.1529 54.5884 66.4106 54.6953 66.6006 54.8853C66.7906 55.0753 66.8975 55.333 66.8978 55.6017V55.9976C66.8975 56.2664 66.7906 56.524 66.6006 56.714C66.4106 56.904 66.1529 57.0109 65.8842 57.0112H58.815C58.5462 57.0109 58.2886 56.904 58.0986 56.714C57.9086 56.524 57.8017 56.2664 57.8014 55.9976Z" fill="white"/>
                            <path d="M57.8014 60.1684V59.7723C57.8017 59.5036 57.9086 59.2459 58.0986 59.0559C58.2886 58.8659 58.5462 58.759 58.815 58.7587H65.8842C66.1529 58.759 66.4106 58.8659 66.6006 59.0559C66.7906 59.2459 66.8975 59.5036 66.8978 59.7723V60.1684C66.8975 60.4371 66.7906 60.6948 66.6006 60.8848C66.4106 61.0748 66.1529 61.1817 65.8842 61.182H58.815C58.5462 61.1817 58.2886 61.0748 58.0986 60.8848C57.9086 60.6948 57.8017 60.4371 57.8014 60.1684Z" fill="white"/>
                            <path d="M62.1468 43.9216C62.0068 43.7445 61.9091 43.5379 61.8611 43.3174C61.813 43.0969 61.8159 42.8684 61.8695 42.6492C63.1504 37.2844 63.1504 31.6934 61.8694 26.3286C61.8159 26.1094 61.813 25.8808 61.8611 25.6603C61.9091 25.4398 62.0069 25.2332 62.1468 25.0562C62.2838 24.8817 62.4587 24.7406 62.6584 24.6439C62.8581 24.5472 63.0772 24.4972 63.299 24.4979H76.9915C77.3414 24.4986 77.6797 24.6234 77.9462 24.8502C78.2126 25.0769 78.39 25.3909 78.4466 25.7362C79.3398 31.5371 79.3398 37.4406 78.4466 43.2416C78.39 43.5869 78.2126 43.9009 77.9462 44.1276C77.6797 44.3544 77.3414 44.4792 76.9915 44.4798H63.299C63.0772 44.4805 62.8581 44.4306 62.6584 44.3339C62.4587 44.2371 62.2837 44.0961 62.1468 43.9216Z" fill="#00C881"/>
                            <path d="M74.3493 31.8707H67.1363C66.8675 31.8704 66.6099 31.7635 66.4199 31.5735C66.2299 31.3835 66.123 31.1259 66.1227 30.8571V30.4267C66.123 30.158 66.2299 29.9004 66.4199 29.7103C66.6099 29.5203 66.8675 29.4134 67.1363 29.4131H74.3493C74.6181 29.4134 74.8757 29.5203 75.0657 29.7103C75.2557 29.9004 75.3626 30.158 75.3629 30.4267V30.8571C75.3626 31.1259 75.2557 31.3835 75.0657 31.5735C74.8757 31.7636 74.6181 31.8704 74.3493 31.8707Z" fill="white"/>
                            <path d="M74.3493 36.1098H67.1363C66.8675 36.1095 66.6099 36.0026 66.4199 35.8126C66.2299 35.6226 66.123 35.365 66.1227 35.0962V34.6658C66.123 34.3971 66.2299 34.1394 66.4199 33.9494C66.6099 33.7594 66.8675 33.6525 67.1363 33.6522H74.3493C74.6181 33.6525 74.8757 33.7594 75.0657 33.9494C75.2557 34.1394 75.3626 34.3971 75.3629 34.6658V35.0962C75.3626 35.365 75.2557 35.6226 75.0657 35.8126C74.8757 36.0026 74.6181 36.1095 74.3493 36.1098Z" fill="white"/>
                            <path d="M55.8642 51.3538C55.3709 51.1814 54.9193 50.9071 54.539 50.5487C54.1586 50.1902 53.858 49.7557 53.6567 49.2734C53.6102 49.1608 53.5697 49.0459 53.5354 48.9291L35.8579 43.8974C35.0983 43.678 34.4296 43.2196 33.9511 42.5901C33.4727 41.9606 33.21 41.1936 33.202 40.403L33.1588 33.5776L39.6747 33.752L40.0994 37.8517L55.2154 44.6382C55.3577 44.5532 55.5058 44.4782 55.6585 44.4137H55.6586C56.2154 44.1802 56.8207 44.0858 57.4221 44.1386C58.0236 44.1914 58.6032 44.3898 59.1108 44.7166C59.6184 45.0435 60.0389 45.489 60.3358 46.0147C60.6328 46.5404 60.7973 47.1305 60.8151 47.734C60.833 48.3375 60.7037 48.9363 60.4384 49.4787C60.1731 50.021 59.7798 50.4907 59.2924 50.847C58.805 51.2034 58.2382 51.4357 57.641 51.524C57.0437 51.6123 56.4339 51.5539 55.8642 51.3538Z" fill="#A0616A"/>
                            <path d="M33.2601 36.574C33.1274 36.4246 33.0257 36.2505 32.9607 36.0615C32.8958 35.8726 32.8689 35.6727 32.8818 35.4733L33.4001 27.0428C33.4306 26.533 33.5613 26.0341 33.7848 25.5748C34.0083 25.1156 34.3202 24.7048 34.7025 24.3662C35.0849 24.0276 35.5303 23.7677 36.0133 23.6013C36.4962 23.435 37.0072 23.3656 37.517 23.3969C38.0268 23.4283 38.5254 23.5599 38.9843 23.7841C39.4433 24.0084 39.8534 24.321 40.1914 24.7039C40.5294 25.0869 40.7886 25.5327 40.9541 26.0159C41.1196 26.4992 41.1882 27.0103 41.156 27.52L40.6378 35.9505C40.6125 36.3528 40.4287 36.7286 40.1268 36.9956C39.8248 37.2625 39.4293 37.3988 39.027 37.3746L34.3059 37.0844C34.1065 37.0727 33.9114 37.0216 33.7319 36.934C33.5524 36.8463 33.392 36.724 33.2602 36.574H33.2601Z" fill="#CCCCCC"/>
                            <path d="M178.508 119.454H142.907C141.871 119.453 140.878 119.041 140.146 118.309C139.413 117.576 139.001 116.583 139 115.547V76.9074C139.001 75.8714 139.413 74.8783 140.146 74.1458C140.878 73.4132 141.871 73.0012 142.907 73H178.508C179.544 73.0012 180.537 73.4132 181.27 74.1458C182.002 74.8783 182.414 75.8714 182.415 76.9074V115.547C182.414 116.583 182.002 117.576 181.27 118.309C180.537 119.041 179.544 119.453 178.508 119.454ZM142.907 73.8683C142.102 73.8692 141.329 74.1897 140.759 74.7594C140.19 75.3292 139.869 76.1016 139.868 76.9074V115.547C139.869 116.353 140.19 117.125 140.759 117.695C141.329 118.265 142.102 118.585 142.907 118.586H178.508C179.314 118.585 180.086 118.265 180.656 117.695C181.226 117.125 181.546 116.353 181.547 115.547V76.9074C181.546 76.1016 181.226 75.3292 180.656 74.7594C180.086 74.1897 179.314 73.8692 178.508 73.8683H142.907Z" fill="#3F3D56"/>
                            <path d="M150.377 88.3828H146.904C146.386 88.3822 145.889 88.1762 145.523 87.8099C145.157 87.4437 144.951 86.9471 144.95 86.4291V82.9559C144.951 82.4379 145.157 81.9413 145.523 81.5751C145.889 81.2088 146.386 81.0028 146.904 81.0022H150.377C150.895 81.0028 151.391 81.2088 151.758 81.5751C152.124 81.9413 152.33 82.4379 152.331 82.9559V86.4291C152.33 86.9471 152.124 87.4437 151.758 87.8099C151.391 88.1762 150.895 88.3822 150.377 88.3828Z" fill="#A3A3A3"/>
                            <path d="M175.762 86.2715H159.547C159.201 86.2711 158.87 86.1337 158.626 85.8896C158.382 85.6454 158.245 85.3143 158.244 84.969V84.4159C158.245 84.0706 158.382 83.7395 158.626 83.4954C158.87 83.2512 159.201 83.1138 159.547 83.1135H175.762C176.107 83.1138 176.439 83.2512 176.683 83.4954C176.927 83.7395 177.064 84.0706 177.065 84.4159V84.969C177.064 85.3143 176.927 85.6454 176.683 85.8896C176.439 86.1337 176.107 86.2711 175.762 86.2715Z" fill="#A3A3A3"/>
                            <path d="M175.762 96.6911H145.654C145.309 96.6908 144.978 96.5534 144.734 96.3092C144.489 96.0651 144.352 95.734 144.352 95.3887V94.8356C144.352 94.4903 144.489 94.1592 144.734 93.915C144.978 93.6709 145.309 93.5335 145.654 93.5331H175.762C176.108 93.5335 176.439 93.6709 176.683 93.915C176.927 94.1592 177.064 94.4903 177.065 94.8356V95.3887C177.064 95.734 176.927 96.0651 176.683 96.3092C176.439 96.5534 176.108 96.6908 175.762 96.6911Z" fill="#00C881"/>
                            <path d="M175.762 104.072H145.654C145.309 104.071 144.978 103.934 144.734 103.69C144.489 103.446 144.352 103.114 144.352 102.769V102.216C144.352 101.871 144.489 101.54 144.734 101.296C144.978 101.051 145.309 100.914 145.654 100.914H175.762C176.108 100.914 176.439 101.051 176.683 101.296C176.927 101.54 177.064 101.871 177.065 102.216V102.769C177.064 103.114 176.927 103.446 176.683 103.69C176.439 103.934 176.108 104.071 175.762 104.072Z" fill="#A3A3A3"/>
                            <path d="M175.762 111.452H145.654C145.309 111.452 144.978 111.315 144.734 111.07C144.489 110.826 144.352 110.495 144.352 110.15V109.597C144.352 109.251 144.489 108.92 144.734 108.676C144.978 108.432 145.309 108.295 145.654 108.294H175.762C176.108 108.295 176.439 108.432 176.683 108.676C176.927 108.92 177.064 109.251 177.065 109.597V110.15C177.064 110.495 176.927 110.826 176.683 111.07C176.439 111.315 176.108 111.452 175.762 111.452Z" fill="#A3A3A3"/>
                            <path d="M107.606 167.164H79.1259C78.2972 167.163 77.5026 166.833 76.9166 166.247C76.3306 165.661 76.0009 164.866 76 164.038V133.126C76.0009 132.297 76.3306 131.503 76.9166 130.917C77.5026 130.331 78.2972 130.001 79.1259 130H107.606C108.435 130.001 109.23 130.331 109.816 130.917C110.402 131.503 110.731 132.297 110.732 133.126V164.038C110.731 164.866 110.402 165.661 109.816 166.247C109.23 166.833 108.435 167.163 107.606 167.164ZM79.1259 130.695C78.4813 130.695 77.8633 130.952 77.4075 131.408C76.9517 131.863 76.6954 132.481 76.6946 133.126V164.038C76.6954 164.682 76.9518 165.3 77.4075 165.756C77.8633 166.212 78.4813 166.468 79.1259 166.469H107.606C108.251 166.468 108.869 166.212 109.325 165.756C109.781 165.3 110.037 164.682 110.038 164.038V133.126C110.037 132.481 109.781 131.863 109.325 131.408C108.869 130.952 108.251 130.695 107.606 130.695H79.1259Z" fill="#3F3D56"/>
                            <path d="M84.8621 142.851H82.0835C81.6691 142.85 81.2719 142.686 80.9788 142.393C80.6858 142.1 80.521 141.702 80.5206 141.288V138.509C80.521 138.095 80.6858 137.698 80.9788 137.405C81.2719 137.112 81.6691 136.947 82.0835 136.946H84.8621C85.2765 136.947 85.6737 137.112 85.9668 137.405C86.2598 137.698 86.4246 138.095 86.425 138.509V141.288C86.4246 141.702 86.2598 142.1 85.9668 142.393C85.6737 142.686 85.2765 142.85 84.8621 142.851Z" fill="#00C881"/>
                            <path d="M84.8621 151.534H82.0835C81.6691 151.534 81.2719 151.369 80.9788 151.076C80.6858 150.783 80.521 150.385 80.5206 149.971V147.192C80.521 146.778 80.6858 146.381 80.9788 146.088C81.2719 145.795 81.6691 145.63 82.0835 145.63H84.8621C85.2765 145.63 85.6737 145.795 85.9668 146.088C86.2598 146.381 86.4246 146.778 86.425 147.192V149.971C86.4246 150.385 86.2598 150.783 85.9668 151.076C85.6737 151.369 85.2765 151.534 84.8621 151.534Z" fill="#A3A3A3"/>
                            <path d="M105.17 141.162H92.1976C91.9214 141.162 91.6565 141.052 91.4612 140.856C91.2658 140.661 91.1559 140.396 91.1556 140.12V139.677C91.1559 139.401 91.2658 139.136 91.4612 138.941C91.6565 138.746 91.9214 138.636 92.1976 138.635H105.17C105.446 138.636 105.711 138.746 105.906 138.941C106.102 139.136 106.212 139.401 106.212 139.677V140.12C106.212 140.396 106.102 140.661 105.906 140.856C105.711 141.052 105.446 141.162 105.17 141.162Z" fill="#00C881"/>
                            <path d="M105.17 149.845H92.1976C91.9214 149.845 91.6565 149.735 91.4612 149.539C91.2658 149.344 91.1559 149.079 91.1556 148.803V148.361C91.1559 148.084 91.2658 147.819 91.4612 147.624C91.6565 147.429 91.9214 147.319 92.1976 147.319H105.17C105.446 147.319 105.711 147.429 105.906 147.624C106.102 147.819 106.212 148.084 106.212 148.361V148.803C106.212 149.079 106.102 149.344 105.906 149.539C105.711 149.735 105.446 149.845 105.17 149.845Z" fill="#A3A3A3"/>
                            <path d="M84.8621 160.217H82.0835C81.6691 160.217 81.2719 160.052 80.9788 159.759C80.6858 159.466 80.521 159.068 80.5206 158.654V155.876C80.521 155.461 80.6858 155.064 80.9788 154.771C81.2719 154.478 81.6691 154.313 82.0835 154.313H84.8621C85.2765 154.313 85.6737 154.478 85.9668 154.771C86.2598 155.064 86.4246 155.461 86.425 155.876V158.654C86.4246 159.068 86.2598 159.466 85.9668 159.759C85.6737 160.052 85.2765 160.217 84.8621 160.217Z" fill="#A3A3A3"/>
                            <path d="M105.17 158.528H92.1976C91.9214 158.528 91.6565 158.418 91.4612 158.222C91.2658 158.027 91.1559 157.762 91.1556 157.486V157.044C91.1559 156.767 91.2658 156.502 91.4612 156.307C91.6565 156.112 91.9214 156.002 92.1976 156.002H105.17C105.446 156.002 105.711 156.112 105.906 156.307C106.102 156.502 106.212 156.767 106.212 157.044V157.486C106.212 157.762 106.102 158.027 105.906 158.222C105.711 158.418 105.446 158.528 105.17 158.528Z" fill="#A3A3A3"/>
                            <path d="M137.606 44.1635H109.126C108.297 44.1626 107.503 43.833 106.917 43.2469C106.331 42.6609 106.001 41.8664 106 41.0376V10.1259C106.001 9.29715 106.331 8.50262 106.917 7.91661C107.503 7.33059 108.297 7.00095 109.126 7H137.606C138.435 7.00095 139.23 7.33059 139.816 7.91661C140.402 8.50262 140.731 9.29715 140.732 10.1259V41.0376C140.731 41.8664 140.402 42.6609 139.816 43.2469C139.23 43.833 138.435 44.1626 137.606 44.1635ZM109.126 7.69465C108.481 7.69537 107.863 7.95175 107.408 8.40754C106.952 8.86334 106.695 9.48132 106.695 10.1259V41.0376C106.695 41.6822 106.952 42.3002 107.408 42.756C107.863 43.2118 108.481 43.4682 109.126 43.4689H137.606C138.251 43.4682 138.869 43.2118 139.325 42.756C139.781 42.3002 140.037 41.6822 140.038 41.0376V10.1259C140.037 9.48132 139.781 8.86334 139.325 8.40754C138.869 7.95175 138.251 7.69537 137.606 7.69465H109.126Z" fill="#3F3D56"/>
                            <path d="M135.409 17.6171H122.437C122.161 17.6168 121.896 17.5069 121.701 17.3116C121.505 17.1162 121.395 16.8514 121.395 16.5751V16.1327C121.395 15.8564 121.505 15.5916 121.701 15.3962C121.896 15.2009 122.161 15.091 122.437 15.0907H135.409C135.686 15.091 135.951 15.2009 136.146 15.3962C136.341 15.5916 136.451 15.8564 136.451 16.1327V16.5751C136.451 16.8514 136.341 17.1162 136.146 17.3116C135.951 17.5069 135.686 17.6168 135.409 17.6171Z" fill="#A3A3A3"/>
                            <path d="M135.409 25.9528H111.323C111.047 25.9525 110.782 25.8427 110.586 25.6473C110.391 25.452 110.281 25.1871 110.281 24.9109V24.4684C110.281 24.1922 110.391 23.9273 110.586 23.732C110.782 23.5366 111.047 23.4268 111.323 23.4265H135.409C135.686 23.4268 135.951 23.5366 136.146 23.732C136.341 23.9273 136.451 24.1922 136.451 24.4684V24.9109C136.451 25.1871 136.341 25.452 136.146 25.6473C135.951 25.8427 135.686 25.9525 135.409 25.9528Z" fill="#A3A3A3"/>
                            <path d="M135.409 31.8573H111.323C111.047 31.857 110.782 31.7472 110.586 31.5518C110.391 31.3565 110.281 31.0916 110.281 30.8154V30.3729C110.281 30.0966 110.391 29.8318 110.586 29.6365C110.782 29.4411 111.047 29.3312 111.323 29.3309H135.409C135.686 29.3312 135.951 29.4411 136.146 29.6365C136.341 29.8318 136.451 30.0966 136.451 30.3729V30.8154C136.451 31.0916 136.341 31.3565 136.146 31.5518C135.951 31.7472 135.686 31.857 135.409 31.8573Z" fill="#A3A3A3"/>
                            <path d="M135.409 37.7618H111.323C111.047 37.7615 110.782 37.6516 110.586 37.4563C110.391 37.261 110.281 36.9961 110.281 36.7198V36.2774C110.281 36.0011 110.391 35.7363 110.586 35.5409C110.782 35.3456 111.047 35.2357 111.323 35.2354H135.409C135.686 35.2357 135.951 35.3456 136.146 35.5409C136.341 35.7363 136.451 36.0011 136.451 36.2774V36.7198C136.451 36.9961 136.341 37.261 136.146 37.4563C135.951 37.6516 135.686 37.7615 135.409 37.7618Z" fill="#A3A3A3"/>
                            <path d="M116.766 16.0304C116.766 16.0224 116.767 16.0147 116.767 16.0066C116.767 15.5961 116.686 15.1897 116.529 14.8104C116.372 14.4312 116.142 14.0866 115.851 13.7963C115.561 13.506 115.217 13.2758 114.837 13.1187C114.458 12.9616 114.052 12.8807 113.641 12.8807V16.0304H116.766Z" fill="#00C881"/>
                            <path d="M113.293 16.3778V13.2281C112.676 13.2279 112.073 13.4104 111.559 13.7527C111.045 14.095 110.644 14.5817 110.407 15.1515C110.17 15.7213 110.106 16.3486 110.225 16.9544C110.343 17.5602 110.639 18.1173 111.073 18.5555C111.508 18.9937 112.063 19.2934 112.668 19.4168C113.272 19.5402 113.9 19.4818 114.472 19.2488C115.044 19.0159 115.533 18.619 115.88 18.108C116.226 17.5971 116.414 16.995 116.418 16.3778H113.293Z" fill="#A3A3A3"/>
                        </svg>
                    </div>
                    <div class="text-center mt-5 mb-5">
                        <img v-if="!free && logo" class="wl-logo" :src="logo" />
                        <h2 class="vaitel" v-else>Vaitel</h2>
                    </div>
                </div>

                <div v-if="free" class="logo">
                    <img src="/img/logo_bottom_green.svg" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import jQuery from 'jquery'
import config from '@/options/config'
import { SimpleError } from '@/utils/exception'
import { showError } from '@/utils/notifications'
import { RotateSquare2 } from 'vue-loading-spinner'
import { cleanUp } from '@/utils/common'

export default {
  name: 'EndLobby',
  components: {
    RotateSquare2
  },
  data () {
    return {
      meeting_id: null,
      loaded: false,
      need_password: true,
      host: '',
      topic: '',
      logo: null,
      meeting_time: '',
      free: true,
      title: 'Online Meeting Lobby | Vaitel Business Telecommunications',
      permission_status: 'loading'
    }
  },
  mounted () {
    cleanUp()

    if (!this.$route.query.end) {
      this.loaded = true
    }

    if (this.$route.query.end) {
      this.meeting_id = this.$route.query.end
      document.title = this.title

      this.fetchDetails()
        .then(details => {
          if (details.logo) {
            this.logo = details.logo
          }
          this.free = details.free
          if (!details.free) {
            document.title = `Online Meeting Lobby | ${details.customer_name}`
            this.changeFavIcon(details.favico)
          }
          this.loaded = true
        })
        .catch(error => {
          this.loaded = true
          showError(error.message)
        })
    }
  },
  methods: {
    fetchDetails () {
      return new Promise((resolve, reject) => {
        if (!this.meeting_id) {
          resolve({})
          return
        }

        jQuery
          .ajax({
            url: `${config.arena.host}/api/v1/webconf/room/meeting/${this.meeting_id}?ending`,
            method: 'GET',
            headers: {
              'X-YCT-Token': config.arena.key
            },
            contentType: 'application/json',
            dataType: 'json',
            processData: false,
            async: true
          })
          .then(details => {
            if (!details.success) {
              throw new SimpleError(details.message)
            }
            resolve(details.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    changeFavIcon (url) {
      if (url) {
        const favicon = document.querySelectorAll("link[rel~='icon']")
        let i = 0
        for (i = 0; i < favicon.length; i++) {
          favicon[i].href = url
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loadingDiv {
  display: flex;
  padding-top: 10em;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.body {
  background: url('/img/wave_end_page.svg') no-repeat;
  background-color: #00C881;
  background-position-x: -30vh;
  background-position-y: 40vh;
  background-size: cover;
}

@media screen and (min-width: 2000px) {
  .body {
    background-color: #00C881;
    background-position-x: -5vh;
    background: url('/img/wave_end_page.svg') no-repeat;
    background-position-y: 40vh;
    background-size: cover;
  }
}

.end-title {
  font-weight: 500;
  font-size: 35px;
}

.end-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .end {
    width: 400px;
    max-width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(48, 48, 48, 0.6);
    border-radius: 5px;
  }
}

.logo {
  margin-top: 6em;
}

.vaitel {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  color: #00C881;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.info-end {
  line-height: 20px;
  font-weight: 500;
  font-size: 18px;
}
</style>
