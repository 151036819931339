<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <translate>Keyboard Shortcuts</translate>
                    </h5>
                </div>
                <div class="modal-body">
                    <table class="table">
                        <tr>
                            <td><span v-translate>Show or hide video thumbnails</span></td>
                            <td align="right"><span class="badge bg-primary">F</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Mute or unmute your microphone</span></td>
                            <td align="right"><span class="badge bg-primary">M</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Start or stop your camera</span></td>
                            <td align="right"><span class="badge bg-primary">V</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Manage video quality</span></td>
                            <td align="right"><span class="badge bg-primary">A</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Open the chat</span></td>
                            <td align="right"><span class="badge bg-primary">C</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Switch between camera and screen sharing</span></td>
                            <td align="right"><span class="badge bg-primary">D</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Raise or lower your hand</span></td>
                            <td align="right"><span class="badge bg-primary">R</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>View or exit full screen</span></td>
                            <td align="right"><span class="badge bg-primary">S</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Toggle tile view</span></td>
                            <td align="right"><span class="badge bg-primary">W</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Show keyboard shortcuts</span></td>
                            <td align="right"><span class="badge bg-primary">H</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Push to talk</span></td>
                            <td align="right"><span class="badge bg-primary">SPACE</span></td>
                        </tr>
                        <tr>
                            <td><span v-translate>Show speaker stats</span></td>
                            <td align="right"><span class="badge bg-primary">T</span></td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer">
                    <div v-translate @click="close" class="btn btn-light">
                        Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseModal from '@/dialogs/_mixin'
import jQuery from 'jquery'
import {
  KEYBOARD_SHORTCUT_DOWN,
  KEYBOARD_SHORTCUT_UP,
  KEY_SPACE,
  KEY_A,
  KEY_C,
  KEY_D,
  KEY_F,
  KEY_H,
  KEY_M,
  KEY_R,
  KEY_S,
  KEY_T,
  KEY_V,
  KEY_W
} from '@/utils/consts'

export default {
  name: 'KeyboardShortcut',
  mixins: [BaseModal],
  data () {
    return {}
  },
  mounted () {
    this.onGlobalEvent(KEYBOARD_SHORTCUT_DOWN, (key) => {
      this.handleDown(key)
    })

    this.onGlobalEvent(KEYBOARD_SHORTCUT_UP, (key) => {
      this.handleUp(key)
    })
  },
  methods: {
    handleDown (key) {
      if (jQuery('input, textarea').is(':focus')) {
        return
      }

      const pressedKey = key.key.toUpperCase()

      switch (pressedKey) {
      case KEY_F:
        this.globalEventEmit(KEY_F, {})
        break
      case KEY_M: // toggle mic
        this.globalEventEmit(KEY_M, {})
        break
      case KEY_V: // toggle camera
        this.globalEventEmit(KEY_V, {})
        break
      case KEY_A: // open settings
        this.globalEventEmit(KEY_A, {})
        break
      case KEY_C: // toggle chat
        this.globalEventEmit(KEY_C, {})
        break
      case KEY_D: // screen sharing
        this.globalEventEmit(KEY_D, {})
        break
      case KEY_R: // raise hand
        this.globalEventEmit(KEY_R, {})
        break
      case KEY_S: // toggle full screen
        this.globalEventEmit(KEY_S, {})
        break
      case KEY_W: // toggle view
        this.globalEventEmit(KEY_W, {})
        break
      case KEY_H: // show shortcuts
        this.globalEventEmit(KEY_H, {})
        break
      case KEY_SPACE: // mute
        this.globalEventEmit(KEY_SPACE, 'mute')
        break
      case KEY_T: // show speaker stats
        this.globalEventEmit(KEY_T, {})
        break
      }
    },
    handleUp (key) {
      if (jQuery('input, textarea').is(':focus')) {
        return
      }

      const pressedKey = key.key.toUpperCase()

      switch (pressedKey) {
      case KEY_SPACE: // unmute
        this.globalEventEmit(KEY_SPACE, 'unmute')
        break
      }
    },
    close () {
      this.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
