<template>
    <div v-cloak class="body">
        <div v-show="!loaded" class="loadingDiv">
            <p style="font-size: 18px">Loading...</p>
            <br>
            <RotateSquare2 :background="'#FFFFFF'"/>
        </div>
        <transition name="fade">
            <div v-show="loaded" class="login-container">
                <img v-show="!free && logo" :src="logo" class="wl-logo"/>
                <h2 v-show="free" class="vaitel">Vaitel</h2>
                <div class="login">
                    <div class="login-box">
                        <div class="text-center login-title mt-5 mb-5">
                            Let's start your meeting
                        </div>
                        <div class="info-panel">
                            <div v-if="host">
                                <label>Host:</label>
                                <span v-text="host"></span>
                            </div>
                            <div v-if="topic">
                                <label>Topic:</label>
                                <span v-text="topic"></span>
                            </div>
                            <div v-if="meeting_time">
                                <label>Meeting time:</label>
                                <span v-text="meeting_time"></span>
                            </div>
                        </div>

                        <div class="mt-5">
                            <input
                                v-if="!$route.query.id"
                                v-model="form.meeting_id"
                                :class="{inputError: error.meeting_id}"
                                class="form-control form-input"
                                placeholder="Meeting ID"
                                type="text"
                                @keydown.enter="enter()"
                                @paste="onPaste"
                            />
                            <div v-if="error.meeting_id" class="inputErrorMessage" v-html="error.meeting_id"></div>
                        </div>

                        <div class="mt-2">
                            <input
                                v-model="form.name"
                                :class="{inputError: error.name}"
                                class="form-control form-input"
                                placeholder="Your Name"
                                type="text"
                                @keydown.enter="enter()"
                            />
                            <div v-if="error.name" class="inputErrorMessage" v-html="error.name"></div>
                        </div>

                        <div v-if="need_password" class="mt-2">
                            <input
                                v-model="form.password"
                                :class="{inputError: error.password}"
                                class="form-control form-input"
                                placeholder="Meeting Pin"
                                type="text"
                                @keyup="onlyNumber()"
                                @paste="onPaste"
                                @keydown.enter="enter()"
                            >
                            <div v-if="error.password" class="inputErrorMessage" v-html="error.password"></div>
                        </div>
                        <div class="mb-5 mt-4">
                            <button class="d-block btn btn-start border-0" @click="enter()">Join Meeting</button>
                        </div>
                        <div class="text-muted small text-end">{{ region }}</div>
                    </div>
                </div>

                <div v-if="free" class="pt-5">
                    <img src="/img/logo_footer_vaitel.svg"/>
                </div>
            </div>
        </transition>
        <notifications group="lobby" position="top center"/>
    </div>
</template>

<script>
import { SimpleError } from '@/utils/exception'
import { cleanUp, getNearRegion, requestJWT } from '@/utils/common'
import { _ } from '@/lang'
import { checkToken } from '@/utils/jwt'
import jQuery from 'jquery'
import config from '@/options/config'
import { showError, showInfo } from '@/utils/notifications'
import { RotateSquare2 } from 'vue-loading-spinner'
import MobileDetect from 'mobile-detect'
import haversine from 'haversine-distance'

export default {
  name: 'Lobby',
  components: {
    RotateSquare2
  },
  data () {
    return {
      form: {
        meeting_id: '',
        name: '',
        password: ''
      },
      error: {
        meeting_id: null,
        name: null,
        password: null
      },
      loaded: true,
      need_password: true,
      host: '',
      topic: '',
      logo: null,
      meeting_time: '',
      region: 'region_us',
      free: true,
      title: 'Online Meeting Lobby | Vaitel Business Telecommunications'
    }
  },
  mounted () {
    document.title = this.title

    cleanUp()
    this.parseParams()

    window.addEventListener('message', evnt => {
      if (evnt.data === 'APP_UPDATED') {
        showInfo(_('New version has been installed'))
        setTimeout(() => {
          window.location.reload(true)
        }, 3000)
      } else if (evnt.data === 'APP_UPDATING') {
        showInfo(_('Installing a new version'))
      }
    })

    this.form.password = this.$route.query.pin || ''
    this.need_password = config.jwt

    getNearRegion().then(region => {
      this.region = region
    })
  },
  methods: {
    onPaste (evnt) {
      const paste = evnt.clipboardData.getData('Text')
      const lines = paste.split('\n')
      if (lines.length >= 4) {
        let match

        match = lines[1].match(/Meeting ID: ([\w-]+)/)
        if (match) {
          this.form.meeting_id = match[1]
        }

        match = lines[2].match(/PIN: (\d+)/)
        if (match) {
          this.form.password = match[1]
        }

        evnt.preventDefault()
      }
      // You're invited to join a meeting on Vai Vu!
      // Meeting ID: 927ac519-71c8-415e-87bc-7fff6188e822
      // PIN: 630138
      // Join: https://vaivu.hennig.net.br:4443/#/?id=927ac519-71c8-415e-87bc-7fff6188e822
      // console.log(evnt.clipboardData.getData('Text'))
    },
    parseParams () {
      if (this.$route.query.id) {
        this.loaded = false
        this.form.meeting_id = this.$route.query.id
        this
          .fetchDetails()
          .then(details => {
            this.topic = details.meeting_name ? details.meeting_name : 'N/A'
            this.host = details.host
            this.meeting_time = details.details
            if (details.logo) {
              this.logo = details.logo
            }
            this.free = details.free
            if (!details.free) {
              document.title = `Online Meeting Lobby | ${details.customer_name}`
              this.changeFavIcon(details.favico)
            }

            this.loaded = true
          })
          .catch(error => {
            this.loaded = true
            showError('Lobby', error.message, { group: 'lobby' })
          })
      }

      if (config.jwt && this.$route.query.jwt) {
        this.loaded = false
        checkToken(this.$route.query.jwt)
          .then(decoded => {
            this.loaded = true

            this.globalSet('display_name', decoded?.context?.user?.name || 'Vai Vu User')

            this.$nextTick(() => {
              this.$router.replace({
                name: 'Conference',
                params: { room: decoded.meeting_id }
              })
            })
          })
          .catch(() => {
            this.loaded = true
            window.sessionStorage.removeItem('jwt')
          })
      }
    },
    validate () {
      this.cleanErrors()
      let errorFound = false

      if (!this.form.meeting_id) {
        this.error.meeting_id = _('Please fill the Meeting ID field')
        errorFound = true
      }

      if (!this.form.name) {
        this.error.name = _('Please fill the display name')
        errorFound = true
      }

      if (this.need_password && !this.form.password) {
        this.error.password = _('Please fill the password field')
        errorFound = true
      }

      if (this.need_password && !/^\d{6}$/.test(this.form.password)) {
        this.error.password = _('Incorrect Pin. Please try again.')
        errorFound = true
      }

      return errorFound
    },
    cleanErrors () {
      this.error.meeting_id = null
      this.error.name = null
      this.error.password = null
    },
    onlyNumber (evnt) {
      this.$nextTick(() => {
        this.form.password = (this.form.password || '').replace(/\D+/, '')
      })
      return true
    },
    enter () {
      if (this.validate()) {
        return
      }

      this.globalSet('display_name', this.form.name)

      let path = ''
      if (this.checkIfMobile()) {
        path = '/mobile?id=' + this.form.meeting_id
      } else {
        path = '/' + this.form.meeting_id
      }

      // If not JWT passed, request
      if (!this.$route.query.jwt) {
        requestJWT({
          room: this.form.meeting_id,
          name: this.form.name,
          password: this.form.password
        }, decoded => {
          if (this.$route.path !== path) {
            this.$router.replace(path).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err
              }
            })
          }
        }, errors => {
          Object.keys(errors).forEach(key => {
            this.error[key] = errors[key]
          })
        })
      }
    },
    fetchDetails () {
      return new Promise((resolve, reject) => {
        if (!this.form.meeting_id) {
          resolve({})
          return
        }

        jQuery
          .ajax({
            url: `${config.arena.host}/api/v1/webconf/room/meeting/${this.form.meeting_id}`,
            method: 'GET',
            headers: {
              'X-YCT-Token': config.arena.key
            },
            contentType: 'application/json',
            dataType: 'json',
            processData: false,
            async: true
          })
          .then(details => {
            if (!details.success) {
              throw new SimpleError(details.message)
            }
            resolve(details.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    changeFavIcon (url) {
      if (url) {
        const favicon = document.querySelectorAll('link[rel~=\'icon\']')
        let i = 0
        for (i = 0; i < favicon.length; i++) {
          favicon[i].href = url
        }
      }
    },
    checkIfMobile () {
      const mobileDetect = new MobileDetect(window.navigator.userAgent)
      if (mobileDetect.mobile() !== null) {
        // on mobile, open mobile view
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  background: url('/img/wave_bottom.svg') no-repeat;
  background-position-x: -30vh;
  background-position-y: 40vh;
  background-size: cover;
}

@media screen and (min-width: 2000px) {
  .body {
    background-position-x: -5vh;
    background: url('/img/wave_bottom.svg') no-repeat;
    background-position-y: 40vh;
    background-size: cover;
  }
}

.vaitel {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  color: #00C881;
}

.loadingDiv {
  display: flex;
  padding-top: 10em;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.inputErrorMessage {
  color: #F2453D;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 0 0 20px;
}

.inputError {
  border: 2.5px solid #F2453D !important;
}

.info-panel {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;

  span {
    margin-left: 10px;
    font-weight: bold;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .login {
    margin-top: 30px;
    width: 358px;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(48, 48, 48, 0.6);
    background: #ffffff;

    .login-box {
      padding-left: 30px;
      padding-right: 30px;
    }

    .login-title {
      font-size: 26px;
      font-weight: 500;
      text-align: center;
    }
  }
}

.form-input {
  border: 2.5px solid #00c881;
  border-radius: 20px;
  padding-left: 18px !important;
  margin-top: 10px !important;
}

.form-input::placeholder {
  color: #DFE0DF;
  opacity: 1;
}

.btn-start {
  background-color: #00c881;
  color: #ffffff;
  border-radius: 20px;
  padding-left: 5px !important;
  margin-top: 10px !important;;
  font-size: 1.2rem;
  width: 100%;
}

.small {
  font-size: .7rem;
}

</style>
