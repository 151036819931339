<template>
    <ButtonBase
        @click="$emit('click')"
        @mouseover="showHint()"
    >
        <svg
            fill="none"
            height="55"
            viewBox="0 0 56 55"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="28"
                cy="27.5"
                fill="#F8423D"
                rx="28"
                ry="27.5"
            />
            <path
                d="M36.4375 15.1699C40.1289 17.8066 42.5313 22.1191 42.5313 27C42.5313 35.0156 36.0391 41.5137 28.0293 41.5312C20.0313 41.5488 13.4805 35.0273 13.4688 27.0234C13.4629 22.1426 15.8652 17.8184 19.5508 15.1758C20.2363 14.6895 21.1914 14.8945 21.6016 15.627L22.5274 17.2734C22.8731 17.8887 22.709 18.668 22.1406 19.0898C19.709 20.8945 18.1563 23.7539 18.1563 26.9941C18.1504 32.4023 22.5215 36.8438 28 36.8438C33.3672 36.8438 37.8789 32.4961 37.8438 26.9355C37.8262 23.9004 36.3965 20.9707 33.8535 19.084C33.2852 18.6621 33.127 17.8828 33.4727 17.2734L34.3984 15.627C34.8086 14.9004 35.7578 14.6836 36.4375 15.1699ZM30.3438 27.4688V13.4062C30.3438 12.627 29.7168 12 28.9375 12H27.0625C26.2832 12 25.6563 12.627 25.6563 13.4062V27.4688C25.6563 28.248 26.2832 28.875 27.0625 28.875H28.9375C29.7168 28.875 30.3438 28.248 30.3438 27.4688Z"
                fill="white"
            />
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { showHint } from '@/utils/common'
import { _ } from '@/lang'

export default {
  name: 'ButtonDisconnect',
  components: { ButtonBase },
  data () {
    return {}
  },
  methods: {
    showHint () {
      showHint(this.$el, _('Leave'), {
        position: 'bottom',
        style: {
          backgroundColor: '#F8423D'
        }
      })
    }
  }
}
</script>
