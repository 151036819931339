<template>
    <div class="mobile-view">
        <div class="mobile-header d-flex justify-content-center align-items-center flex-row">
            <div class="text-center mt-5 mb-5">
                <img class="mobile-logo" src="/img/logo_bottom_green.svg" />
            </div>
        </div>
        <div class="px-4 py-4 text-center">
            <div class="fw-bold mb-4">
                Make sure you have the Jitsi Meet app to join this meeting:
            </div>
            <a class="btn btn-primary mb-4" :href="download_link">Download the Jitsi app</a>
            <div class="fw-bold mb-4">
                Then click on the button below to join:
            </div>
            <a class="btn btn-primary mb-4" :href="join_link">Join this meeting using the app</a>
            <div v-if="room_number && pin_code">
                <div class="fw-bold mb-4">
                    You can also dial the number below and enter the pin to join the meeting:
                </div>
                <div class="mb-1">
                    Number: {{room_number}}
                </div>
                <div class="mb-1">
                    PIN: {{pin_code}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileDetect from 'mobile-detect'
import config from '@/options/config'

export default {
  data () {
    return {
      mobile_detect: new MobileDetect(window.navigator.userAgent),
      meeting_id: null,
      token: window.sessionStorage.getItem('jwt'),
      join_link: null,
      download_link: null,
      room_number: null,
      pin_code: null
    }
  },
  mounted () {
    // check if meeting id was provided
    this.meeting_id = this.$route.query.id
    if (!this.meeting_id) {
      this.goToLobby()
      return
    }

    // check if token exists
    const decodedToken = window.sessionStorage.getItem('jwt_decoded')
    if (!decodedToken) {
      this.goToLobby()
      return
    }

    // check if it is a mobile device
    const mobileDescription = this.mobile_detect.mobile()
    if (!mobileDescription) {
      // not on mobile
      this.goToLobby()
      return
    }

    // add room number
    this.room_number = decodedToken.roomNumber
    this.pin_code = decodedToken.join_pwd

    // set download link
    if (mobileDescription.toLowerCase().includes('iphone') || mobileDescription.toLowerCase().includes('ipad')) {
      // it's an iPhone / iPad
      this.download_link = 'https://apps.apple.com/br/app/jitsi-meet/id1165103905'
    } else {
      // it's an Android
      this.download_link = 'https://play.google.com/store/apps/details?id=org.jitsi.meet'
    }

    // set join link
    this.join_link = `org.jitsi.meet://${config.hosts.domain}/${this.meeting_id}?jwt=${this.token}`
  },
  methods: {
    goToLobby () {
      if (this.meeting_id) {
        this.$router.replace('/?id=' + this.meeting_id)
      } else {
        this.$router.replace('/')
      }
    }
  }
}
</script>

<style>
.mobile-view {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: white;
}

.mobile-header {
  height: 6rem;
  background-color: rgb(240, 240, 240);
}

.mobile-logo {
  height: 3rem;
}
</style>
