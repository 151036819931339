<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <translate>Statistics</translate>
                    </h5>
                </div>
                <div class="modal-body">
                    <table class="w-100">
                        <tr>
                            <th class="fw-bold" v-translate>Name</th>
                            <th class="fw-bold" v-translate>Time</th>
                        </tr>
                        <tr v-for="(participant, name) in list" :key="name">
                            <td>
                                {{ participant.name }}
                            </td>
                            <td>
                                {{ counterToTime(participant.counter) }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer">
                    <div v-translate @click="close" class="btn btn-light">
                        Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseModal from '@/dialogs/_mixin'

export default {
  name: 'Statistics',
  mixins: [BaseModal],
  data () {
    return {
      list: []
    }
  },
  mounted () {
    window.setInterval(() => {
      this.list = { ...window.g_speak_time }
    }, 1000)
  },
  methods: {
    close () {
      this.hide()
    },
    counterToTime (counter) {
      counter = Number(counter)
      const hours = Math.floor(counter / 3600)
      const minutes = Math.floor(counter % 3600 / 60)
      const seconds = Math.floor(counter % 3600 % 60)

      let formatted = ''
      if (hours) formatted += hours + 'h'
      if (minutes) formatted += minutes + 'm'
      if (seconds) formatted += seconds + 's'
      return formatted
    }
  }
}
</script>
