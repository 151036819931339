import { browser, JitsiMeetJS } from '@/utils/jitsiLib'
import $ from 'jquery'
import options from '../options/config'
import { cleanUp, getNearRegion, globalEventEmit, Log, LogError, stopAudioTracks } from '@/utils/common'
import { ConferenceError, PasswordError } from '@/utils/exception'
import { showError } from '@/utils/notifications'
import { _ } from '@/lang'
import { LOCAL_MUTED, UNAVAILABLE_AUDIO, UNAVAILABLE_VIDEO, UPDATE_BLUR } from '@/utils/consts'
import { collect } from 'collect.js'

window.$ = $

JitsiMeetJS.init()
JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)

// eslint-disable-next-line no-unused-expressions
module?.hot?.dispose((data) => {
  // Clean up before reload
  Log('Hot reload detected, cleaning...')
  cleanUp()
})

export async function askAllPermissions () {
  await enumerateDevice('video')
  await enumerateDevice('audio')
}

export async function enumerateDevice (type) {
  const ids = collect(await navigator.mediaDevices.enumerateDevices())
    .where('kind', `${type}input`)
    .pluck('deviceId')
    .unique()
    .values()
    .toArray()

  const denied = []
  for (const id of ids) {
    if (type === 'audio') {
      stopAudioTracks()
    }

    try {
      const opt = {}
      opt[type] = { deviceId: { exact: id } }
      const track = await navigator.mediaDevices.getUserMedia(opt)
      window.g_jitsi_tracks = window.g_jitsi_tracks || []
      window.g_jitsi_tracks.push(track)
      console.log(`${type} device granted: ${id}`)
    } catch (e) {
      console.warn(`${type} device denied: ${id}`)
      console.error(e)
      denied.push(id)
    }
  }

  const response = {}
  const filtered = collect(await navigator.mediaDevices.enumerateDevices())
    .where('kind', `${type}input`)
    .whereNotIn('deviceId', denied)
    .toArray()

  for (const {
    deviceId,
    label
  } of filtered) {
    if (label) {
      response[deviceId] = label
    }
  }

  return response
}

export async function createAudioTrack () {
  const micDeviceId = window.g_root.global('audioinput', null)

  return await JitsiMeetJS.createLocalTracks({
    devices: ['audio'],
    micDeviceId
  })
}

export async function createVideoTrack () {
  const cameraDeviceId = window.g_root.global('videoinput', null)

  return await JitsiMeetJS.createLocalTracks({
    devices: ['video'],
    cameraDeviceId
  })
}

export function createTracksAndAddToRoom (room, onTrack) {
  const onError = (error, kind) => {
    if (error.name === 'gum.general') {
      showError(_('Permission'), _('Could not access your %{kind} device', { kind }))
      return
    }

    if (error.name === 'gum.permission_denied') {
      showError(_('Permission'), _('User denied permission to %{kind} device', { kind }))
      return
    }

    if (error.name === JitsiMeetJS.errors.track.PERMISSION_DENIED) {
      showError(_('Permission'), _('User denied permission to %{kind} device', { kind }))
      return
    }

    showError(_('Permission'), _('Something went wrong accessing your %{kind} device', { kind }))
    globalEventEmit(kind === 'audio' ? UNAVAILABLE_AUDIO : UNAVAILABLE_VIDEO)
    LogError(error)
  }

  const audio =
    createAudioTrack()
      .then((tracks) => {
        tracks.forEach(track => {
          onTrack(track)
          room.addTrack(track)
        })
      })
      .catch(error => {
        onError(error, 'audio')
      })

  const video = createVideoTrack()
    .then((tracks) => {
      tracks.forEach(track => {
        onTrack(track)
        room.addTrack(track)
      })
    })
    .catch(error => {
      onError(error, 'video')
    })

  return Promise.all([audio, video])
}

export function createAndJoinRoom (connection, roomName) {
  return new Promise((resolve, reject) => {
    let newOptions
    if (browser.isFirefox()) {
      newOptions = { ...options }
    } else {
      const createVADProcessor = window.g_rnnoise.createRnnoiseProcessor
      newOptions = { createVADProcessor, ...options }
    }

    const room = connection.initJitsiConference(roomName, newOptions)
    room.on(JitsiMeetJS.events.conference.CONFERENCE_ERROR, () => {
      // TODO JitsiConferenceErrors.CHAT_ERROR

      reject(new ConferenceError())
    })

    room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, () => {
      reject(new ConferenceError())
    })

    room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
      resolve(room)
    })

    room.join()
  })
}

export function connect () {
  return new Promise((resolve, reject) => {
    getNearRegion().then(r => {
      options.deploymentInfo.userRegion = r
      const connection = new JitsiMeetJS.JitsiConnection(null, window.sessionStorage.jwt, options)

      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, (...args) => {
        reject(new ConferenceError())
      })

      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, () => {
        // Messages coming from prosody
        connection.xmpp.connection.addHandler(function (msg) {
          let text = $(msg).find('>b64-message').text()
          if (!text) return true

          try {
            text = atob(text)
            if (text || text[0] === '{') {
              const o = JSON.parse(text)
              globalEventEmit(o.action, o)
              Log(text)
            }
          } catch (e) {
            LogError('Couldn\'t parse: ' + text)
            return true
          }
          return true// Keep active, returning anything else will stop work after first message
        }, null, 'message', null, null)

        resolve(connection)
      })

      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, (e) => {
        if (e === 'connection.passwordRequired') {
          reject(new PasswordError())
          return
        }

        reject(new ConferenceError())
      })

      connection.connect()
    })
  })
}

export function addOrReplaceAudio (track, state) {
  const oldTrack = window.vueConference.local.audio
  if (!oldTrack || oldTrack.disposed) {
    if (window.g_conference) {
      window.g_conference.addTrack(track).then(() => {
        window.vueConference.onLocalTrack(track)
        track[state]()
        globalEventEmit(LOCAL_MUTED)
      })
    }
    return
  }

  if (window.g_conference) {
    window.g_conference.replaceTrack(oldTrack, track).then(() => {
      window.vueConference.local.audio = track
      track.unmute()
      globalEventEmit(LOCAL_MUTED)

      setTimeout(() => {
        oldTrack.dispose()
        Log('Removing ' + oldTrack.getId())
      }, 100)
    })
  }
}

export function addOrReplaceVideo (track, state) {
  const oldTrack = window.vueConference.local.video
  if (!oldTrack || oldTrack.disposed) {
    if (Object.prototype.hasOwnProperty(window.g_conference, 'addTrack')) {
      window.g_conference.addTrack(track).then(() => {
        window.vueConference.onLocalTrack(track)
        track[state]()
        globalEventEmit(LOCAL_MUTED)
      })
    }
    return
  }

  window.g_conference.replaceTrack(oldTrack, track).then(() => {
    window.vueConference.local.video = track
    track[state]()
    globalEventEmit(LOCAL_MUTED)
    globalEventEmit(UPDATE_BLUR)

    setTimeout(() => {
      oldTrack.dispose()
      Log('Removing ' + oldTrack.getId())
    }, 100)
  })
}
