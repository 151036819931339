<template>
    <ButtonOverBase
        @mouseover="$emit('mouseover', '#B40044', _('Kick participant'))"
        @click="$emit('click')"
    >
        <SvgOverKick/>
    </ButtonOverBase>
</template>

<script>
import ButtonOverBase from '@/components/ButtonOverBase'
import { SvgOverKick } from '@/svgs'

export default {
  name: 'ButtonOverKick',
  components: {
    ButtonOverBase,
    SvgOverKick
  }
}
</script>

<style scoped>

</style>
