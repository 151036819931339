<template>
    <ButtonBase
        :class="{ enabled, disabled: sharing }"
        @click="toggleFullScreen()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="55" viewBox="0 0 56 55" width="56" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="28" cy="27.5" fill="#979797" rx="28" ry="27.5"/>
            <path d="M27.2424 31.9719L21.0357 38.1786L23.2403 40.2564C24.2528 41.2689 23.5358 43 22.1039 43H14.6039C13.7163 43 13 42.2805 13 41.3929V33.8929C13 32.461 14.7279 31.744 15.7403 32.7564L17.8214 34.9643L24.0281 28.7576C24.4465 28.3392 25.1249 28.3392 25.5433 28.7576L27.2424 30.4567C27.6608 30.8751 27.6608 31.5535 27.2424 31.9719ZM28.7576 24.0281L34.9643 17.8214L32.7597 15.7436C31.7472 14.7311 32.4642 13 33.8961 13H41.3961C42.2837 13 43 13.7195 43 14.6071V22.1071C43 23.539 41.2721 24.256 40.2597 23.2436L38.1786 21.0357L31.9719 27.2424C31.5535 27.6608 30.8751 27.6608 30.4567 27.2424L28.7576 25.5433C28.3392 25.1249 28.3392 24.4465 28.7576 24.0281Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { confEventEmit, LogError } from '@/utils/common'
import { DRAWING_STOP, KEY_S, SHARE_STARTED, SHARE_STOPPED } from '@/utils/consts'

export default {
  name: 'ButtonFullScreen',
  components: { ButtonBase },
  props: {
    tool: {}
  },
  data () {
    return {
      enabled: false,
      sharing: false
    }
  },
  mounted () {
    setInterval(() => {
      this.enabled = !!window.document.fullscreenElement
    }, 1000)

    this.onGlobalEvent(SHARE_STARTED, () => {
      this.sharing = true
    })

    this.onGlobalEvent(SHARE_STOPPED, () => {
      this.sharing = false
    })

    this.onGlobalEvent(KEY_S, () => {
      this.toggleFullScreen()
    })
  },
  methods: {
    toggleFullScreen () {
      if (this.sharing) {
        return
      }

      if (this.enabled) {
        document.exitFullscreen()
        return
      }

      this.stopDrawing()

      const elem = document.documentElement

      if (elem.webkitRequestFullScreen) {
        return elem.webkitRequestFullScreen()
      }

      if (elem.requestFullscreen) {
        return elem.requestFullscreen()
      }
    },
    stopDrawing () {
      const me = window.g_conference.myUserId()
      if (this.tool === 'drawing') {
        confEventEmit(DRAWING_STOP)
        this.globalEventEmit(DRAWING_STOP, {}, me)
      }
    }
  }
}
</script>

<style scoped>

</style>
