<template>
    <ButtonBase
        @click="onClick"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg
            fill="none"
            height="55"
            viewBox="0 0 56 55"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="28"
                cy="27.5"
                fill="#979797"
                rx="28"
                ry="27.5"
            />
            <path
                d="M42.3521 30.6048L39.7755 29.1169C40.0356 27.7137 40.0356 26.2742 39.7755 24.871L42.3521 23.3831C42.6484 23.2137 42.7815 22.8629 42.6847 22.5363C42.0134 20.3831 40.8702 18.4355 39.3763 16.8146C39.1464 16.5666 38.7714 16.5061 38.4811 16.6754L35.9045 18.1633C34.8219 17.2319 33.5759 16.5121 32.2271 16.0404V13.0706C32.2271 12.7319 31.9912 12.4355 31.6586 12.363C29.4388 11.867 27.1646 11.8912 25.0538 12.363C24.7211 12.4355 24.4852 12.7319 24.4852 13.0706V16.0464C23.1425 16.5242 21.8965 17.244 20.8078 18.1694L18.2372 16.6815C17.9409 16.5121 17.5719 16.5666 17.3421 16.8206C15.8481 18.4355 14.705 20.3831 14.0336 22.5424C13.9308 22.869 14.0699 23.2198 14.3663 23.3891L16.9429 24.877C16.6828 26.2802 16.6828 27.7198 16.9429 29.123L14.3663 30.6109C14.0699 30.7802 13.9369 31.131 14.0336 31.4576C14.705 33.6109 15.8481 35.5584 17.3421 37.1794C17.5719 37.4274 17.9469 37.4879 18.2372 37.3185L20.8139 35.8306C21.8965 36.7621 23.1425 37.4818 24.4913 37.9536V40.9294C24.4913 41.2681 24.7271 41.5645 25.0598 41.637C27.2796 42.133 29.5537 42.1088 31.6646 41.637C31.9973 41.5645 32.2332 41.2681 32.2332 40.9294V37.9536C33.5759 37.4758 34.8219 36.756 35.9106 35.8306L38.4872 37.3185C38.7835 37.4879 39.1525 37.4334 39.3823 37.1794C40.8763 35.5645 42.0194 33.6169 42.6908 31.4576C42.7815 31.125 42.6484 30.7742 42.3521 30.6048ZM28.3562 31.8326C25.6888 31.8326 23.5175 29.6613 23.5175 26.994C23.5175 24.3266 25.6888 22.1553 28.3562 22.1553C31.0235 22.1553 33.1949 24.3266 33.1949 26.994C33.1949 29.6613 31.0235 31.8326 28.3562 31.8326Z"
                fill="white"
            />
        </svg>

    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'

export default {
  name: 'ButtonSettings',
  components: {
    ButtonBase
  },
  data () {
    return {}
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
