<template>
    <div class="info-top" :class="{ recording, closing }" @click="open">
        <span class="bullet"></span>
        <span class="timer">{{ timer }}</span>
        <span v-if="closing" key="closing" v-translate>Meeting ends in 5 seconds</span>
        <translate v-else-if="count" :translate-n="count" translate-plural="%{count} People" key="count" class="people">%{count} Person</translate>
        <span v-else class="people" key="empty" v-translate>No Participants</span>
    </div>
</template>
<script>
import { KEY_T, RECORDING_CHANGED } from '@/utils/consts'

export default {
  name: 'InfoTop',
  props: {
    timer: {},
    count: {},
    closing: {},
    timeMouseOverlay: null
  },
  data () {
    return {
      recording: false
    }
  },
  mounted () {
    this.onGlobalEvent(RECORDING_CHANGED, (v) => {
      this.recording = v
    })

    this.onGlobalEvent(KEY_T, () => {
      this.open()
    })
  },
  methods: {
    open () {
      this.dialog('Statistics').show()
      document.querySelector('html').style.cursor = 'default'
      clearTimeout(this.timeMouseOverlay)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.info-top {
  margin-left: auto;
  margin-right: calc(50% - 100px);
  cursor: pointer;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #FAFAFA;
  width: 200px;
  height: 32px;
  border-radius: 16px;

  >span {
    white-space: nowrap;
  }

  &.closing {
    width: 240px;
    margin-right: calc(50% - 120px);
  }

  &.recording {
    border: 2px solid #F2453D;
    width: 240px;
    margin-right: calc(50% - 120px);

    .bullet {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #F2453D;
    }
  }

  .bullet {
    display: none;
  }

  .timer {
    color: #00C881;
  }

  .people {
    color: #979797;
  }
}

.blink {
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
