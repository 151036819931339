<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <translate>Settings</translate>
                    </h5>
                </div>
                <div class="modal-body">
                    <nav class="nav border-bottom">
                        <span
                            :class="{active: tab === 'devices'}"
                            class="nav-link"
                            @click="tab = 'devices'"
                        >
                            <translate>Devices</translate>
                        </span>
                        <span
                            :class="{active: tab === 'room'}"
                            class="nav-link"
                            @click="tab = 'room'"
                        >
                            <translate>Room</translate>
                        </span>
                        <span
                            :class="{active: tab === 'privacy'}"
                            class="nav-link"
                            @click="tab = 'privacy'"
                        >
                            <translate>Privacy</translate>
                        </span>
                        <span
                            :class="{active: tab === 'lang'}"
                            class="nav-link"
                            @click="tab = 'lang'"
                        >
                            <translate>Language</translate>
                        </span>
                    </nav>
                    <div class="mt-4">
                        <div
                            v-if="tab === 'devices'"
                            key="devices"
                        >
                            <div class="row">
                                <label
                                    v-translate
                                    class="col-sm-3 col-form-label"
                                >Camera</label>
                                <div class="col-sm-6">
                                    <select
                                        v-model="form.videoinput"
                                        class="form-control form-select"
                                        @change="createVideoTrack()"
                                    >
                                        <option
                                            v-for="(input, index) in videoinputs"
                                            :key="input.deviceId"
                                            :value="input.deviceId"
                                            v-text="input.label || `${_('Camera')} ${index + 1}`"
                                        ></option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <div class="w-100 position-relative">
                                        <video
                                            v-if="previewVideoTrack"
                                            :src-object.prop.camel="previewVideoTrack.stream"
                                            autoplay
                                            class="video-preview"
                                            muted
                                            playsinline
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="offset-sm-3 col-sm-6">
                                    <div class="form-check form-switch">
                                        <input
                                            id="inputBlurMyBackground"
                                            v-model="form.blur"
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                        <label
                                            v-translate
                                            class="form-check-label"
                                            for="inputBlurMyBackground"
                                        >Blur my background</label>
                                    </div>
                                </div>
                            </div>

                            <div v-if="can_select_output" class="row">
                                <label
                                    v-translate
                                    class="col-sm-3 col-form-label"
                                >Speaker</label>
                                <div class="col-sm-6">
                                    <select
                                        v-model="form.audiooutput"
                                        class="form-control form-select"
                                    >
                                        <option
                                            v-for="input in audiooutputs"
                                            :key="input.deviceId"
                                            :value="input.deviceId"
                                            v-text="input.label"
                                        ></option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <div v-translate class="btn btn-light w-100" @click="testAudio()">Test</div>
                                </div>
                            </div>

                            <div class="row">
                                <label v-translate class="col-sm-3 col-form-label">Microphone</label>
                                <div class="col-sm-6">
                                    <select
                                        v-model="form.audioinput"
                                        class="form-control form-select"
                                        @change="createAudioTrack()"
                                    >
                                        <option
                                            v-for="(input, index) in audioinputs"
                                            :key="input.deviceId"
                                            :value="input.deviceId"
                                            v-text="input.label || `${_('Microphone')} ${index + 1}`"
                                        ></option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <div class="progress">
                                        <div
                                            :style="{width: audioLevel}"
                                            class="progress-bar"
                                            role="progressbar"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="tab === 'room'"
                            key="room"
                        >
                            <div class="row">
                                <label
                                    v-translate
                                    class="col-sm-3 col-form-label"
                                >Nickname</label>
                                <div class="col-sm-6">
                                    <input
                                        v-model="form.display_name"
                                        class="form-control"
                                    />
                                </div>
                                <div class="col-sm-3">

                                </div>
                            </div>
                            <div class="row">
                                <label
                                    v-translate
                                    class="col-sm-3 col-form-label"
                                >Participant</label>
                                <div class="col-sm-6">
                                    <div class="form-check form-switch">
                                        <input
                                            id="inputStartAudioMuted"
                                            v-model="form.start_audio_muted"
                                            :disabled="!moderator"
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                        <label
                                            v-translate
                                            class="form-check-label"
                                            for="inputStartAudioMuted"
                                        >Everyone starts audio muted</label>
                                    </div>

                                    <div class="form-check form-switch">
                                        <input
                                            id="inputStartVideoMuted"
                                            v-model="form.start_video_muted"
                                            :disabled="!moderator"
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                        <label
                                            v-translate
                                            class="form-check-label"
                                            for="inputStartVideoMuted"
                                        >Everyone starts video muted</label>
                                    </div>

                                    <div class="form-check form-switch">
                                        <input
                                            id="inputFollowsMe"
                                            v-model.lazy="form.follows_me"
                                            :disabled="!moderator"
                                            class="form-check-input"
                                            type="checkbox"
                                            @change="checkFollowMe()"
                                        />
                                        <label
                                            v-translate
                                            class="form-check-label"
                                            for="inputFollowsMe"
                                        >Everyone follows me</label>
                                    </div>
                                </div>
                                <div class="col-sm-3">

                                </div>
                            </div>
                        </div>
                        <div
                            v-if="tab === 'privacy'"
                            key="privacy"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-control">
                                        <table class="table table-bordered table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td>{{ _('Bandwidth') }}</td>
                                                    <td>{{ _('Download') }} {{ bandwidth_download }} - {{ _('Upload') }} {{ bandwidth_upload }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ _('Bitrate') }}</td>
                                                    <td>{{ _('Download') }} {{ bitrate_download }} - {{ _('Upload') }} {{ bitrate_upload }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ _('Codecs') }}</td>
                                                    <td>{{ codecs }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ _('Connection Quality') }}</td>
                                                    <td>{{ connection_quality }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ _('Packet Loss') }}</td>
                                                    <td>{{ packet_loss }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ _('Connection') }}</td>
                                                    <td>{{ transport_ip }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="tab === 'lang'"
                            key="lang"
                        >
                            <div class="row">
                                <label
                                    v-translate
                                    class="col-sm-3 col-form-label"
                                >Language</label>
                                <div class="col-sm-6">
                                    <select v-model="form.language" class="form-control form-select">
                                        <option
                                            v-for="(language, key) in $language.available"
                                            :key="key"
                                            :value="key"
                                        >{{ language }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div
                        v-translate
                        class="btn btn-light"
                        :class="{ busy }"
                        @click="cancelClick()"
                    >
                        Cancel
                    </div>
                    <div
                        v-translate
                        class="btn btn-primary"
                        :class="{ busy }"
                        @click="primaryClick()"
                    >
                        Save
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from './_mixin'
import { JitsiMeetJS } from '@/utils/jitsiLib'
import {
  FOLLOW_ME_COMMAND,
  UPDATE_BLUR
} from '@/utils/consts'
import { confEventEmit, globalEventEmit, isModerator, LogError, stopAudioTracks, stopVideoTracks } from '@/utils/common'
import { addOrReplaceAudio, addOrReplaceVideo, askAllPermissions } from '@/utils/jitsiUtils'
import { showWarn } from '@/utils/notifications'
import { _ } from '@/lang'

export default {
  name: 'Settings',
  mixins: [BaseModal],
  data () {
    return {
      busy: false,
      tracks: [],
      tab: 'devices',
      videoinputs: [],
      audioinputs: [],
      audiooutputs: [],
      audioLevel: '0',

      bandwidth_download: 0,
      bandwidth_upload: 0,
      bitrate_download: 0,
      bitrate_upload: 0,
      codecs: '',
      connection_quality: 0,
      packet_loss: 0,
      transport_ip: '',

      can_select_output: false,
      moderator: false,

      local_video_muted: false,
      local_audio_muted: false,

      audio_changed: false,
      video_changed: false,
      watches: [],
      previewVideoTrack: null,
      previewAudioTrack: null,
      initialVideo: null,
      initialAudio: null,

      form: {
        blur: false,
        start_audio_muted: false,
        start_video_muted: false,
        follows_me: false,
        display_name: '',
        videoinput: '',
        audiooutput: '',
        language: '',
        audioinput: ''
      }
    }
  },
  mounted () {
    this.can_select_output = !!Audio.prototype.setSinkId
    this.$on('show.bs.modal', () => {
      window.vueSettings = this
      this.moderator = isModerator()

      this.local_video_muted = window.vueConference.local_video_muted
      this.local_audio_muted = window.vueConference.local_audio_muted
      this.previewVideoTrack = null
      this.previewAudioTrack = null
      this.initialAudio = this.global('audioinput', '')
      this.initialVideo = this.global('videoinput', '')

      this.loadDevices()
      this.loadGlobals()
      this.loadWatch()

      this.timerStats = setInterval(this.loadStats, 2000)
    })

    this.$on('hidden.bs.modal', () => {
      window.vueSettings = null
      this.previewVideoTrack = null
      this.previewAudioTrack = null
      this.unloadWatch()

      clearInterval(this.timerStats)
    })
  },
  methods: {
    checkFollowMe () {
      if (this.form.follows_me && window.vueConference && window.vueConference.isFollowing()) {
        this.$nextTick(() => {
          this.form.follows_me = false
        })
        showWarn(_('You\'re already following another participant'))
      }
    },
    loadStats () {
      if (!window.g_conference) {
        return
      }

      const stats = window.g_conference.connectionQuality.getStats()
      this.bandwidth_download = (stats?.bandwidth?.download ?? '0') + ' Kbps'
      this.bandwidth_upload = (stats?.bandwidth?.upload ?? '0') + ' Kbps'
      this.bitrate_download = (stats?.bitrate?.download ?? '0') + ' Kbps'
      this.bitrate_upload = (stats?.bitrate?.upload ?? '0') + ' Kbps'
      this.connection_quality = (stats?.connectionQuality ?? '0') + ' %'
      this.packet_loss = stats?.packetLoss?.total
      this.transport_ip = stats?.transport?.ip

      const codecs = {}
      for (const i in stats.codec) {
        for (const ii in stats.codec[i]) {
          codecs[stats.codec[i][ii].audio + '/' + stats.codec[i][ii].video] = 1
        }
      }
      this.codecs = Object.keys(codecs).join(', ')
    },
    unloadWatch () {
      for (const unwatch of this.watches) {
        unwatch()
      }

      this.watches = []
    },
    loadWatch () {
      this.watches.push(this.$watch('form.videoinput', function (v) {
        this.video_changed = true
      }))

      this.watches.push(this.$watch('form.audioinput', function (v) {
        this.audio_changed = true
      }))
    },
    loadGlobals () {
      this.form.display_name = this.global('display_name', 'Vai Vu User')
      this.form.blur = this.global('blur', false)
      this.form.language = this.$language.current
    },
    setAudioLevel (v) {
      clearTimeout(this.audioLevelTimer)
      this.audioLevelTimer = setTimeout(() => {
        this.audioLevel = Math.floor(v * 100) + '%'
      }, 250)
    },
    testAudio () {
      if (!Audio.prototype.setSinkId) {
        return
      }

      const audio = new Audio(require('@/assets/ring.mp3'))
      audio.setSinkId('')
      if (this.form.audiooutput) audio.setSinkId(this.form.audiooutput)
      audio.play()
    },
    async loadDevices () {
      this.busy = true
      await askAllPermissions()
      this.form.audioinput = this.global('audioinput', '')
      this.form.videoinput = this.global('videoinput', '')
      this.form.audiooutput = this.global('audiooutput', '')

      const videoinput = {}
      const audiooutput = {}
      const audioinput = {}

      const videoinputs = []
      const audioinputs = []
      const audiooutputs = []

      JitsiMeetJS
        .mediaDevices
        .enumerateDevices(devices => {
          for (const device of devices) {
            if (device.kind === 'videoinput') {
              videoinput[device.deviceId] = device.label
            } else if (device.kind === 'audiooutput') {
              audiooutput[device.deviceId] = device.label
            } else if (device.kind === 'audioinput') {
              audioinput[device.deviceId] = device.label
            }
          }
          for (const i in videoinput) {
            videoinputs.push({
              deviceId: i,
              label: videoinput[i]
            })
            if (!this.form.videoinput || !videoinput[this.form.videoinput]) {
              this.form.videoinput = i
            }
          }
          for (const i in audioinput) {
            audioinputs.push({
              deviceId: i,
              label: audioinput[i]
            })
            if (!this.form.audioinput || !audioinput[this.form.audioinput]) {
              this.form.audioinput = i
            }
          }
          for (const i in audiooutput) {
            audiooutputs.push({
              deviceId: i,
              label: audiooutput[i]
            })
            if (!this.form.audiooutput || !audiooutput[this.form.audiooutput]) {
              this.form.audiooutput = i
            }
          }

          this.videoinputs = [...videoinputs]
          this.audioinputs = [...audioinputs]
          this.audiooutputs = [...audiooutputs]
        })

      this.createVideoTrack(null, () => {
        this.createAudioTrack(null, () => {
          this.busy = false
        })
      })
    },
    async createVideoTrack (cameraDeviceId = null, cb = null) {
      cameraDeviceId = cameraDeviceId ?? (this.form.videoinput === 'Default' ? undefined : this.form.videoinput)

      this.previewVideoTrack && this.previewVideoTrack.dispose()
      stopVideoTracks()

      JitsiMeetJS
        .createLocalTracks({
          devices: ['video'],
          cameraDeviceId
        })
        .then((tracks) => {
          this.previewVideoTrack = tracks[0]
          if (cb) {
            cb(tracks[0])
          }
        })
        .catch(error => {
          LogError(error)
        })
    },
    async createAudioTrack (micDeviceId = null, cb = null) {
      micDeviceId = micDeviceId ?? (this.form.audioinput === 'Default' ? undefined : this.form.audioinput)

      this.previewAudioTrack && this.previewAudioTrack.dispose()
      stopAudioTracks()

      JitsiMeetJS
        .createLocalTracks({
          devices: ['audio'],
          micDeviceId
        })
        .then((tracks) => {
          this.previewAudioTrack = tracks[0]
          if (cb) {
            cb(tracks[0])
          }
          tracks[0].addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
            audioLevel => {
              this.setAudioLevel(audioLevel)
            }
          )
        })
        .catch(error => {
          console.error('There was an error creating the local tracks:', error)
        })
    },
    cancelClick () {
      if (this.busy) return
      this.restoreInitialTracks()
      this.callback(false)
      this.hide()
    },
    primaryClick () {
      if (this.busy) return
      this.globalSet('display_name', this.form.display_name)
      this.globalSet('start_audio_muted', this.form.start_audio_muted)
      this.globalSet('start_video_muted', this.form.start_video_muted)
      this.globalSet('language', this.form.language)
      this.globalSet('follows_me', this.form.follows_me)
      this.globalSet('blur', this.form.blur)

      if (this.form.audioinput !== 'Default') this.globalSet('audioinput', this.form.audioinput)
      if (this.form.videoinput !== 'Default') this.globalSet('videoinput', this.form.videoinput)
      if (this.form.audiooutput !== 'Default') this.globalSet('audiooutput', this.form.audiooutput)

      this.$language.current = this.form.language
      window.vueConference.setDisplayName()

      if (window.g_conference) {
        if (this.form.follows_me) {
          confEventEmit(FOLLOW_ME_COMMAND, { attributes: {} })
        } else {
          confEventEmit(FOLLOW_ME_COMMAND, { attributes: { off: true } })
        }

        if (this.form.audiooutput !== 'Default') {
          if (this.can_select_output) {
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(this.form.audiooutput)
          }
        }

        window.g_conference.setStartMutedPolicy({
          audio: this.form.start_audio_muted,
          video: this.form.start_video_muted
        })
      }

      this.updateTracks()
      this.callback(true)
      this.hide()
    },
    restoreInitialTracks () {
      if (this.previewVideoTrack) {
        this.previewVideoTrack.dispose()
        this.previewVideoTrack = null
      }

      if (this.previewAudioTrack) {
        this.previewAudioTrack.dispose()
        this.previewAudioTrack = null
      }

      this.createVideoTrack(this.initialVideo, newTrack => {
        addOrReplaceVideo(newTrack, this.local_video_muted ? 'mute' : 'unmute')
        globalEventEmit(UPDATE_BLUR)
      })

      this.createAudioTrack(this.initialAudio, newTrack => {
        addOrReplaceAudio(newTrack, this.local_audio_muted ? 'mute' : 'unmute')
      })
    },
    /**
     * Use previewing tracks as the main
     */
    updateTracks () {
      if (this.previewVideoTrack) {
        addOrReplaceVideo(this.previewVideoTrack, this.local_video_muted ? 'mute' : 'unmute')
      }

      if (this.previewAudioTrack) {
        addOrReplaceAudio(this.previewAudioTrack, this.local_audio_muted ? 'mute' : 'unmute')
      }

      globalEventEmit(UPDATE_BLUR)
    }
  }
}
</script>

<style scoped>
.video-preview {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.nav-link {
    cursor: pointer;
}

.row {
    margin-bottom: 1rem;
}

.busy {
    filter: grayscale(1);
    opacity: .5;
}

</style>
