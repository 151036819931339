import Vue from 'vue'
import router from './router'
import App from '@/App'
import Notifications from 'vue-notification'
import { showError } from '@/utils/notifications'
import './lang/init'
import './registerServiceWorker'
import { _ } from '@/lang'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Vue.use(Notifications)
Vue.config.productionTip = false

if (process.env.VUE_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true
    },
    onError: async event => {
      if (event.errors[0].stacktrace[0].file) {
        const urlSplit = event.errors[0].stacktrace[0].file.split('/')
        let check = false
        urlSplit.forEach(value => {
          if (value === 'libs' || value === 'node_modules' || value === 'JitsiMeetJS') {
            check = true
          }
        })
        if (check) {
          return false
        }
      }
      if (window.g_conference) {
        event.addMetadata('config', window.g_conference.options.config)
        event.addMetadata('p2p', { p2p: window.g_conference.p2p })
      }
    }
  })
}

Vue.mixin({
  errorCaptured (err, vm, info) {
    if (err.name === 'SimpleError') {
      showError(err.message)
      return false
    }

    if (err.name === 'ValidationError') {
      showError(_('Validation'), err.message)
      return false
    }
  },
  methods: {
    /**
     * Gettext shortcut
     *
     * @param args
     * @return {string}
     * @private
     */
    _ (...args) {
      return this.$gettext.apply(this, args)
    },
    global (k, def = '') {
      return this.$root.globals[k] || def
    },
    globalSet (k, v) {
      this.$set(this.$root.globals, k, v)
    },
    globalEventEmit (...args) {
      this.$root.$emit.apply(this.$root, args)
    },
    onGlobalEvent (eventName, callback) {
      this.$root.$on(eventName, callback)
    },
    dialogHideAll () {
      this.$root.$refs.app.$refs.ConfirmKick.hide()
      this.$root.$refs.app.$refs.Settings.hide()
      this.$root.$refs.app.$refs.Login.hide()
      this.$root.$refs.app.$refs.ConfirmLeave.hide()
      this.$root.$refs.app.$refs.Chat.hide()
      this.$root.$refs.app.$refs.Statistics.hide()
      this.$root.$refs.app.$refs.KeyboardShortcut.hide()
    },
    dialog (name) {
      return this.$root.$refs.app.$refs[name]
    }
  }
})

window.g_root = new Vue({
  router,
  data: {
    globals: {}
  },
  watch: {
    globals: {
      handler (v) {
        window.localStorage.setItem('globals', JSON.stringify(v))
      },
      deep: true
    }
  },
  created () {
    const globals = JSON.parse(window.localStorage.getItem('globals'))
    if (globals) {
      for (const k in globals) {
        this.$set(this.globals, k, globals[k])
      }
    }

    this.$language.current = this.global('language', 'en_GB')
  },
  render (h) {
    return h(App, { ref: 'app' })
  }
})
window.g_root.$mount('#app')
