<template>
    <ButtonBase
        @click="onClick()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="70" viewBox="0 0 70 70" width="70" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" fill="#00C881" r="35"/>
            <path d="M41.9643 48.6818V51.6647C41.9643 52.6532 41.1848 53.4545 40.2232 53.4545H20.4911C19.5295 53.4545 18.75 52.6532 18.75 51.6647V24.2216C18.75 23.2331 19.5295 22.4318 20.4911 22.4318H25.7143V44.5057C25.7143 46.8084 27.5367 48.6818 29.7768 48.6818H41.9643ZM41.9643 23.0284V15.2727H29.7768C28.8152 15.2727 28.0357 16.074 28.0357 17.0625V44.5057C28.0357 45.4941 28.8152 46.2954 29.7768 46.2954H49.5089C50.4705 46.2954 51.25 45.4941 51.25 44.5057V24.8182H43.7054C42.7478 24.8182 41.9643 24.0128 41.9643 23.0284ZM50.7401 20.7144L45.9563 15.7969C45.6298 15.4613 45.187 15.2727 44.7253 15.2727H44.2857V22.4318H51.25V21.98C51.25 21.5053 51.0666 21.0501 50.7401 20.7144Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { copyTextToClipboard } from '@/utils/common'
import { showInfo } from '@/utils/notifications'
import { _ } from '@/lang'
import { checkToken } from '@/utils/jwt'

export default {
  name: 'ButtonCopy',
  components: { ButtonBase },
  data () {
    return {}
  },
  methods: {
    onClick () {
      checkToken().then(decoded => {
        let body = `${_('You\'re invited to join a meeting on Vai Vu!')}\n`
        if (decoded.meeting_id) body += `${_('Meeting ID')}: ${decoded.meeting_id}\n`
        if (decoded.join_pwd) body += `${_('PIN')}: ${decoded.join_pwd}\n`
        body += `${_('Join')}: ${(decoded.join_link || location.href)}\n`
        if (!decoded.is_free && decoded.room_number) body += `${_('Phone')}: ${decoded.room_number}\n`

        copyTextToClipboard(body)

        showInfo(_('Copied into clipboard'))
      })

      this.$emit('click')
    }
  }
}
</script>
