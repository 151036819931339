<template>
    <ButtonBase
        @click="onClick()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="70" viewBox="0 0 70 70" width="70" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" fill="#00C881" r="35"/>
            <path d="M50.7325 15.9874L16.6679 35.6337C15.3377 36.3978 15.5067 38.2493 16.8296 38.8076L24.6421 42.0845L45.7569 23.4815C46.1612 23.1215 46.7344 23.6725 46.389 24.0913L28.6842 45.6552V51.5696C28.6842 53.3036 30.7788 53.9869 31.8077 52.7305L36.4746 47.0511L45.632 50.8864C46.6756 51.3272 47.8662 50.6733 48.0573 49.5492L53.3489 17.8095C53.5988 16.3253 52.004 15.2527 50.7325 15.9874Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { _ } from '@/lang'
import { checkToken } from '@/utils/jwt'
import { mailTo } from '@/utils/common'

export default {
  name: 'ButtonSend',
  components: { ButtonBase },
  data () {
    return {}
  },
  methods: {
    onClick () {
      checkToken().then(decoded => {
        let body = _('You\'re invited to join a meeting on Vai Vu!') + '\n'
        if (decoded.meeting_id) body += _('Meeting ID') + ': ' + decoded.meeting_id + '\n'
        if (decoded.join_pwd) body += _('PIN') + ': ' + decoded.join_pwd + '\n'
        body += _('Join') + ': ' + (decoded.join_link || location.href) + '\n'

        mailTo({
          subject: _('Vai Vu Invitation'),
          body
        })
      })

      this.$emit('click')
    }
  }
}
</script>
