<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <translate>Login Title</translate>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="mb-2">
                        <input
                            v-model="form.name"
                            :placeholder="_('Participant name')"
                            class="form-control"
                            type="text"
                        />
                    </div>
                    <div class="mb-2">
                        <input
                            v-model="form.password"
                            :placeholder="_('Password')"
                            class="form-control"
                            type="password"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-light" data-bs-dismiss="modal">Cancel</div>
                    <div class="btn btn-primary" @click="primaryClick()">Join</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from '@/dialogs/_mixin'
import { requestJWT } from '@/utils/common'

export default {
  name: 'Login',
  mixins: [BaseModal],
  data () {
    return {
      form: {
        username: '',
        password: '',
        name: ''
      }
    }
  },
  methods: {
    primaryClick () {
      requestJWT({
        room: this.$route.params.room,
        name: this.form.name,
        password: this.form.password
      }, decoded => {
        this.callback(true)
        this.hide()
      })
    }
  }
}
</script>

<style scoped>

</style>
