<template>
    <ButtonBase
        v-if="visible"
        :class="{enabled: !muted && !locked, disabled: locked}"
        @click="toggleMute()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg v-if="locked" fill="none" height="55" viewBox="0 0 56 55" width="56" xmlns="http://www.w3.org/2000/svg">
            <ellipse
                cx="28"
                cy="27.5"
                fill="#979797"
                rx="28"
                ry="27.5"
            />
            <path
                d="M42.7103 36.4734L35.3134 30.7566C35.9035 29.6311 36.25 28.3598 36.25 27V24.75C36.25 24.3356 35.9143 24 35.5 24H34.75C34.3356 24 34 24.3356 34 24.75V27C34 27.84 33.8143 28.6312 33.4975 29.3531L32.2529 28.3912C32.3982 27.9506 32.5004 27.4903 32.5004 27.0005V19.5C32.5004 17.0147 30.4857 15 28.0004 15C25.5151 15 23.5004 17.0147 23.5004 19.5V21.6262L15.1314 15.158C14.8042 14.9039 14.3326 14.9625 14.0786 15.2897L13.1579 16.4742C12.9039 16.8009 12.9625 17.272 13.2896 17.5266L40.869 38.842C41.1962 39.0966 41.6673 39.0375 41.9218 38.7103L42.8424 37.5258C43.096 37.1991 43.0374 36.728 42.7103 36.4734ZM31.75 36.75H29.125V35.167C29.6715 35.092 30.196 34.9542 30.7032 34.7775L28.3543 32.962C28.0398 32.9808 27.7257 33.0028 27.4004 32.9714C24.7825 32.7159 22.772 30.6919 22.1889 28.1972L19.75 26.3114V26.6325C19.75 30.8344 22.7485 34.5802 26.875 35.1492V36.75H24.25C23.8356 36.75 23.5 37.0856 23.5 37.5V38.25C23.5 38.6644 23.8356 39 24.25 39H31.75C32.1643 39 32.5 38.6644 32.5 38.25V37.5C32.5 37.0856 32.1643 36.75 31.75 36.75Z"
                fill="white"
            />
        </svg>

        <svg
            v-else
            fill="none"
            height="55"
            viewBox="0 0 55 55"
            width="55"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="27.5"
                cy="27.5"
                fill="#979797"
                r="27.5"
            />
            <path
                d="M27.3125 32.625C30.4191 32.625 32.9375 30.1066 32.9375 27V17.625C32.9375 14.5184 30.4191 12 27.3125 12C24.2059 12 21.6875 14.5184 21.6875 17.625V27C21.6875 30.1066 24.2059 32.625 27.3125 32.625ZM36.6875 23.25H35.75C35.232 23.25 34.8125 23.6695 34.8125 24.1875V27C34.8125 31.3828 31.0338 34.8996 26.5631 34.4637C22.6666 34.0834 19.8125 30.5807 19.8125 26.666V24.1875C19.8125 23.6695 19.393 23.25 18.875 23.25H17.9375C17.4195 23.25 17 23.6695 17 24.1875V26.5406C17 31.793 20.7482 36.4752 25.9062 37.1865V39.1875H22.625C22.107 39.1875 21.6875 39.607 21.6875 40.125V41.0625C21.6875 41.5805 22.107 42 22.625 42H32C32.518 42 32.9375 41.5805 32.9375 41.0625V40.125C32.9375 39.607 32.518 39.1875 32 39.1875H28.7188V37.2088C33.7408 36.5197 37.625 32.209 37.625 27V24.1875C37.625 23.6695 37.2055 23.25 36.6875 23.25Z"
                fill="white"
            />
        </svg>

    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { KEY_M, KEY_SPACE, LOCAL_MUTED, MEETING_JOINED } from '@/utils/consts'

export default {
  name: 'ButtonMic',
  components: {
    ButtonBase
  },
  props: ['locked'],
  data () {
    return {
      visible: false,
      muted: false
    }
  },
  mounted () {
    this.onGlobalEvent(LOCAL_MUTED, (v) => {
      this.muted = window.vueConference.local.audio.isMuted()
    })

    this.onGlobalEvent(KEY_M, (v) => {
      this.toggleMute()
    })

    this.onGlobalEvent(KEY_SPACE, (action) => {
      if (action === 'mute') {
        this.unmute()
        return
      }

      this.mute()
    })

    // To only show after connected
    this.onGlobalEvent(MEETING_JOINED, () => {
      this.visible = true
    })
  },
  methods: {
    async toggleMute () {
      if (this.locked) {
        return
      }

      if (!window?.vueConference.local?.audio) {
        return
      }

      if (window.vueConference.local.audio.isMuted()) {
        await this.unmute()
      } else {
        await this.mute()
      }
    },
    async mute () {
      if (!window?.vueConference.local?.audio) {
        return
      }

      await window.vueConference.local.audio.mute()
      this.muted = window.vueConference.local.audio.isMuted()
    },
    async unmute () {
      if (!window?.vueConference.local?.audio) {
        return
      }

      await window.vueConference.local.audio.unmute()
      this.muted = window.vueConference.local.audio.isMuted()
    }
  }
}
</script>
