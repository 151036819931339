<template>
    <ButtonBase
        :class="{ recording }"
        @click="toggleRecording()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" fill="#979797" r="28"/>
            <path d="M28 13C19.7291 13 13 19.7291 13 28C13 36.2709 19.7291 43 28 43C36.2709 43 43 36.2709 43 28C43 19.7291 36.2708 13 28 13ZM28 39.25C21.7964 39.25 16.75 34.2036 16.75 28C16.75 21.7964 21.7964 16.75 28 16.75C34.2036 16.75 39.25 21.7963 39.25 27.9999C39.25 34.2035 34.2036 39.25 28 39.25Z" fill="white"/>
            <path d="M28 20.5C23.8646 20.5 20.5 23.8637 20.5 28C20.5 32.1363 23.8646 35.5 28 35.5C32.1354 35.5 35.5 32.1363 35.5 28C35.5 23.8637 32.1354 20.5 28 20.5Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { SimpleError } from '@/utils/exception'
import { Log, LogError, onGlobalEvent } from '@/utils/common'
import { RECORDING_CHANGED, STOP_RECORDING, TRACKS_UPDATED } from '@/utils/consts'

const RecordRTC = require('recordrtc')

// 'confirm_action_stop_current_recording' => 'This will stop the current recording. Are you sure?',

let recorder

export default {
  name: 'ButtonRecord',
  components: { ButtonBase },
  data () {
    return {
      recording: false
    }
  },
  watch: {
    recording (v) {
      this.globalEventEmit(RECORDING_CHANGED, v)
    }
  },
  mounted () {
    this.onGlobalEvent(TRACKS_UPDATED, this.reset)
    this.onGlobalEvent(STOP_RECORDING, this.stop)
  },
  methods: {
    toggleRecording () {
      // askStopRecording
      if (this.recording) {
        this.stop()
        return
      }

      this.start()
    },
    stop () {
      if (!recorder) return
      this.recording = false
      recorder.stopRecording(function () {
        recorder.save('Recording.webm')
        recorder = null
      })
    },
    start () {
      const streams = this.getTracks()
      if (!streams.length) {
        throw new SimpleError('There is no streams to record')
      }

      recorder = RecordRTC(streams, {
        type: 'video',
        bitsPerSecond: 256 * 8 * 1024,
        mimeType: 'video/webm;codecs=vp8,opus'
      })

      recorder.startRecording()
      this.recording = true
    },
    reset () {
      const streams = this.getTracks()
      if (recorder) {
        recorder.getInternalRecorder().resetVideoStreams(streams)
      }
    },
    getTracks () {
      const streams = []
      Log('Recording tracks updated')

      const tracks = [window.vueConference.local, ...Object.values(window.vueConference.remote)]
      for (const i in tracks) {
        const { audio, video } = tracks[i]

        if (video && !video.isMuted()) {
          streams.push(video.getOriginalStream())
        }

        if (audio && !audio.isMuted()) {
          streams.push(audio.getOriginalStream())
        }
      }

      return streams
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.recording {
  svg {
    circle {
      fill: $red;
    }
  }
}
</style>
