import JWT from 'jsonwebtoken'
import { SimpleError } from '@/utils/exception'
import config from '@/options/config'
import { LogError } from '@/utils/common'

/**
 * Store the JWT and check its validity
 *
 * @param {string} jwt Base64 representation
 * @return {Promise<unknown>}
 */
export function checkToken (jwt = null) {
  return new Promise((resolve) => {
    if (!config.jwt) {
      resolve({})
      return
    }

    if (jwt === null) {
      jwt = window.sessionStorage.getItem('jwt')
    } else {
      window.sessionStorage.setItem('jwt', jwt)
    }

    if (!window.sessionStorage.jwt) {
      throw new SimpleError('Empty access token')
    }

    JWT.verify(window.sessionStorage.jwt, config.arena.pub, function (err, decoded) {
      if (err) {
        LogError(err)
        throw new SimpleError('Invalid access token')
      }

      window.sessionStorage.setItem('jwt_decoded', JSON.stringify(decoded))
      resolve(decoded)
    })
  })
}

/**
 * Return the already stored JWT
 *
 * @param {string} key
 * @param {*} def
 * @return {*}
 */
export function getDecodedToken (key = '', def = '') {
  if (!config.jwt) {
    return def
  }

  let decoded
  decoded = window.sessionStorage.getItem('jwt_decoded')
  if (!decoded) {
    LogError('Invalid access token: ' + key)
    return def
  }

  decoded = JSON.parse(decoded)
  if (!decoded) {
    LogError('Invalid access token: ' + key)
    return def
  }

  if (!key) {
    return decoded
  }

  return decoded[key] || def
}
