<template>
    <div
        :style="getStyle()"
        class="button"
        @click="$emit('click')"
        @mouseover="$emit('mouseover', $event)"
    >
        <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'ButtonBase',
  props: {
    svg: {
      type: String
    }
  },
  methods: {
    getStyle () {
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.button {
  height: 40px;
  width: 40px;
  transition: all .2s ease-in-out;
  cursor: pointer;

  svg {
    height: 40px;
    width: 40px;

    path {
      fill: #fff;
    }
  }

  &.disabled {
    svg {
      path {
        fill: #fff;
        fill-opacity: 0.5;
      }

      ellipse, circle {
        fill: $secondary;
        fill-opacity: 0.2;
      }
    }
  }

  &.enabled {
    svg {
      path {
        fill: #fff;
      }

      ellipse, circle {
        fill: $primary;
      }
    }
  }

  &:hover:not(.disabled) {
    transform: scale(1.2, 1.2);
  }
}
</style>
