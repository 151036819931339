import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'
import translations from './translations.json'

// https://github.com/Polyconseil/vue-gettext/issues/29
Vue.use(GetTextPlugin, {
  autoAddKeyAttributes: false,
  availableLanguages: {
    en_GB: 'British English',
    en_US: 'American English',
    es_US: 'Español',
    pt_BR: 'Português Brasileiro'
  },
  defaultLanguage: 'en_GB',
  translations,
  silent: true
})
