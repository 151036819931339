<template>
    <ButtonBase
        :class="{enabled}"
        @click="onClick()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" fill="#979797" r="28"/>
            <path
                d="M28 13C19.7294 13 13 19.7294 13 28C13 36.2706 19.7294 43 28 43C36.2706 43 43 36.2706 43 28C43 19.7294 36.2706 13 28 13ZM20.5 24.25C20.5 23.2169 21.3419 22.375 22.375 22.375C23.4081 22.375 24.25 23.2169 24.25 24.25C24.25 25.2831 23.4081 26.125 22.375 26.125C21.3419 26.125 20.5 25.2831 20.5 24.25ZM33.1694 35.6331C33.6138 35.8994 33.7581 36.475 33.4919 36.9194C33.3156 37.2119 33.0044 37.375 32.6875 37.375C32.5225 37.375 32.3556 37.3337 32.2056 37.2419L28 34.7181L23.7944 37.2419C23.6444 37.3337 23.4775 37.375 23.3125 37.375C22.9937 37.375 22.6844 37.2119 22.5081 36.9194C22.2419 36.4769 22.3863 35.8994 22.8288 35.6331L26.1775 33.625L22.8306 31.6169C22.3863 31.3506 22.2419 30.7731 22.5081 30.3306C22.7744 29.8881 23.35 29.74 23.7944 30.0081L28 32.5319L32.2056 30.0081C32.6444 29.7419 33.2256 29.8862 33.4919 30.3306C33.76 30.7731 33.6138 31.3506 33.1694 31.6169L29.8225 33.625L33.1694 35.6331ZM33.625 26.125C32.5919 26.125 31.75 25.2831 31.75 24.25C31.75 23.2169 32.5919 22.375 33.625 22.375C34.6581 22.375 35.5 23.2169 35.5 24.25C35.5 25.2831 34.6581 26.125 33.625 26.125Z" fill="white"
            />
        </svg>
    </ButtonBase>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { checkModerator, confEventEmit } from '@/utils/common'
import { REMOVE_BUTTON_STYLE, UNMUTE_POLICY } from '@/utils/consts'

export default {
  name: 'ButtonMuteAll',
  components: {
    ButtonBase
  },
  data () {
    return {
      enabled: false
    }
  },
  mounted () {
    this.onGlobalEvent(REMOVE_BUTTON_STYLE, this.removeStyle)
  },
  methods: {
    onClick () {
      checkModerator()
      this.enabled = !this.enabled
      confEventEmit(UNMUTE_POLICY, {
        value: this.enabled,
        attributes: {}
      })
    },
    removeStyle () {
      this.enabled = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
