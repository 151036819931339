export const CHAT_END = 'chat-end'
export const CHAT_ENDED = 'chat-ended'
export const CHAT_MESSAGE = 'chat-message'
export const CHAT_START = 'chat-start'
export const CHAT_STARTED = 'chat-started'
export const CHAT_STATUS = 'chat_status'
export const CLEAR_RAISED_HAND = 'clear-raised-hand'
export const DEFAULT_DISPLAY_NAME = 'Vai Vu User'
export const DRAWING_PEN = 'vaitel_svg'
export const DRAWING_START = 'drawing-start'
export const DRAWING_STOP = 'drawing-stop'
export const DRAWING_STARTED = 'drawing-started'
export const DRAWING_STOPPED = 'drawing-stopped'
export const FOLLOW_ME_COMMAND = 'follow-me'
export const HOST_CLOSED = 'HOST_CLOSED'
export const HOST_DESTROYED = 'HOST_DESTROYED'
export const KEY_SPACE = ' '
export const KEY_A = 'A'
export const KEY_C = 'C'
export const KEY_D = 'D'
export const KEY_F = 'F'
export const KEY_H = 'H'
export const KEY_M = 'M'
export const KEY_R = 'R'
export const KEY_S = 'S'
export const KEY_T = 'T'
export const KEY_V = 'V'
export const KEY_W = 'W'
export const KEYBOARD_SHORTCUT_DOWN = 'shortcut-down'
export const KEYBOARD_SHORTCUT_UP = 'shortcut-up'
export const LOCAL_MUTED = 'local-muted'
export const MEETING_DISPLAY_NAME = 'meeting-display-name'
export const MEETING_ENDED = 'ENDED'
export const MEETING_ERROR = 'ERROR'
export const MEETING_INUSE = 'INUSE'
export const MEETING_JOINED = 'meeting-joined'
export const MEETING_MODERATOR = 'meeting-moderator'
export const MEETING_MODERATOR_REQUEST = 'meeting-moderator-request'
export const MEETING_WARN = 'WARN'
export const MODERATOR_GRANTED = 'moderator-granted'
export const RECORDING_CHANGED = 'recording'
export const REMOVE_BUTTON_STYLE = 'remove-button-style'
export const SHARE_STARTED = 'share-started'
export const SHARE_STOPPED = 'share-stopped'
export const STOP_RECORDING = 'stop-recording'
export const TRACKS_UPDATED = 'tracks-updated'
export const UNAVAILABLE_AUDIO = 'unavailable-audio-devices'
export const UNAVAILABLE_DEVICES = 'unavailable-devices'
export const UNAVAILABLE_VIDEO = 'unavailable-video-devices'
export const UNMUTE_POLICY = 'unmute-policy'
export const UPDATE_BLUR = 'updateBlur'
export const WHITEBOARD_COMMAND = 'vaitel_whiteboard_command'
export const WHITEBOARD_COMMAND_CLOSE = 'vaitel_whiteboard_command_close'
export const DISABLE_VIDEO = 'disable_video'
