<template>
    <ButtonOverBase
        :class="{ jigasi }"
        @click="emitClick()"
        @mouseover="emitOver()"
    >
        <SvgOverCrown/>
    </ButtonOverBase>
</template>

<script>
import ButtonOverBase from '@/components/ButtonOverBase'
import { SvgOverCrown } from '@/svgs'
import { _ } from '@/lang'

export default {
  name: 'ButtonOverCrown',
  components: {
    ButtonOverBase,
    SvgOverCrown
  },
  props: {
    jigasi: {
      Boolean,
      default: false
    }
  },
  methods: {
    emitClick () {
      if (this.jigasi) return
      this.$emit('click')
    },
    emitOver () {
      if (this.jigasi) return
      this.$emit('mouseover', '#515151', _('Grant moderator'))
    }
  }
}
</script>
