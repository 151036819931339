import { _ } from '@/lang'

export class SimpleError extends Error {
  constructor (message) {
    super(message)
    this.name = 'SimpleError'
  }
}

export class ValidationError extends Error {
  constructor (message) {
    super(message)
    this.name = 'ValidationError'
  }
}

/**
 * connection.passwordRequired
 */
export class PasswordError extends Error {
  constructor () {
    super(_('Password required'))
    this.name = 'PasswordError'
  }
}

/**
 */
export class ConferenceError extends Error {
  constructor () {
    super(_('Conference connection has failed. Contact support.'))
    this.name = 'ConferenceError'
  }
}
