<template>
    <div :style="{color:hintColor}" class="hint">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'ButtonHint',
  props: {
    hintColor: String
  }
}
</script>

<style scoped>
  .hint {
    position: absolute;
    width: 100%;
    margin-top: 55px;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
  }
</style>
