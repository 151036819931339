<template>
    <ButtonBase
        :class="getClass()"
        @click="toggleView()"
        @mouseover="$emit('mouseover', $event)"
    >
        <svg fill="none" height="55" viewBox="0 0 56 55" width="56" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="28" cy="27.5" fill="#979797" rx="28" ry="27.5"/>
            <path d="M25.6923 12H14.1539C13.5166 12 13 12.5166 13 13.1539V24.6924C13 25.3296 13.5166 25.8462 14.1539 25.8462H25.6924C26.3296 25.8462 26.8462 25.3296 26.8462 24.6924V13.1539C26.8462 12.5166 26.3296 12 25.6923 12ZM24.5385 23.5385H15.3077V14.3077H24.5384V23.5385H24.5385Z" fill="white"/>
            <path d="M41.8466 12H30.3082C29.6709 12 29.1543 12.5166 29.1543 13.1539V24.6924C29.1543 25.3296 29.6709 25.8462 30.3082 25.8462H41.8466C42.4839 25.8462 43.0005 25.3296 43.0005 24.6924V13.1539C43.0004 12.5166 42.4839 12 41.8466 12ZM40.6928 23.5385H31.462V14.3077H40.6928V23.5385Z" fill="white"/>
            <path d="M25.6923 28.1538H14.1539C13.5166 28.1538 13 28.6704 13 29.3077V40.8462C13 41.4835 13.5166 42.0001 14.1539 42.0001H25.6924C26.3296 42.0001 26.8462 41.4835 26.8462 40.8462V29.3077C26.8462 28.6704 26.3296 28.1538 25.6923 28.1538ZM24.5385 39.6923H15.3077V30.4615H24.5384V39.6923H24.5385Z" fill="white"/>
            <path d="M41.8466 28.1538H30.3082C29.6709 28.1538 29.1543 28.6704 29.1543 29.3077V40.8462C29.1543 41.4835 29.6709 42.0001 30.3082 42.0001H41.8466C42.4839 42.0001 43.0005 41.4835 43.0005 40.8462V29.3077C43.0004 28.6704 42.4839 28.1538 41.8466 28.1538ZM40.6928 39.6923H31.462V30.4615H40.6928V39.6923Z" fill="white"/>
        </svg>
    </ButtonBase>
</template>
<script>
import ButtonBase from '@/components/ButtonBase'
import { KEY_W } from '@/utils/consts'

export default {
  name: 'ButtonTileView',
  components: {
    ButtonBase
  },
  props: ['view', 'tool', 'following'],
  data () {
    return {}
  },
  mounted () {
    this.onGlobalEvent(KEY_W, () => {
      this.toggleView()
    })
  },
  methods: {
    getClass () {
      if (this.tool || this.following) {
        return ['disabled']
      }

      return { enabled: this.view === 'tile' }
    },
    toggleView () {
      this.$emit('click')
    }
  }
}
</script>
