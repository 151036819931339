import { Modal } from 'bootstrap'

export default {
  methods: {
    show (callback) {
      if (!this.modal) {
        this.modal = new Modal(this.$el, {
          backdrop: false,
          keyboard: false
        })

        this.$el.addEventListener('hidden.bs.modal', () => {
          this.$emit('hidden.bs.modal')
        })

        this.$el.addEventListener('show.bs.modal', () => {
          this.$emit('show.bs.modal')
        })

        this.$el.addEventListener('shown.bs.modal', () => {
          this.$emit('shown.bs.modal')
        })
      }

      this.callback = callback || function () {}
      this.modal.show()
    },
    hide () {
      if (this.modal) this.modal.hide()
    }
  }
}
