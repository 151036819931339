<template>
    <div class="connectionQuality" @mouseover="showHint()" @mouseout="hideHint()">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" :style="{color: colorIcon}" class="bi bi-reception-4" viewBox="0 0 16 16">
            <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z"/>
        </svg>
        <div v-show="hint" class="hint" :style="{top: hintTop, left: hintLeft}">
            <div>
                <div class="tableClass">
                    <h6 v-translate>Bitrate</h6>
                    <div><span>Download: </span><b>{{ bitrate_download }}</b></div>
                    <div><span>Upload: </span> <b>{{ bitrate_upload }}</b></div>
                </div>
                <div class="tableClass">
                    <h6 v-translate>Packet Loss</h6>
                    <div><span>Download: </span><b> {{ packetloss_download }}</b></div>
                    <div><span>Upload: </span><b> {{ packetloss_upload }}</b></div>
                </div>
                <div v-if="jvbRTT || serverRegion || jvbIP || jvbProto" class="tableClass">
                    <h6 v-translate>Server</h6>
                    <div v-if="jvbRTT"><span>RTT: </span><b>{{ jvbRTT }}</b></div>
                    <div v-if="serverRegion"><span>Region: </span><b>{{ serverRegion }}</b></div>
                    <div v-if="jvbIP"><span>Connected: </span><b>{{ jvbIP }}</b></div>
                    <div v-if="jvbProto"><span>Proto: </span><b>{{ jvbProto }}</b></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NetworkStatus',
  props: {
    participant: {},
    type: String
  },
  data () {
    return {
      hint: false,
      quality: 0,
      jvbRTT: 0,
      serverRegion: '',
      jvbIP: '',
      jvbProto: '',
      packetloss_download: 0,
      packetloss_upload: 0,
      bitrate_download: 0,
      bitrate_upload: 0,
      hintTop: 0,
      hintLeft: 0
    }
  },
  computed: {
    colorIcon () {
      if (this.quality >= 85) {
        return '#00ca00'
      } else if (this.quality >= 50 && this.quality < 85) {
        return '#ffa100'
      }

      return '#d70000'
    }
  },
  mounted () {
    this.timer = setInterval(this.loadStats, 2000)
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    showHint () {
      this.setHintPosition()
      this.hint = true
    },
    hideHint () {
      setTimeout(() => {
        this.hint = false
      }, 3500)
    },
    loadStats () {
      if (!window.g_conference) {
        return
      }

      let stats = window.g_conference.connectionQuality
      if (this.type === 'remote') {
        if (!stats._remoteStats[this.participant]) {
          return
        }

        stats = stats._remoteStats[this.participant]
      } else {
        stats = stats._localStats
      }

      this.quality = stats?.connectionQuality
      this.packetloss_download = (stats?.packetLoss?.download ?? '0') + ' %'
      this.packetloss_upload = (stats?.packetLoss?.upload ?? '0') + ' %'
      this.bitrate_download = (stats?.bitrate?.download ?? '0') + ' Kbps'
      this.bitrate_upload = (stats?.bitrate?.upload ?? '0') + ' Kbps'
      this.jvbRTT = stats?.jvbRTT
      this.serverRegion = stats?.serverRegion
      if (stats?.transport?.length) {
        this.jvbIP = stats.transport[0].ip
        this.jvbProto = stats.transport[0].type
      } else {
        this.jvbIP = ''
        this.jvbProto = ''
      }
    },
    setHintPosition () {
      const rect = this.$el.getBoundingClientRect()

      const divHintHeight = 140
      const divHintWidth = 185
      const marginFromIcon = 20 // padding
      let hintTop = 0
      let hintWidth = 0

      if ((rect.top + divHintHeight) < (window.innerHeight - (divHintHeight + marginFromIcon))) {
        hintTop = rect.top + (marginFromIcon * 2)
      } else {
        hintTop = rect.top - divHintHeight
      }

      if ((rect.left + divHintWidth) < (window.innerWidth - (divHintWidth + marginFromIcon))) {
        hintWidth = rect.left + marginFromIcon
      } else {
        hintWidth = rect.left - (divHintWidth - marginFromIcon)
      }

      this.hintTop = hintTop + 'px'
      this.hintLeft = hintWidth + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.tableClass {
  border-top: 1px solid rgba(white, .25);
  margin-top: 1em;
  margin-bottom: 1em;
  color: #FFF;

  h6 {
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  b {
    font-weight: bold;
  }
}

.connectionQuality {
  pointer-events: initial;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 10px;
  z-index: 9999 !important;
}

.hint {
  position: fixed;
  width: 185px;
  font-size: 12px;
  padding: 10px;
  font-weight: bold;
  z-index: 9999 !important;
  border-radius: 10px;
  background-color: rgba($primary, .9);
}
</style>
