<template>
    <div class="modal" tabindex="-1" @mousemove="mouseOverlayReset()">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Chat</h5>
                    <div class="close-button" data-bs-dismiss="modal">&Cross;</div>
                </div>
                <div class="modal-body">
                    <div ref="scrollable" class="balloons scroll-styled">
                        <div
                            v-for="(message, index) in messagesGrouped()"
                            :key="index"
                            :class="getBalloonClass(message)"
                            class="balloon-block"
                        >
                            <div>
                                <div class="balloon">
                                    <div>
                                        <span :class="{pvt: message.pvt}" class="author">{{ getAuthor(message) }}</span>
                                        <div class="message-body">{{ message.body }}</div>
                                    </div>
                                    <div v-if="message.pvt && message.from !== 'me'" class="pvt" @click="reply(message)">
                                        <svg fill="none" height="11" viewBox="0 0 16 11" width="16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.6465 3.20949H5.78556V0.459774C5.78556 0.270043 5.6674 0.100477 5.48782 0.0326504C5.3101 -0.0370091 5.10539 0.0106526 4.97606 0.151805L0.323731 5.19296C0.16276 5.36802 0.16276 5.63475 0.323731 5.80981L4.97606 10.851C5.06631 10.9472 5.19193 11.0004 5.32033 11.0004C5.37709 11.0004 5.43385 10.9903 5.48782 10.9701C5.6674 10.9014 5.78556 10.7318 5.78556 10.5421V7.79236H6.76255C9.60792 7.79236 12.283 8.884 14.2956 10.8665C14.4296 10.9985 14.6306 11.037 14.8027 10.9655C14.9767 10.895 15.0902 10.7272 15.0902 10.5421C15.0902 6.49907 11.7508 3.20949 7.6465 3.20949Z" fill="black"/>
                                        </svg>
                                    </div>
                                </div>
                                <span class="timestamp">{{ formatTime(message.time) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="reply_to" class="private-footer border-top">
                    <label>To: </label>
                    <span @click="reply_to = ''">
                        <span>{{ reply_name }}</span>
                        <span>&Cross;</span>
                    </span>
                </div>
                <div class="modal-footer">
                    <div class="flex-grow-1">
                        <textarea
                            ref="message"
                            v-model="message"
                            class="message form-control"
                            rows="1"
                            @keydown.ctrl.space.exact="toggle()"
                            @keydown.enter.exact="send"
                        />
                    </div>
                    <EmojiPicker ref="EmojiPicker" :search="search" class="smile-button" @emoji="insert">
                        <div
                            slot="emoji-invoker"
                            slot-scope="{ events: { click: clickEvent } }"
                            @click.stop="clickEvent"
                        >
                            <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7344 20C16.3489 20 20.9005 15.5228 20.9005 10C20.9005 4.47715 16.3489 0 10.7344 0C5.11976 0 0.568237 4.47715 0.568237 10C0.568237 15.5228 5.11976 20 10.7344 20Z" fill="#FFCA28"/>
                                <path d="M6.92207 8.75C7.62389 8.75 8.19283 8.19036 8.19283 7.5C8.19283 6.80964 7.62389 6.25 6.92207 6.25C6.22025 6.25 5.65131 6.80964 5.65131 7.5C5.65131 8.19036 6.22025 8.75 6.92207 8.75Z" fill="#6D4C41"/>
                                <path d="M14.5466 8.75C15.2485 8.75 15.8174 8.19036 15.8174 7.5C15.8174 6.80964 15.2485 6.25 14.5466 6.25C13.8448 6.25 13.2759 6.80964 13.2759 7.5C13.2759 8.19036 13.8448 8.75 14.5466 8.75Z" fill="#6D4C41"/>
                                <path d="M10.7343 16.2499C9.03652 16.2499 7.44171 15.5986 6.24084 14.4186C5.99304 14.1749 5.99304 13.7786 6.24084 13.5349C6.48864 13.2911 6.89147 13.2911 7.13927 13.5349C9.0594 15.4249 12.4066 15.4249 14.328 13.5349C14.5758 13.2911 14.9786 13.2911 15.2264 13.5349C15.4742 13.7786 15.4742 14.1749 15.2264 14.4186C14.0268 15.5986 12.432 16.2499 10.7343 16.2499Z" fill="#6D4C41"/>
                            </svg>
                        </div>
                        <div
                            slot="emoji-picker"
                            slot-scope="{ emojis, insert }"
                        >
                            <div class="emoji-container shadow rounded">
                                <div class="p-2 border-bottom">
                                    <input
                                        ref="emojiSearch"
                                        v-model="search"
                                        class="form-control form-control-sm"
                                        placeholder="Search..."
                                        type="text"
                                        @keydown.esc.stop="hidePicker()"
                                        @keydown.enter="insert(emojis)"
                                    />
                                </div>
                                <div class="emoji-icons-container">
                                    <div
                                        v-for="(emojiGroup, category) in emojis"
                                        :key="category"
                                    >
                                        <small class="text-muted">{{ category }}</small>
                                        <div class="d-flex flex-wrap">
                                            <span
                                                v-for="(emoji, emojiName) in emojiGroup"
                                                :key="emojiName"
                                                :title="emojiName"
                                                class="emoji"
                                                tabindex="0"
                                                @click="insert(emoji)"
                                                @keydown.enter="insert(emoji)"
                                                @keydown.esc.stop="hidePicker()"
                                                @keydown.down="hidePicker()"
                                            >{{ emoji }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </EmojiPicker>
                    <div class="send-button" @click="send()">
                        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5954 0.126175L0.487941 10.5694C-0.219166 10.9756 -0.129313 11.9597 0.573888 12.2566L4.72668 13.9984L15.9505 4.10975C16.1654 3.91838 16.4701 4.21129 16.2865 4.4339L6.87534 15.8964V19.0403C6.87534 19.962 7.98874 20.3252 8.53568 19.6574L11.0164 16.6385L15.8841 18.6771C16.4389 18.9115 17.0718 18.5639 17.1733 17.9663L19.9861 1.09473C20.119 0.305826 19.2712 -0.264372 18.5954 0.126175Z" fill="#00C881"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDistance } from 'date-fns'
import BaseModal from '@/dialogs/_mixin'
import EmojiPicker from 'vue-emoji-picker'
import { CHAT_ENDED, CHAT_MESSAGE, CHAT_START, CHAT_STARTED } from '@/utils/consts'
import { _ } from '@/lang'

const messageContract = {
  body: '',
  time: '',
  pvt: false,
  from: '',
  to: ''
}

export default {
  name: 'Chat',
  components: {
    EmojiPicker
  },
  mixins: [BaseModal],
  data () {
    return {
      messages: [],
      reply_to: '',
      reply_name: '',
      message: '',
      search: ''
    }
  },
  mounted () {
    this.onGlobalEvent(CHAT_START, (data) => {
      this.reply_to = data.participant_id
      this.reply_name = data.display_name
    })

    this.onGlobalEvent(CHAT_MESSAGE, (data = {}) => {
      this.messages.push({
        ...messageContract,
        time: new Date(),
        pvt: data.pvt,
        body: data.body,
        display_name: data.display_name,
        from: data.participant_id,
        to: 'me'
      })

      this.$nextTick(() => {
        this.$refs.scrollable.scrollTo(0, this.$refs.scrollable.scrollHeight)
      })
    })

    this.$on('hidden.bs.modal', () => {
      this.globalEventEmit(CHAT_ENDED)
    })

    this.$on('shown.bs.modal', () => {
      this.globalEventEmit(CHAT_STARTED)
      this.$refs.scrollable.scrollTo(0, this.$refs.scrollable.scrollHeight)
      this.$refs.message.focus()
    })
  },
  methods: {
    mouseOverlayReset () {
      document.querySelector('html').style.cursor = 'default'
    },
    * messagesGrouped () {
      let lastKey = null
      let lastMessage = null
      for (const i in this.messages) {
        if (!this.messages.hasOwnProperty(i)) continue
        const message = this.messages[i]
        const key = message.to + message.from + message.pvt

        if (!lastMessage) {
          lastMessage = { ...message }
          lastKey = key
          continue
        }

        if (lastKey === key) {
          lastMessage.body += '\n' + message.body
          lastMessage.time = message.time
          continue
        }

        yield lastMessage
        lastMessage = { ...message }
        lastKey = key
      }

      if (lastMessage) {
        yield lastMessage
      }
    },
    reply (message) {
      this.reply_to = message.from
      this.reply_name = message.display_name || message.from

      this.$refs.message.focus()
    },
    getAuthor (message) {
      if (message.to === 'all') {
        return ''
      }

      const author = []
      author.push(message.pvt ? _('Private message') : '')
      if (message.from !== 'me') {
        author.push(message.display_name || message.from)
      }

      if (message.to !== 'me') {
        author.push(message.display_name || message.to)
      }

      return author.join(' ')
    },
    async hidePicker () {
      this.$refs.EmojiPicker.hide()
      this.$refs.message.focus()
    },
    async toggle () {
      this.$refs.EmojiPicker.toggle({
        clientX: 0,
        clientY: 0
      })

      await this.$nextTick()
      this.$refs.emojiSearch.focus()
    },
    formatTime (v) {
      if (!v) {
        v = new Date()
      }
      return formatDistance(v, new Date())
    },
    async send (e) {
      if (!this.message) return

      const pvt = !!this.reply_to

      this.messages.push({
        ...messageContract,
        time: new Date(),
        body: this.message,
        display_name: this.reply_name || '',
        from: 'me',
        to: this.reply_to || 'all',
        pvt
      })

      await this.$nextTick()

      if (pvt) {
        window.g_conference.sendMessage(this.message, this.reply_to)
      } else {
        window.g_conference.sendMessage(this.message, '')
      }

      this.message = ''
      await this.$nextTick()

      this.$refs.scrollable.scrollTo(0, this.$refs.scrollable.scrollHeight)
      e.preventDefault()
    },
    getBalloonClass (message) {
      return {
        left: message.to === 'me',
        right: message.from === 'me'
      }
    },
    async insert (emoji) {
      if (typeof emoji === 'object') {
        if (Object.values(emoji).length) emoji = Object.values(emoji).shift()
        if (Object.values(emoji).length) emoji = Object.values(emoji).shift()
      }

      const msg = this.message + ''
      const start = this.$refs.message.selectionStart
      this.message = msg.substring(0, start) + emoji + msg.substring(this.$refs.message.selectionEnd)

      await this.$nextTick()
      this.$refs.message.setSelectionRange(start, start)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixin";

.modal {
  pointer-events: none;
}

.modal-dialog {
  position: absolute;
  right: 90px;
  bottom: 10px;
  width: 400px;
  max-width: 100%;
  height: 600px;
  max-height: 100vh;

  @include media-sm() {
    position: initial;
    right: initial;
    bottom: initial;
    width: initial;
  }

  .modal-body {
    padding-right: .5rem;
    height: 400px;
    max-height: calc(100vh - 180px);
  }
}

.balloons {
  overflow-y: scroll;
  height: 100%;
  padding-right: .5rem;
}

.balloon-block {
  display: flex;

  &.left {
    .balloon {
      border-radius: 0 20px 20px 20px;
    }
  }

  &.right {
    .balloon {
      border-radius: 20px 0 20px 20px;
    }

    justify-content: flex-end;
  }

  .balloon {
    display: flex;
    background-color: #F0F0F0;
    min-height: 1em;
    min-width: 100px;
    max-width: 100%;
    padding: .5em;

    > .pvt {
      display: flex;
      align-items: center;
      border-left: 2px solid white;
      padding-left: .5rem;
    }
  }

  .author {
    display: block;
    color: $primary;
    padding-right: .5em;

    &.pvt {
      color: $arena-blue;
    }
  }

  .timestamp {
    padding-left: .5em;
    font-size: .8em;
    color: $secondary;
  }
}

.modal-footer {
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.message {
  resize: none;
  overflow: hidden;
  border-radius: 50px;
  padding-right: 35px;
}

.smile-button {
  margin-left: -35px;
  cursor: pointer;
}

.send-button {
  margin-left: 15px;
  cursor: pointer;
}

.message-body {
  white-space: pre-line;
}

.emoji-container {
  position: fixed;
  right: 100px;
  bottom: 100px;
  width: 200px;
  background-color: #fff;
  border: 1px solid $input-border-color;

  .emoji-icons-container {
    overflow-y: scroll;
    height: 200px;

    .emoji {
      cursor: pointer;

      &:hover {
        transform: scale(1.2, 1.2) translateY(-20%);
      }
    }
  }
}

.close-button {
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.private-footer {
  padding: .2rem 1rem;

  > span {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    background-color: $arena-blue;
    color: #fff;
    padding: .2rem .5rem;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
  }
}

</style>
