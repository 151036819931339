<template>
    <ButtonOverBase
        :class="{ jigasi }"
        @click="emitClick()"
        @mouseover="emitOver()"
    >
        <SvgOverSpeaker/>
        <input v-if="showRange" v-model="range" v-on:change="closeRange" type="range" step="0.01" min="0" max="1"/>
    </ButtonOverBase>
</template>

<script>
import ButtonOverBase from '@/components/ButtonOverBase'
import { SvgOverSpeaker } from '@/svgs'
import { showError } from '@/utils/notifications'
import { _ } from '@/lang'

export default {
  name: 'ButtonOverSpeaker',
  components: {
    ButtonOverBase,
    SvgOverSpeaker
  },
  props: {
    jigasi: {
      Boolean,
      default: false
    }
  },
  data () {
    return {
      element: null,
      showRange: false,
      range: 0
    }
  },
  watch: {
    range (volume) {
      if (this.element) {
        this.element.volume = volume
      }
    }
  },
  methods: {
    setElement (el) {
      if (el) {
        this.element = el
        this.range = this.element.volume
      }
    },
    changeVolume () {
      this.showRange = !this.showRange
    },
    closeRange () {
      this.showRange = false
    },
    emitClick () {
      if (this.jigasi) return
      this.changeVolume()
      this.$emit('click')
    },
    emitOver () {
      if (this.jigasi) return
      this.$emit('mouseover', '#9900CF', _('Adjust Volume'))
    }
  }
}
</script>

<style scoped>
[type=range] {
  margin-top: 2rem;
  width: 7rem;
}
</style>
