<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-translate class="modal-title">Recording</h5>
                </div>
                <div class="modal-body">
                    <p v-translate>confirm_action_recording_being_made</p>
                </div>
                <div class="modal-footer">
                    <div v-translate class="btn btn-light" data-bs-dismiss="modal">Cancel</div>
                    <div v-translate class="btn btn-primary" @click="primaryClick()">Leave</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from '@/dialogs/_mixin'

// 'confirm_action_recording_being_made' => 'There is a recording being made. What would you like to do?',

export default {
  name: 'ConfirmLeave',
  mixins: [BaseModal],
  data () {
    return {}
  },
  methods: {
    primaryClick () {
      this.callback(true)
      this.hide()
    }
  }
}
</script>

<style scoped>

</style>
