<template>
    <ButtonOverBase
        @mouseover="$emit('mouseover', '#B40044', _('Unpin participant'))"
        @click="$emit('click')"
    >
        <SvgOverUnpin/>
    </ButtonOverBase>
</template>

<script>
import ButtonOverBase from '@/components/ButtonOverBase'
import { SvgOverUnpin } from '@/svgs'

export default {
  name: 'ButtonOverUnpin',
  components: {
    ButtonOverBase,
    SvgOverUnpin
  }
}
</script>
