<template>
    <div v-if="visible" class="position-relative">
        <ButtonBase
            ref="ButtonBase"
            :class="{enabled: raisedHand || badge}"
            @click="onClick()"
            @mouseover="$emit('mouseover', $event)"
        >
            <svg
                fill="none"
                height="55"
                viewBox="0 0 56 55"
                width="56"
                xmlns="http://www.w3.org/2000/svg"
            >
                <ellipse
                    cx="28"
                    cy="27.5"
                    fill="#979797"
                    rx="28"
                    ry="27.5"
                />
                <path
                    d="M20.5344 31.5291C18.03 31.5291 16 33.5591 16 36.0635V41.128C16 41.6094 16.3907 42 16.872 42H35.2536C35.7349 42 36.1256 41.6094 36.1256 41.128V36.189C36.2023 36.0565 36.2721 35.9135 36.3279 35.7635L39.8856 26.346C40.0042 26.0321 40.067 25.6972 40.067 25.3589V14.7904C40.067 13.2487 38.8183 12 37.2766 12C35.7349 12 34.4862 13.2487 34.4862 14.7904V24.8497L31.9644 31.5291H20.5344Z"
                    fill="white"
                />
                <path
                    d="M25.0641 29.2394C28.6497 29.2394 31.5656 26.3235 31.5656 22.7379C31.5656 19.1523 28.6496 16.2363 25.0641 16.2363C21.4785 16.2363 18.5625 19.1523 18.5625 22.7379C18.5625 26.3235 21.4785 29.2394 25.0641 29.2394Z"
                    fill="white"
                />
            </svg>
        </ButtonBase>
        <div v-if="badge" class="my-badge">
            {{ badge }}
        </div>
    </div>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import { confEventEmit, isModerator, showPopover } from '@/utils/common'
import { CLEAR_RAISED_HAND, KEY_R, MEETING_JOINED } from '@/utils/consts'
import { _ } from '@/lang'

export default {
  name: 'ButtonRaiseHand',
  components: { ButtonBase },
  props: [
    'badge'
  ],
  data () {
    return {
      visible: false,
      raisedHand: false,
      raisedHandTimeout: null
    }
  },
  mounted () {
    // To only show after connected
    this.onGlobalEvent(MEETING_JOINED, () => {
      this.visible = true

      window.g_conference.addCommandListener(CLEAR_RAISED_HAND, () => {
        if (this.raisedHand) {
          this.showPopover(_('The host got it'))
          this.raisedHand = false
          window.g_conference.setLocalParticipantProperty('raisedHand', false)
        }
      })
    })

    this.onGlobalEvent(KEY_R, (v) => {
      this.onClick()
    })
  },
  methods: {
    showPopover (text) {
      showPopover(this.$el, text, {
        position: 'left'
      })
    },
    onClick () {
      // When is host user, clear badge and notify users
      if (isModerator()) {
        confEventEmit(CLEAR_RAISED_HAND, {})
        return
      }

      this.raisedHand = !this.raisedHand
      // Send to others
      window.g_conference.setLocalParticipantProperty('raisedHand', this.raisedHand)
    }
  }
}
</script>
