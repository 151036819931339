<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Kick out this participant</h5>
                    <!--                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>-->
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to kick out this participant?</p>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-light" data-bs-dismiss="modal">Cancel</div>
                    <div class="btn btn-primary" @click="primaryClick()">Kick Out</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from '@/dialogs/_mixin'

export default {
  name: 'ConfirmKick',
  mixins: [BaseModal],
  data () {
    return {}
  },
  methods: {
    primaryClick () {
      this.callback(true)
      this.hide()
    }
  }
}
</script>

<style scoped>

</style>
