<template>
    <div>
        <router-view :key="$route.fullPath"></router-view>
        <div class="container">
            <Settings ref="Settings"/>
            <ConfirmKick ref="ConfirmKick"/>
            <ConfirmLeave ref="ConfirmLeave"/>
            <Login ref="Login"/>
            <Chat ref="Chat"/>
            <Statistics ref="Statistics"/>
            <KeyboardShortcut ref="KeyboardShortcut"/>
        </div>
        <notifications position="bottom left" class="bottom-left"/>
    </div>
</template>

<script>
import ConfirmKick from '@/dialogs/ConfirmKick'
import Settings from '@/dialogs/Settings'
import Login from '@/dialogs/Login'
import ConfirmLeave from '@/dialogs/ConfirmLeave'
import Chat from '@/dialogs/Chat'
import Statistics from '@/dialogs/Statistics'
import KeyboardShortcut from '@/dialogs/KeyboardShortcut'

export default {
  name: 'App',
  components: {
    ConfirmKick,
    Settings,
    Login,
    ConfirmLeave,
    Chat,
    Statistics,
    KeyboardShortcut
  }
}
</script>

<style lang="scss">
@import "styles/app";
</style>
