import Vue from 'vue'
import Notifications from 'vue-notification'
Vue.use(Notifications)

export function showError (title, text, opts = {}) {
  Vue.notify({
    title,
    text,
    type: 'error',
    ...opts
  })
}

export function showInfo (title, text, opts = {}) {
  Vue.notify({
    title,
    text,
    type: 'info',
    ...opts
  })
}

export function showWarn (title, text, opts = {}) {
  Vue.notify({
    title,
    text,
    type: 'warn',
    ...opts
  })
}
