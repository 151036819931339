<template>
    <div>
        <ButtonBase
            @click="onClick()"
            @mouseover="showHint($event.target, _('Invite'), 'left')"
        >
            <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" fill="#979797" r="28"/>
                <path d="M32.0903 31.3088C28.4562 31.3088 21.1812 33.1778 21.1812 36.9088V39.7088H42.9993V36.9088C42.9993 33.1778 35.7243 31.3088 32.0903 31.3088Z" fill="white"/>
                <path d="M32.0903 28.5091C35.1039 28.5091 37.5448 25.9961 37.5448 22.9091C37.5448 19.8151 35.1039 17.3091 32.0903 17.3091C29.0767 17.3091 26.6357 19.8151 26.6357 22.9091C26.6357 25.9961 29.0766 28.5091 32.0903 28.5091Z" fill="white"/>
                <path d="M23.9091 25.7088H19.8182V21.5088H17.0909V25.7088H13V28.5088H17.0909V32.7088H19.8182V28.5088H23.9091V25.7088Z" fill="white"/>
            </svg>
        </ButtonBase>
        <div v-if="float_visible" :style="{top, left}" class="float-menu">
            <ButtonCopy
                @click="hideFloat()"
                @mouseover="showHint($event.target, _('Copy'))"
            />
            <ButtonSend
                @click="hideFloat()"
                @mouseover="showHint($event.target, _('E-mail'))"
            />
        </div>
    </div>
</template>

<script>
import ButtonBase from '@/components/ButtonBase'
import ButtonCopy from '@/components/ButtonCopy'
import ButtonSend from '@/components/ButtonSend'
import jQuery from 'jquery'
import { showHint } from '@/utils/common'

export default {
  name: 'ButtonInvite',
  components: {
    ButtonSend,
    ButtonCopy,
    ButtonBase
  },
  data () {
    return {
      popover: {},
      float_visible: false,
      top: '',
      left: ''
    }
  },
  methods: {
    onClick () {
      this.float_visible = true

      const rect = this.$el.getBoundingClientRect()
      const mid = (rect.top + (rect.height / 2))
      this.top = (mid - 50) + 'px'
      this.left = (rect.left - 80 - 10) + 'px'

      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.float_visible = false
      }, 5000)
    },
    hideFloat () {
      clearTimeout(this.timer)
      this.float_visible = false
    },
    showHint (elem, text) {
      showHint(jQuery(elem).closest('.button').get(0), text, { position: 'left' })
    }
  }
}
</script>

<style lang="scss" scoped>
.float-menu {
  position: fixed;
  //border: 1px solid blue;
  width: 80px;
  height: 100px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}
</style>
