import { cleanUp, isHost, Log } from '@/utils/common'
import { showInfo } from '@/utils/notifications'
import { _ } from '@/lang'
import jQuery from 'jquery'

let globalRemoveEvents = () => {
}

export function removeWinEvents () {
  globalRemoveEvents()
}

export function installWinEvents (vue) {
  const onLeave = (evnt) => {
    jQuery(window).trigger('jitsi_unload')
    cleanUp()
  }

  const onBeforeLeave = (evnt) => {
    // Check for recording running
    if (vue.recording || isHost()) {
      evnt.returnValue = 'Are you sure?'
      if (vue.recording) {
        setTimeout(() => {
          showInfo(_('There is a recording in progress'))
        }, 1000)
      }

      if (isHost()) {
        setTimeout(() => {
          showInfo(_('When host user leaves, all participanst are kicked out'))
        }, 1000)
      }
    } else {
      jQuery(window).trigger('jitsi_unload')
      cleanUp()
    }

    return evnt.returnValue
  }

  window.addEventListener('beforeunload', onBeforeLeave)
  window.addEventListener('unload', onLeave)
  globalRemoveEvents = () => {
    window.removeEventListener('beforeunload', onBeforeLeave)
    window.removeEventListener('unload', onLeave)
  }
}
