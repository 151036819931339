<template>
    <div
        v-show="show || isPinned(data) || view !== 'stripe'"
        ref="videocontainer"
        :class="{ dominant: !!data.dominant, pinned: isPinned(data), sharing }"
        class="video-container"
        @click="$emit('click')"
    >
        <NetworkStatus :participant="data.participant" :type="type"/>
        <template v-if="type === 'remote'">
            <canvas ref="canvas"></canvas>
            <VaiVideo
                v-if="data.video"
                ref="video"
                v-show="!isVideoMuted(data)"
                :key="data.video.id"
                :style="{top: topHeight, position: 'relative'}"
                :stream="videoStream()"
            />
            <div :class="{muted: isVideoMuted(data), sharing: !isOverlay}" class="overlay">
                <div>
                    <div class="name">
                        <span>{{ data.display_name }}</span>
                    </div>
                    <div v-if="isVideoMuted(data)" class="video-muted"><span>{{ _('Camera is not available') }}</span>
                    </div>
                    <div class="commands" @click="$emit('click')">
                        <ButtonOverUnpin
                            v-if="isPinned(data) && !sharing"
                            @click="unpin(data)"
                            @mouseover="showHint"
                        />

                        <ButtonOverMic
                            v-if="moderator"
                            :jigasi="data.jigasi"
                            @click="mute(data)"
                            @mouseover="showHint"
                        />

                        <ButtonOverCam
                            v-if="moderator"
                            :jigasi="data.jigasi"
                            @click="disableCamera(data.participant)"
                            @mouseover="showHint"
                        />

                        <ButtonOverChat
                            :jigasi="data.jigasi"
                            @click="startPrivate(data)"
                            @mouseover="showHint"
                        />

                        <ButtonOverSpeaker
                            ref="btnSpeaker"
                            :jigasi="data.jigasi"
                            @mouseover="showHint"
                        />

                        <ButtonOverKick
                            v-if="moderator && data.participant"
                            @click="kick(data)"
                            @mouseover="showHint"
                        />

                        <ButtonOverCrown
                            v-if="!data.moderator && moderator && data.participant"
                            :jigasi="data.jigasi"
                            @click="grantModerator(data.participant)"
                            @mouseover="showHint"
                        />

                        <ButtonHint v-bind:hintColor="hintColor">{{ hint }}</ButtonHint>
                    </div>
                </div>
                <div class="status">
                    <IconOverMutedMic v-if="isAudioMuted(data)" />
                    <IconOverMutedVideo v-if="isVideoMuted(data)" />
                    <IconOverModerator v-if="data.moderator" />
                </div>
            </div>
            <VaiAudio
                v-if="data.audio"
                ref="audio"
                :key="data.audio.id"
                :stream="audioStream()"
            />
        </template>
        <template v-else-if="type === 'local'">
            <canvas ref="canvas"></canvas>
            <VaiVideo
                ref="video"
                :class="getLocalClass(data)"
                :style="getLocalStyles()"
                class="local-video"
                muted
                :stream="videoStream()"
            />
            <div :class="{muted: isVideoMuted(data) || localVideoMuted, sharing: !isOverlay}" class="overlay">
                <div class="name">{{ data.display_name }} (me)</div>

                <div v-if="localVideoMuted" class="video-muted">
                    <span>{{ _('Camera is not available') }}</span>
                </div>

                <div class="status">
                    <IconOverMutedMic v-if="isAudioMuted(data)" />
                    <IconOverMutedVideo v-if="isVideoMuted(data) || localVideoMuted" />
                    <IconOverModerator v-if="moderator" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ButtonHint from '@/components/ButtonHint'
import ButtonOverUnpin from '@/components/ButtonOverUnpin'
import ButtonOverMic from '@/components/ButtonOverMic'
import ButtonOverCam from '@/components/ButtonOverCam'
import ButtonOverChat from '@/components/ButtonOverChat'
import ButtonOverSpeaker from '@/components/ButtonOverSpeaker'
import ButtonOverKick from '@/components/ButtonOverKick'
import IconOverMutedMic from '@/components/IconOverMutedMic'
import IconOverMutedVideo from '@/components/IconOverMutedVideo'
import ButtonOverCrown from '@/components/ButtonOverCrown'
import { checkModerator, confEventEmit, isHost, isModerator, Log } from '@/utils/common'
import { CHAT_START, DISABLE_VIDEO, LOCAL_MUTED, MODERATOR_GRANTED, SHARE_STARTED, SHARE_STOPPED } from '@/utils/consts'
import IconOverModerator from '@/components/IconOverModerator'
import { showError } from '@/utils/notifications'
import { _ } from '@/lang'
import NetworkStatus from '@/components/NetworkStatus'
import VaiVideo from '@/components/VaiVideo'
import VaiAudio from '@/components/VaiAudio'

export default {
  name: 'VideoContainer',
  components: {
    VaiAudio,
    VaiVideo,
    NetworkStatus,
    IconOverModerator,
    ButtonOverCrown,
    IconOverMutedVideo,
    IconOverMutedMic,
    ButtonOverKick,
    ButtonOverSpeaker,
    ButtonOverChat,
    ButtonOverMic,
    ButtonOverCam,
    ButtonOverUnpin,
    ButtonHint
  },
  props: ['data', 'type', 'view', 'show', 'isOverlay'],
  data () {
    return {
      localVideoMuted: false,
      moderator: false,
      sharing: false,
      host: false,
      hint: '',
      hintTimeout: null,
      hintColor: '',
      topHeight: 0
    }
  },
  mounted () {
    this.host = isHost()
    this.moderator = isModerator()

    this.onGlobalEvent(MODERATOR_GRANTED, (v) => {
      this.moderator = true
    })

    this.onGlobalEvent(LOCAL_MUTED, (v) => {
      this.localVideoMuted = window.vueConference.local && window.vueConference.local.video.isMuted()
    })

    this.onGlobalEvent(SHARE_STARTED, () => {
      this.sharing = true
    })

    this.onGlobalEvent(SHARE_STOPPED, () => {
      this.sharing = false
    })

    if (this.$refs.btnSpeaker && this.$refs.audio) {
      this.$refs.btnSpeaker.setElement(this.$refs.audio.$el)
    }

    this.copyToCanvas()
  },
  methods: {
    copyToCanvas () {
      if (!this.$refs.canvas) return
      if (!this.$refs.video) return
      var context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.video.$el, 0, 0, 320, 240)
      requestAnimationFrame(this.copyToCanvas)
    },
    getLocalClass (data) {
      return {
        flip: !this.sharing
      }
    },
    getLocalStyles () {
      const containerHeight = this.$refs.videocontainer?.clientHeight
      const containerWidth = this.$refs.videocontainer?.clientWidth
      const height = this.$refs.video?.clientHeight
      const width = this.$refs.video?.clientWidth
      if (!height || !width || !this.sharing) {
        return {}
      }
      return {
        height: height < width ? '100%' : containerHeight,
        width: height < width ? containerWidth : '100%'
      }
    },
    isAudioMuted (data) {
      if (!data.audio) {
        return true
      }

      data.audioMuted = data.audio.isMuted()
      return !!data.audioMuted
    },
    isVideoMuted (data) {
      if (!data.video) {
        return true
      }

      data.videoMuted = data.video.isMuted()
      return !!data.videoMuted
    },
    isScreenShare (data) {
      return data.video.videoType === 'desktop'
    },
    unpin (data) {
      Log('unpin')
      data.pinned = false
    },
    isPinned (data) {
      return this.view === 'stripe' && !!data.pinned
    },
    mute ({
      participant
    }) {
      window.g_conference.muteParticipant(participant)
    },
    disableCamera (participant) {
      confEventEmit(DISABLE_VIDEO, { attributes: { participant } })
    },
    showHint (color, hint) {
      this.hint = hint
      this.hintColor = color
      window.clearTimeout(this.hintTimeout)
      this.hintTimeout = window.setTimeout(() => {
        this.hint = this.hintColor = ''
      }, 1000)
    },
    kick ({
      participant
    }) {
      this.dialog('ConfirmKick').show(r => {
        if (!r) return
        window.g_conference
          .kickParticipant(participant)
          .then(r2 => {
            if (!r2) showError(_('Kick'), _('Something went wrong'))
          })
          .catch(() => {
            showError(_('Kick'), _('You can\'t kick moderators'))
          })
      })
    },
    startPrivate (data = {}) {
      this.globalEventEmit(CHAT_START, {
        participant_id: data.participant,
        display_name: data.display_name
      })
    },
    grantModerator (participantId) {
      checkModerator()
      window.g_conference.grantOwner(participantId)
      this.$parent.markAsModerator(participantId)
    },
    videoStream () {
      if (this.$parent.screenShareTrack && this.type === 'local' && !this.$parent.screenShareTrack.disposed) {
        return this.$parent.screenShareTrack
      }

      return this.data?.video
    },
    audioStream () {
      return this.data?.audio
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.video-container {
  display: inline-block;
  height: var(--video-item-height);
  width: var(--video-item-width);
  position: relative;
  overflow: hidden;

  &.pinned {
    z-index: -1;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .overlay {
      div.name {
        font-size: 3rem;
      }
    }
  }

  .overlay {
    transition: all .2s ease-in-out;
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &.muted {
      opacity: 1;
      background-color: rgba(255, 255, 255, .9);
    }

    &:hover:not(.sharing) {
      opacity: 1;
      background-color: rgba(255, 255, 255, .9);
    }

    div.name {
      font-size: 1rem;
      text-shadow: 0 0 3px white;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 20px;
    }

    div.commands {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 16px;
      position: absolute;
      bottom: 8px;
      left: 4px;
    }
  }

  canvas {
    width: 100%;
    height: 100%;
    filter: blur(50px);
    display: block;
    position: absolute;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.flip {
      transform: scaleX(-1)
    }
  }
}

.video-muted {
  color: rgb(215, 0, 0);
  text-align: center;
}
</style>
