<template>
    <ButtonOverBase
        :class="{ jigasi }"
        @click="emitClick()"
        @mouseover="emitOver()"
    >
        <SvgOverMic/>
    </ButtonOverBase>
</template>

<script>
import ButtonOverBase from '@/components/ButtonOverBase'
import { SvgOverMic } from '@/svgs'
import { _ } from '@/lang'

export default {
  name: 'ButtonOverMic',
  components: {
    ButtonOverBase,
    SvgOverMic
  },
  props: {
    jigasi: {
      Boolean,
      default: false
    }
  },
  methods: {
    emitClick () {
      if (this.jigasi) return
      this.$emit('click')
    },
    emitOver () {
      if (this.jigasi) return
      this.$emit('mouseover', '#00A9E9', _('Mute participant'))
    }
  }
}
</script>
