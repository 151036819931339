<template>
    <div class="icon-over-main">
        <SvgOverMutedMic/>
    </div>
</template>

<script>
import { SvgOverMutedMic } from '@/svgs'

export default {
  name: 'IconOverMutedMic',
  components: {
    SvgOverMutedMic
  }
}
</script>

<style scoped>
.icon-over-main {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 24px;
    height: 24px;
}
</style>
