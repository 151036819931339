<template>
    <div
        class="over-button"
        @click.stop="$emit('click')"
        @mouseover="$emit('mouseover')"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'ButtonOverBase',
  props: {
  },
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
}
</script>

<style lang="scss">
.over-button {
  position: relative;
  height: 24px;
  width: 24px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  margin: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
